.user-name {
    color: #49545c;
    font: normal normal 600 19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    margin-top: 9px;
    margin-bottom: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 48px;
}

.user-info {
    color: #7b8896;
    font: normal normal normal 15px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    margin-top: 3px;
    margin-bottom: 3px;
}

pre.user-info {
    height: 70px;
}

.user-avatar {
    border-radius: 5px;
    width: 100%;
    position: absolute;
}

.user-props {
    color: #7b8896;
    font-weight: 700;
    font-size: 14px;
    width: 15%;
    padding-left: 10px;
    padding-top: 10px;
    text-align: center;
}

.avatar-grid {
    position: relative;
    width: 85%;
    height: 0;
    padding-bottom: 85%;
}

.eluminer-info {
    position: absolute;
    transform: translateY(-100%);
    color: #ed961c;
    font: 600 15px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.edit-button-style {
    border: none;
    background-color: transparent;
    color: #7b8896;
}
