.picker-img {
    img {
        height: 25px;
    }
    position: absolute;
    top: 5px;
    left: 5px;
    height: auto;
    background-color: transparent;
    border: unset;
}

.pickgridister {
    position: relative;
    img {
        display: none;
    }
    .text-size-item {
        display: none;
    }
    .close-item {
        display: none;
    }
}

.pickgridister:hover {
    img {
        display: block;
        cursor: pointer;
        z-index: 1;
    }
    .text-size-item {
        display: block;
        cursor: pointer;
        z-index: 1;
    }
    .close-item {
        display: block;
        cursor: pointer;
        z-index: 1;
    }
}

.picker-gridster {
    position: absolute;
    background: white;
    border: 1px solid lightgrey;
    padding: 10px;
    z-index: 2;
    border-radius: 18px;
}

.text-size-item {
    position: absolute;
    bottom: 0;
    left: 0;
    button {
        background-color: transparent;
        color: white;
        border: none;
        padding: 0 3px;
        &:hover {
            color: #000;
        }
    }
}

.close-item {
    position: absolute;
    top: 0;
    right: 0;
    button {
        background-color: transparent;
        border: none;
        font-weight: bold;
        color: darkred;
    }
}