$spinner-radius: 35px;
$spinner-height: $spinner-radius * 3;
$quiet: #fff;
$g09: #fff;

.user-card,
.user-card-eluminer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 0px 8px #00000029;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
}

.user-card-eluminer {
  background-image: linear-gradient(rgb(202, 202, 127), rgba(0,0,0,0));
}

.button-gray,
.button-orange {
  width: 100%;
  color: white;
  border: none !important;
  font-size: 14px !important;
  margin-top: 5px;
  &:hover {
    backdrop-filter: contrast(95%);
    backdrop-filter: brightness(80%);
    transition: 0.3s ease-in-out;
  }
}

.button-gray {
  background-color: #7b8896 !important;
  border: none;
}

.info {
  fill: white;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1000;
}

.button-orange {
  background-color: #ed961c !important;
}

.button-white {
  height: 36px;
  width: 100%;
  color: #7b8896 !important;
  border: 1px solid #7b8896 !important;
  font-size: 14px !important;
  margin-top: 5px;
  &:hover {
    transition: 0.3s ease-in-out;
    color: #49545c;
  }
  background-color: white !important;
}

.modal-button-opp {
  position: relative;
  width: 100%;
  background-color: #7b8896 !important;
  &:hover {
    background-color: #ed961c !important;
    transition: 0.2s;
  }
}

.modal-button-selected {
  position: relative;
  width: 100%;
  background-color: #ed961c !important;
  &:hover {
    background-color: #ffa526 !important;
    transition: 0.2s;
  }
}

.circle-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: $spinner-height;
  height: $spinner-height;

  &:after {
    content: attr(data-pct) "%";
    position: absolute;
    top: 52%;
    left: 54%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: "#3c3c3c";
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .circle-progress-content {
    transform: rotate(-90deg);

    .bar {
      transition: stroke-dashoffset 0.1s ease-out;
    }
    .bg {
      stroke: $g09;
    }
  }
}

.cancel-button-modal {
  background-color: white;
  border: solid 1px #ed961c;
  border-radius: 20px;
  padding: 0.6rem 0 0.6rem 0;
  color: #ed961c;
  width: 40%;
}

.confirm-button-modal {
  background-color: #ed961c;
  border: solid 1px #ed961c;
  border-radius: 20px;
  padding: 0.6rem 0 0.6rem 0;
  color: white;
  width: 40%
}

.opp-container {
  @media screen and (min-width: 1200px) {
    margin-left: 35px;
  }
}

.style-scroll-bar-find::-webkit-scrollbar {
    width: 12px;
}

.style-scroll-bar-find::-webkit-scrollbar-track {
    background: white;
}

.style-scroll-bar-find::-webkit-scrollbar-thumb {
    background-color: #ed961c;
    border-radius: 20px;
    border: 3px solid white;
}
