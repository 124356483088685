blockquote p {
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 12px/16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0.12px;
  color: #ffffff;
  opacity: 1;
}

.blockquote {
  margin-bottom: 0 !important;
  width: 85%;
}

.padding-sec {
  padding: 2rem 1rem 1rem 1rem;
}

.header-footer {
  text-align: left !important;
  font: normal normal normal 13px/40px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0.26px !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.bg-footer-1 {
  background: #354a5f 0% 0% no-repeat padding-box;
  opacity: 1;
}

.bg-footer-2 {
  background: #2e3f51 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal normal 12px/16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0.24px;
  color: #ffffff;
  opacity: 1;
}

.links-footer li {
  text-align: left;
  letter-spacing: 0.28px;
  color: #ffffff !important;
  opacity: 1;
}

@media (max-width: 768px) {
  .footer-elements {
    margin: 0 auto !important;
    margin-bottom: 7% !important;
    padding: 0 !important;
    width: 91% !important;
  }

  .blockquote {
    margin-bottom: 0 !important;
    width: 100% !important;
  }
}
