span.counter {
  font-size: 11px;
  background: #ffffff;
  border-radius: 0.9em;
  -moz-border-radius: 0.9em;
  -webkit-border-radius: 0.9em;
  color: gray;
  display: inline-block;
  line-height: 1.0em;
  text-align: center;
  min-width: 1.4em;
  padding: 0.2em;
  margin-bottom: 2px;
}

.column-header {
  height: 80px;
}

.column-content{
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.title {
  color: white;
}

.victories-indications{
  color: #418d56 !important;
}

.defeats-indications{
  color: #ea6658 !important;
}