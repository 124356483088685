.navbar-bg {
  background: #ed961c 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  color: white;
  opacity: 1;
  font-family: normal normal 600 14px/19px -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important;
}

.navbar a {
  color: #fff !important;
  font: normal normal 600 14px/19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
}

.nav-link {
  padding-right: 2rem !important;
}

.link-cadastro {
  padding-right: 0px !important;
}

.dropdown-menu {
  background-color: #ed961c !important;
  border: none !important;
}

.dropdown-item:hover, .dropdown-item.active {
  background-color: #ea8e1c !important;
}

.navbar-toggler {
  border: 2px solid white !important;
}

.padding-container {
  padding: 0 20px 0 12px !important;
}

.user-links {
  display: none !important;
}

@media (min-width: 992px) {
    .test {
      display: none;
    }

    .user-links {
      display: block !important;
    }
}
