.terms-header {
  margin-top: 2rem;
  text-align: left;
  font: normal normal 600 20px/27px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #2e3f51;
  opacity: 1;
}

.terms-body {
  margin-top: 2rem;
  margin-bottom: 4rem;
  text-align: justify;
  font: normal normal normal 18px/24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.terms-items {
  list-style-type: none;
  padding: 0;
  text-align: left;
  font: normal normal normal 18px/24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.terms-items li {
  list-style-type: none;
}

.terms-items ol {
  padding: 0;
}
