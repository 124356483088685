.header-hunter-text {
  font-size: 13px;
  color: #49545C;
}

.header-hunter-title {
  border-bottom: solid 1px #8695a5;
  padding-bottom: 1%;
  color: #49545C;
  font: normal normal 600 21px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.header-hunter-input-search>div {
  height: 30px;
}

.header-hunter-input-search>div> ::first-line {
  font-size: 13px;
  color: #DBA44D;
}

.container-dropdown {
  margin-right: 2px; 
}

.header-hunter-body {
  display: flex;
  flex-direction: row;
}

.header-hunter-body>div {
  margin-right: 0.5rem;
}

.button-date-range {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: solid 1px rgb(207, 205, 205);
  border-radius: 3px;
  color: rgb(116, 116, 116);
  text-align: center;
  padding: 0 0.5rem 0 1rem;
  font-size: 14px;
  height: 2.5rem;
}
.button-date-range >span, .button-date-range>:nth-last-child(1) {
  margin-top: 0.5rem;
}

.container-date-range {
  background-color: white;
  border-radius: 5px;
  border: solid 1px white;
  box-shadow: 1px 2px 3px 3px #7f8081a2;
  margin-top: 0.5rem;
  // margin-left: 3rem;
  padding: 1rem;
  position: absolute;
  z-index: 1;
}

.label-date-button {
  position: absolute;
  background-color: white;
  padding: 0 3px 0px ;
  font-size: 10px !important;
  left: 8px;
  top: -7px;
  z-index: 1;
}

.text-calendar {
  font-size: 10px !important;
  margin-top: 0.7rem !important;
}

@media (max-width: 1120px) {
  .text-calendar {
    font-size: 9px;
    margin-top: 0.9rem !important;
  }

  .button-date-range {
    padding: 0 0.2rem 0 0.2rem;
  }
}

@media (max-width: 992px) {
  .header-hunter-body {
    display: flex;
    flex-direction: column;
    
  }

  .container-dropdown {
    margin-left: 4%;
  }

  .container-date-range {
    margin-left: 0;
    // margin-top: 11rem;
  }

  .button-date-range {
    padding: 7px;
  }

  .button-date-range >span {
    margin-left: 0.4rem;
  }

  .header-hunter-body>div {
    margin-right: 0;
  }

  .header-hunter-body >div>div {
    margin-bottom: 4px;
  }

  .text-calendar {
    font-size: 9px;
    margin-top: 0.5rem !important;
  }
}

@media (max-width: 767px) {
  .container-date-range {
    width: 17rem;
  }

  .text-calendar {
    font-size: 9px;
    margin-top: 0.7rem !important;
  }

  .button-date-range {
    padding: 0 0.2rem 0 0.2rem;
  }
}