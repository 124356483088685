.set-body {
  width: 81%;
  margin: 0 auto;
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0px;
  color: #49545C;
  text-align: left;
}

.set-input {
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0px;
  color: #707070 !important;
}

.set-title {
  font: normal normal 600 20px/60px Arial, Helvetica, sans-serif;
  letter-spacing: 0px;
  color: #49545C;
}

.set-save-button {
  background-color: #DE701D;
  color: white;
  border-radius: 20px;
  border: none;
  width: 15%;
  padding: 3px 0;
  font: normal normal 600 14px/21px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

@media (max-width: 600px) {
  .set-save-button {
    width: 100%;
  }
}

.toggle.active {
  color: #ed961c !important
}
