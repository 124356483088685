.body-container {
  display: flex;
  margin-top: 4vh;
  margin-bottom: 1.5vh;
}

.drop-container {
  display: flex;
  margin-left: auto;
}

.drop-container>div:first-child {
  display: flex;
  margin-right: 5px;
}

.drop-container>div:first-child>div {
  width: 190px;
}

.drop-container>div:first-child>label {
  margin-right: 10px;
  margin-top: 1vh;
}

.drop-container>div:last-child {
  display: flex;
  margin-bottom: 10px;
}

.drop-container>div:last-child>label {
  margin-right: 5px;
  margin-top: 1vh;
}

.drop-container>div:last-child>div:first-of-type {
  margin-right: 5px;
}

.drop-container>div:last-child>div {
  width: 190px;
}

@media(max-width: 991px) {
  .body-container {
    flex-direction: column;
  }
  .drop-container {
    flex-direction: column;
    width: 100%;
  }
  .drop-container>div:first-child {
    flex-direction: column;
    margin-right: 0;
  }
  .drop-container>div:first-child>div {
    width: 100%;
  }
  .drop-container>div:last-child {
    flex-direction: column;
    width: 100%;
  }
  .drop-container>div:last-child>div {
    width: 100%;
  }
  .drop-container>div:last-child>div:first-of-type {
    margin-bottom: 5px;
    margin-right: 0;
  }
}