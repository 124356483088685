.row-info {
    box-shadow: 0px 1px 20px #00000029;
    border-radius: 7px;
    background: #354A5F 0% 0% no-repeat padding-box;
    cursor: pointer;
} 

.bg, .bar {
    stroke-width: 5px;
}

.gMsFFn {
    color: white !important;
}

.span-q {
    color: white
}

.completeStep {
    background-color: white;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userStep {
    background-color: #37B8AF;
    height: 80%;
    width: 80%;
    border-radius: 50px;
}

.inactiveStep {
    background-color: #678098;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}