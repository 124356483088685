.opp-filter {
  margin: 66px 0 16px;
  width: 100%;
  min-height: 142px;
  background-color: #354A5F;
  border-radius: 4px;
  padding: 20px 30px 5px;
}

.input-search, .input-search>div {
  height: 52px !important;
}

.input-search {
  margin-bottom: 13px !important;
}

/* Input Oportunidades */

.input-search>div>input {
  height: 100% !important;
  padding-bottom: 3px;
  font-size: 18px;
}

/* Select Localidades */

.input-search>div>div {
  height: 100% !important;
  padding-top: 3px;
  font-size: 18px;
}

.input-search>div>svg.sc-iCfLBT.enGAsE {
  margin-top: 8px;
}

.find-button {
  background-color: #DE701D;
  height: 52px;
  border-radius: 35px;
  border: 1px solid #C77332;
  color: white;
  font-size: 20px;
  margin-bottom: 43px;
}

.home-office {
  color: white !important;
  font: normal normal 600 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.home-office.active {
  color: #ED961C !important;
}

.info-select-label {
  position: absolute;
  top: 4px;
  left: 11px;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  padding-top: 2px;
  color: white;
}

.search-result-box {
  border-bottom: 1px solid rgba(112, 112, 112, 0.4);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.result-filter [role=button] {
  background-color: #99A3AE;
  border-radius: 5px;
}