.opp-filter-home {
  margin: 66px 0px 37px !important;
  min-height: 142px;
  background-color: #354A5F;
  border-radius: 4px;
  padding: 43px 30px 0;
}

.input-search, .input-search>div {
  height: 52px !important;
}

.input-search {
  margin-bottom: 13px !important;
}

/* Input Oportunidades */
.input-search>div>input  {
  height: 100% !important;
  padding-bottom: 3px;
  font-size: 18px;
}

/* Select Localidades */
.input-search>div>div  {
  height: 100% !important;
  padding-top: 3px;
  font-size: 18px;
}


.input-search>div>svg.sc-iCfLBT.enGAsE  {
  margin-top: 8px;
}


.find-button {
  background-color: #DE701D;
  height: 52px;
  border-radius: 35px;
  border: 1px solid #C77332;
  color: white;
  font-size: 20px;
  margin-bottom: 43px;
}

.home-office {
  color: white !important;
  font: normal normal 600 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.pagination-number {
  text-align: center;
  width: 1.5rem;
  color: #819A9E;
  border: solid 1px #819A9E;
  border-radius: 5px;
}

.pagination-number-last {
  color: #819A9E;
}

.opp-found {
  color: #354A5F;
  font-weight: 400;
}

.back-button-home {
  background-color: transparent;
  border: none;
  color: #354A5F;
  font-weight: 600;
  text-decoration: underline;
}

.section-home-title {
  background-color: transparent;
  color: #354A5F;
  font-weight: 600;
  text-align: left;
}

@media(max-width: 680px) {
  .opp-filter-home {
      margin-top: 0;
      margin-left: 0 !important;
      margin-right: 0 !important;
  }
}
