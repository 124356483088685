.opp-title {
    font-size: 20px;
    margin-top: 17px;
    font: normal normal 600 20px/60px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    letter-spacing: 0px;
    color: #49545c;
    width: 100%;
}

.border-bottom-details {
    border-bottom: 2px solid lightgray;
}

.button-container {
    text-align: left  !important;
    width: 100% !important;
    font-size: 14px  !important;
    padding: 18px 29px  !important;
    box-shadow: 2px 2px 4px #00000029;
    border: none  !important;
    border-radius: 4px;
    margin-bottom: 2px;
    &:hover{
        background-color: #ed961c !important;
        color: white !important;
    }
}

.neutro {
    background-color: white !important;
    color: #49545c !important;
}
.petro {
    background-color: #67717a !important;
}
.petro:disabled {
    background-color: #67717a !important;
    color: lightgray !important;
    &:hover{
        background-color: none !important;
        color: none !important;
    }
}
.gray {
    background-color: #71808f !important;
}
.green {
    background-color: #5cb85c !important;
    background: url("../../../images/icons/whatsapp.svg") no-repeat;
    background-size: 22px ;
    background-position: 28px 18px;
    padding-left: 60px !important;
}

.cancel-modal-button {
    width: 100%;
    padding: 5px 50px !important;
    border-radius: 25px !important;
    background-color: #ea951d !important;
    border: unset !important;
}

.confirm-modal-button {
    width: 100%;
    padding: 5px 50px !important;
    border-radius: 25px !important;
    background-color: white !important;
    border: 2px solid #ea951d !important;
    color: #ea951d !important;
}