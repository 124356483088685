.banner-cookies {
    background-image: linear-gradient(rgb(222, 112, 29), rgb(255, 141, 55));
    box-shadow: 1px 1px 9px #5a5857;
    color: white;
    display: flex;
    flex-direction: column;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif;;
    font-size: 12px;
    padding: 5px;
    position: fixed;
    text-align: center;
    width: 100%;
    bottom: 0;
    z-index: 1;
  }
  
  .cookie-button {
    background-color: white;
    color: #DE701D;
    border-radius: 5px;
    border: none;
    padding: 0.7rem;
    text-decoration: underline;
    font-style: italic;
    font-weight: bold;
    width: fit-content;
    align-self: center;
  }
  
  .cookie-button:hover {
    color: #707070;
  }
  
  .logo-enter-cookie {
    background: white url('../../images/enter-loading.png') no-repeat;
    border-radius: 25px;
    border: solid 4px white;
    box-shadow: 1px 1px 9px #ffd8ba;
    margin: 0 auto;
    width: 3.5rem;
    height: 3.5rem;
  
  }
  
  .text-cookies {
    padding: 0.5rem;
  }
