@keyframes move-left {
    from {
        transform: translateX(10px);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes move-left-in {
    from {
        transform: translateX(10px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes move-right-out {
    from {
        transform: translateX(0px);
        opacity: 1;
    }

    to {
        transform: translateX(-10px);
        opacity: 0;
    }
}

.chip-input {
    padding: 3px;
    width: auto;
    display: inline-block;
}

.chip-input .chip {
    transform: translateX(10px);
    opacity: 0;
    padding: 6px 19px 3px 19px;
    color: #49545C;
    /* border: 1px solid var(--react-chip-input__chip-border-color, var(--gray, rgba(0, 0, 0, 0.25))); */
    border-radius: 16px;
    height: 32px;
    background-color: #EFF2F2;
    transition: box-shadow 0.15s linear, background-color 0.15s linear, border 0.15s linear;
}

.selected .chip {
    color: white;
    background-color: #ed961c;

}

.close {
    margin: 2px 4px 2px 5px;
}

.chip-input .chip:hover, .chip-input .chip:focus, .chip-input .chip:active {
    border-color: var(--react-chip-input__chip-hover-border-color, var(--dark, rgba(0, 0, 0, 0.5)));
    background-color: var(--react-chip-input__chip-hover-bg-color, #dedede);
}

.chip-input .chip.show {
    animation: move-left-in 0.25s ease-in-out forwards;
}

.chip-input .chip.hide {
    animation: move-right-out 0.25s ease-in-out forwards;
}

.chip-input .chip svg {
    transition: height 0.15s linear, width 0.15s linear;
    transform: scale(0.75);
    margin-top: -2px;
}

.chip-input .chip svg:hover, .chip-input .chip svg:focus, .chip-input .chip svg:active {
    transform: scale(0.9);
}

.no-focus:focus {
    box-shadow: none !important;
}

.shadow-primary {
    box-shadow: 0 0 0 0.2rem #dedede;
}

.cursor-pointer {
    cursor: pointer;
}