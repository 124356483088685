/* @import '@zendeskgarden/css-arrows'; */

/* @import '@zendeskgarden/css-callouts'; */

/* @import '@zendeskgarden/css-breadcrumbs'; */

/* @import '@zendeskgarden/css-menus'; */

/* @import '@zendeskgarden/css-modals'; */

/* @import '@zendeskgarden/css-pagination'; */

/* @import '@zendeskgarden/css-tables'; */

/* @import '@zendeskgarden/css-variables'; */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

:root {
  --zd-color-black: #000;
  --zd-color-green-100: #edf8f4;
  --zd-color-green-200: #d1e8df;
  --zd-color-green-300: #aecfc2;
  --zd-color-green-400: #5eae91;
  --zd-color-green-500: #228f67;
  --zd-color-green-600: #038153;
  --zd-color-green-700: #186146;
  --zd-color-green-800: #0b3b29;
  --zd-color-grey-100: #f8f9f9;
  --zd-color-grey-200: #e9ebed;
  --zd-color-grey-300: #d8dcde;
  --zd-color-grey-400: #c2c8cc;
  --zd-color-grey-500: #87929d;
  --zd-color-grey-600: #68737d;
  --zd-color-grey-700: #49545c;
  --zd-color-grey-800: #2f3941;
  --zd-color-blue-100: #edf7ff;
  --zd-color-blue-200: #cee2f2;
  --zd-color-blue-300: #adcce4;
  --zd-color-blue-400: #5293c7;
  --zd-color-blue-500: #337fbd;
  --zd-color-blue-600: #1f73b7;
  --zd-color-blue-700: #144a75;
  --zd-color-blue-800: #0f3554;
  --zd-color-kale-100: #f5fbfc;
  --zd-color-kale-200: #c1d6d9;
  --zd-color-kale-300: #819a9e;
  --zd-color-kale-400: #56777a;
  --zd-color-kale-500: #335d63;
  --zd-color-kale-600: #04444d;
  --zd-color-kale-700: #03363d;
  --zd-color-kale-800: #012b30;
  --zd-color-red-100: #fff0f1;
  --zd-color-red-200: #f5d5d8;
  --zd-color-red-300: #f5b5ba;
  --zd-color-red-400: #e35b66;
  --zd-color-red-500: #d93f4c;
  --zd-color-red-600: #cc3340;
  --zd-color-red-700: #8c232c;
  --zd-color-red-800: #681219;
  --zd-color-yellow-100: #fff8ed;
  --zd-color-yellow-200: #fff0db;
  --zd-color-yellow-300: #fcdba9;
  --zd-color-yellow-400: #ffb648;
  --zd-color-yellow-500: #f5a133;
  --zd-color-yellow-600: #ed961c;
  --zd-color-yellow-700: #ad5e18;
  --zd-color-yellow-800: #703b15;
  --zd-color-white: #fff;
  --zd-color-secondary-azure-400: #3091ec;
  --zd-color-secondary-azure-600: #1371d6;
  --zd-color-secondary-crimson-400: #e34f32;
  --zd-color-secondary-crimson-600: #c72a1c;
  --zd-color-secondary-fuschia-400: #d653c2;
  --zd-color-secondary-fuschia-600: #a81897;
  --zd-color-secondary-lemon-400: #ffd424;
  --zd-color-secondary-lemon-600: #ffbb10;
  --zd-color-secondary-lime-400: #43b324;
  --zd-color-secondary-lime-600: #2e8200;
  --zd-color-secondary-mint-400: #00a656;
  --zd-color-secondary-mint-600: #058541;
  --zd-color-secondary-orange-400: #de701d;
  --zd-color-secondary-orange-600: #bf5000;
  --zd-color-secondary-pink-400: #ec4d63;
  --zd-color-secondary-pink-600: #d42054;
  --zd-color-secondary-purple-400: #b552e2;
  --zd-color-secondary-purple-600: #6a27b8;
  --zd-color-secondary-royal-400: #5d7df5;
  --zd-color-secondary-royal-600: #3353e2;
  --zd-color-secondary-teal-400: #02a191;
  --zd-color-secondary-teal-600: #028079;
  --zd-color-secondary-azure-M400: #5f8dcf;
  --zd-color-secondary-azure-M600: #3a70b2;
  --zd-color-secondary-crimson-M400: #cc6c5b;
  --zd-color-secondary-crimson-M600: #b24a3c;
  --zd-color-secondary-fuschia-M400: #cf62a8;
  --zd-color-secondary-fuschia-M600: #a8458c;
  --zd-color-secondary-lemon-M400: #e7a500;
  --zd-color-secondary-lemon-M600: #c38f00;
  --zd-color-secondary-lime-M400: #519e2d;
  --zd-color-secondary-lime-M600: #47782c;
  --zd-color-secondary-mint-M400: #299c66;
  --zd-color-secondary-mint-M600: #2e8057;
  --zd-color-secondary-orange-M400: #d4772c;
  --zd-color-secondary-orange-M600: #b35827;
  --zd-color-secondary-pink-M400: #d57287;
  --zd-color-secondary-pink-M600: #b23a5d;
  --zd-color-secondary-purple-M400: #b072cc;
  --zd-color-secondary-purple-M600: #9358b0;
  --zd-color-secondary-royal-M400: #7986d8;
  --zd-color-secondary-royal-M600: #4b61c3;
  --zd-color-secondary-teal-M400: #2d9e8f;
  --zd-color-secondary-teal-M600: #3c7873;
  --zd-color-chat-orange: #f79a3e;
  --zd-color-connect-red: #eb6651;
  --zd-color-explore-blue: #30aabc;
  --zd-color-gather-pink: #e7afa2;
  --zd-color-guide-pink: #eb4962;
  --zd-color-message-green: #37b8af;
  --zd-color-sell-gold: #d4ae5e;
  --zd-color-support-green: #78a300;
  --zd-color-talk-yellow: #efc93d;
  --zd-font-family-monospace: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  --zd-font-family-system: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif;
  --zd-font-size-xs: 10px;
  --zd-font-size-sm: 12px;
  --zd-font-size-md: 14px;
  --zd-font-size-lg: 18px;
  --zd-font-size-xl: 22px;
  --zd-font-size-xxl: 26px;
  --zd-font-size-xxxl: 36px;
  --zd-font-size-sm-monospace: 11px;
  --zd-font-size-md-monospace: 13px;
  --zd-font-size-lg-monospace: 17px;
  --zd-font-weight-thin: 100;
  --zd-font-weight-extralight: 200;
  --zd-font-weight-light: 300;
  --zd-font-weight-regular: 400;
  --zd-font-weight-medium: 500;
  --zd-font-weight-semibold: 600;
  --zd-font-weight-bold: 700;
  --zd-font-weight-extrabold: 800;
  --zd-font-weight-black: 900;
  --zd-font-weight-ultralight: 200;
  --zd-font-weight-ultrabold: 800;
  --zd-font-weight-heavy: 900;
  --zd-line-height-sm: 16px;
  --zd-line-height-md: 20px;
  --zd-line-height-lg: 24px;
  --zd-line-height-xl: 28px;
  --zd-line-height-xxl: 32px;
  --zd-line-height-xxxl: 44px;
  --zd-spacing-xxs: 4px;
  --zd-spacing-xs: 8px;
  --zd-spacing-sm: 12px;
  --zd-spacing: 20px;
  --zd-spacing-lg: 32px;
  --zd-spacing-xl: 40px;
  --zd-spacing-xxl: 48px;
}

:root {
  --zd-avatar-border-radius: 50%;
  --zd-avatar-transition-duration: .25s;
  --zd-avatar-transition:
    box-shadow var(--zd-avatar-transition-duration) ease-in-out,
    color calc(var(--zd-avatar-transition-duration) * .4) ease-in-out;
  --zd-avatar-size: 40px;
  --zd-avatar__img-border-radius: 50%;
  --zd-avatar__svg-size: 16px;
  --zd-avatar__txt-color: var(--zd-color-white);
  --zd-avatar__txt-font-size: var(--zd-font-size-lg);
}

/* 1. Reset for <figure> element.
   2. Use `color` to manipulate inner box shadow and badge. */

.c-avatar {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: box-shadow .25s ease-in-out,
    color calc(.25s * .4) ease-in-out;
  transition: var(--zd-avatar-transition);
  margin: 0; /* [1] */
  border-radius: 50%;
  border-radius: var(--zd-avatar-border-radius);
  width: 40px;
  width: var(--zd-avatar-size);
  height: 40px;
  height: var(--zd-avatar-size);
  vertical-align: middle;
  box-sizing: border-box;
  color: transparent; /* [2] */
}

/* inner ring */

.c-avatar::before {
  position: absolute;
  top: 0;
  left: 0;
  transition: box-shadow .25s ease-in-out;
  transition: box-shadow var(--zd-avatar-transition-duration) ease-in-out;
  border-radius: inherit;
  box-shadow: inset 0 0 0 2px; /* [2] */
  width: 100%;
  height: 100%;
  content: '';
}

.c-avatar__img,
.c-avatar > img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  box-sizing: inherit;
  vertical-align: bottom;
  object-fit: cover;
}

.c-avatar__txt {
  overflow: hidden;
  text-align: center;
  line-height: 40px;
  line-height: var(--zd-avatar-size);
  white-space: nowrap;
  font-size: 18px;
  font-size: var(--zd-avatar__txt-font-size);
}

.c-avatar__txt,
.c-avatar__svg,
.c-avatar > svg {
  color: #fff;
  color: var(--zd-avatar__txt-color);
}

.c-avatar__svg,
.c-avatar > svg {
  width: 1em;
  height: 1em;
  font-size: 16px;
  font-size: var(--zd-avatar__svg-size);
}

:root {
  --zd-avatar--xs-size: 24px;
  --zd-avatar--xs__svg-size: 12px;
  --zd-avatar--xs__txt-font-size: var(--zd-font-size-sm);
  --zd-avatar--sm-size: 32px;
  --zd-avatar--sm__svg-size: 12px;
  --zd-avatar--sm__txt-font-size: var(--zd-font-size-md);
  --zd-avatar--lg-size: 48px;
  --zd-avatar--lg__svg-size: 24px;
  --zd-avatar--lg__txt-font-size: var(--zd-font-size-xl);
}

.c-avatar--lg {
  width: 48px;
  width: var(--zd-avatar--lg-size);
  height: 48px;
  height: var(--zd-avatar--lg-size);
}

.c-avatar--lg .c-avatar__txt {
  line-height: 48px;
  line-height: var(--zd-avatar--lg-size);
  font-size: 22px;
  font-size: var(--zd-avatar--lg__txt-font-size);
}

.c-avatar--lg .c-avatar__svg,
.c-avatar--lg > svg {
  font-size: 24px;
  font-size: var(--zd-avatar--lg__svg-size);
}

.c-avatar--sm {
  width: 32px;
  width: var(--zd-avatar--sm-size);
  height: 32px;
  height: var(--zd-avatar--sm-size);
}

.c-avatar--sm .c-avatar__txt {
  line-height: 32px;
  line-height: var(--zd-avatar--sm-size);
  font-size: 14px;
  font-size: var(--zd-avatar--sm__txt-font-size);
}

.c-avatar--sm .c-avatar__svg,
.c-avatar--sm > svg {
  font-size: 12px;
  font-size: var(--zd-avatar--sm__svg-size);
}

.c-avatar--xs {
  width: 24px;
  width: var(--zd-avatar--xs-size);
  height: 24px;
  height: var(--zd-avatar--xs-size);
}

.c-avatar--xs .c-avatar__txt {
  line-height: 24px;
  line-height: var(--zd-avatar--xs-size);
  font-size: 12px;
  font-size: var(--zd-avatar--xs__txt-font-size);
}

.c-avatar--xs .c-avatar__svg,
.c-avatar--xs > svg {
  font-size: 12px;
  font-size: var(--zd-avatar--xs__svg-size);
}

:root {
  --zd-avatar-box-shadow: 0 0 0 2px;
  --zd-avatar--active-color: var(--zd-color-secondary-crimson-400);
  --zd-avatar--away-color: var(--zd-color-yellow-400);
  --zd-avatar--available-color: var(--zd-color-secondary-mint-400);
}

.c-avatar.is-active,
.c-avatar.is-away,
.c-avatar.is-available {
  color: #fff;
  color: var(--zd-color-white);
}

.c-avatar.is-active {
  box-shadow: 0 0 0 2px #e34f32;
  box-shadow: var(--zd-avatar-box-shadow) var(--zd-avatar--active-color);
}

.c-avatar.is-away {
  box-shadow: 0 0 0 2px #ffb648;
  box-shadow: var(--zd-avatar-box-shadow) var(--zd-avatar--away-color);
}

.c-avatar.is-available {
  box-shadow: 0 0 0 2px #00a656;
  box-shadow: var(--zd-avatar-box-shadow) var(--zd-avatar--available-color);
}

:root {
  --zd-avatar--system-border-radius: 4px;
  --zd-avatar--system--lg-border-radius: 5px;
  --zd-avatar--system--sm-border-radius: 3px;
}

.c-avatar--system {
  border-radius: 4px;
  border-radius: var(--zd-avatar--system-border-radius);
}

.c-avatar--system.c-avatar--lg {
  border-radius: 5px;
  border-radius: var(--zd-avatar--system--lg-border-radius);
}

.c-avatar--system.c-avatar--sm {
  border-radius: 3px;
  border-radius: var(--zd-avatar--system--sm-border-radius);
}

:root {
  --zd-avatar__badge-border-radius: 100px;
  --zd-avatar__badge-color: var(--zd-color-white);
  --zd-avatar__badge-font-size: 10px;
  --zd-avatar__badge-font-weight: var(--zd-font-weight-semibold);
  --zd-avatar__badge-position: calc(var(--zd-avatar--available__badge-size) / 2 + var(--zd-avatar--available__badge-position));
  --zd-avatar__badge-transition-duration: var(--zd-avatar-transition-duration);
  --zd-avatar__badge-transition:
    all var(--zd-avatar__badge-transition-duration) ease-in-out,
    color 0s;
  --zd-avatar--active__badge-animation: zd-avatar--active__badge calc(var(--zd-avatar-transition-duration) * 1.5) ease-in-out;
  --zd-avatar--active__badge-min-width: var(--zd-avatar__badge-font-size);
  --zd-avatar--active__badge-padding: 0 5px;
  --zd-avatar--active__badge-position: -4px;
  --zd-avatar--active__badge-size: 20px;
  --zd-avatar--available__badge-position: -4px;
  --zd-avatar--available__badge-size: 12px;
  --zd-avatar--lg__badge-position: calc(var(--zd-avatar--lg--available__badge-size) / 2 + var(--zd-avatar--available__badge-position));
  --zd-avatar--lg--active__badge-padding: 0 5px;
  --zd-avatar--lg--available__badge-size: calc(var(--zd-avatar--available__badge-size) + 2px);
  --zd-avatar--sm--active__badge-padding: 0 3px;
  --zd-avatar--sm--active__badge-size: calc(var(--zd-avatar--active__badge-size) - 4px);
  --zd-avatar--sm--available__badge-size: calc(var(--zd-avatar--available__badge-size) - 2px);
  --zd-avatar--sm__badge-position: calc(var(--zd-avatar--sm--available__badge-size) / 2 + var(--zd-avatar--available__badge-position));
  --zd-avatar--xs--active__badge-min-width: var(--zd-avatar--xs--active__badge-size);
  --zd-avatar--xs--active__badge-size: 8px;
  --zd-avatar--xs--available__badge-size: calc(var(--zd-avatar--available__badge-size) - 4px);
  --zd-avatar--xs__badge-position: calc(var(--zd-avatar--xs--available__badge-size) / 2 + var(--zd-avatar--available__badge-position));
}

/* 1. Use `color` to manipulate inner box shadow and badge.
   2. Set text color without altering border.
   3. Fallback for IE 11 (which will set border).
   4. Improve animation easing. */

.c-avatar::after {
  box-sizing: content-box !important;
}

.c-avatar::after {
  display: inline-block;
  position: absolute;
  right: calc(12px / 2 + -4px);
  right: var(--zd-avatar__badge-position);
  bottom: calc(12px / 2 + -4px);
  bottom: var(--zd-avatar__badge-position);
  transition: all .25s ease-in-out,
    color 0s;
  transition: var(--zd-avatar__badge-transition);
  opacity: 0;
  border: 2px solid; /* [1] */
  border-radius: 100px;
  border-radius: var(--zd-avatar__badge-border-radius);
  background-color: transparent;
  padding: 0;
  min-width: 0;
  height: 0;
  /* stylelint-disable-next-line declaration-no-important */
  text-align: center;
  line-height: 1px; /* [4] */
  font-size: 0;
  font-weight: 600;
  font-weight: var(--zd-avatar__badge-font-weight);
  -webkit-text-fill-color: #fff;
  -webkit-text-fill-color: var(--zd-avatar__badge-color); /* [2] */
  content: '';
}

/* stylelint-disable selector-type-no-unknown, selector-no-vendor-prefix */

_:-ms-input-placeholder, .c-avatar::after {
  color: #fff;
  color: var(--zd-avatar__badge-color); /* [3] */
}

/* stylelint-enable selector-type-no-unknown, selector-no-vendor-prefix */

.c-avatar--lg::after {
  right: calc((12px + 2px) / 2 + -4px);
  right: calc(calc(12px + 2px) / 2 + -4px);
  right: var(--zd-avatar--lg__badge-position);
  bottom: calc((12px + 2px) / 2 + -4px);
  bottom: calc(calc(12px + 2px) / 2 + -4px);
  bottom: var(--zd-avatar--lg__badge-position);
}

.c-avatar--sm::after {
  right: calc((12px - 2px) / 2 + -4px);
  right: calc(calc(12px - 2px) / 2 + -4px);
  right: var(--zd-avatar--sm__badge-position);
  bottom: calc((12px - 2px) / 2 + -4px);
  bottom: calc(calc(12px - 2px) / 2 + -4px);
  bottom: var(--zd-avatar--sm__badge-position);
}

.c-avatar--xs::after {
  right: calc((12px - 4px) / 2 + -4px);
  right: calc(calc(12px - 4px) / 2 + -4px);
  right: var(--zd-avatar--xs__badge-position);
  bottom: calc((12px - 4px) / 2 + -4px);
  bottom: calc(calc(12px - 4px) / 2 + -4px);
  bottom: var(--zd-avatar--xs__badge-position);
}

.c-avatar.is-active::after,
.c-avatar.is-available::after {
  opacity: 1;
}

.c-avatar.is-active::after {
  right: -4px;
  right: var(--zd-avatar--active__badge-position);
  bottom: -4px;
  bottom: var(--zd-avatar--active__badge-position);
  -webkit-animation: zd-avatar--active__badge calc(.25s * 1.5) ease-in-out;
          animation: zd-avatar--active__badge calc(.25s * 1.5) ease-in-out;
  -webkit-animation: var(--zd-avatar--active__badge-animation);
          animation: var(--zd-avatar--active__badge-animation);
  background-color: #e34f32;
  background-color: var(--zd-avatar--active-color);
  padding: 0 5px;
  padding: var(--zd-avatar--active__badge-padding);
  min-width: 10px;
  min-width: var(--zd-avatar--active__badge-min-width);
  height: 20px;
  height: var(--zd-avatar--active__badge-size);
  line-height: 20px;
  line-height: var(--zd-avatar--active__badge-size);
}

.c-avatar.is-available::after {
  right: -4px;
  right: var(--zd-avatar--available__badge-position);
  bottom: -4px;
  bottom: var(--zd-avatar--available__badge-position);
  background-color: #00a656;
  background-color: var(--zd-avatar--available-color);
  min-width: 12px;
  min-width: var(--zd-avatar--available__badge-size);
  height: 12px;
  height: var(--zd-avatar--available__badge-size);
  line-height: 12px;
  line-height: var(--zd-avatar--available__badge-size); /* [4] */
}

.c-avatar--xs.is-active::after {
  padding: 0;
  min-width: 8px;
  min-width: var(--zd-avatar--xs--active__badge-min-width);
  height: 8px;
  height: var(--zd-avatar--xs--active__badge-size);
  line-height: 8px;
  line-height: var(--zd-avatar--xs--active__badge-size);
}

.c-avatar--xs.is-available::after {
  min-width: calc(12px - 4px);
  min-width: var(--zd-avatar--xs--available__badge-size);
  height: calc(12px - 4px);
  height: var(--zd-avatar--xs--available__badge-size);
  line-height: calc(12px - 4px);
  line-height: var(--zd-avatar--xs--available__badge-size); /* [4] */
}

.c-avatar--sm.is-active::after {
  padding: 0 3px;
  padding: var(--zd-avatar--sm--active__badge-padding);
  height: calc(20px - 4px);
  height: var(--zd-avatar--sm--active__badge-size);
  line-height: calc(20px - 4px);
  line-height: var(--zd-avatar--sm--active__badge-size);
}

.c-avatar--sm.is-available::after {
  min-width: calc(12px - 2px);
  min-width: var(--zd-avatar--sm--available__badge-size);
  height: calc(12px - 2px);
  height: var(--zd-avatar--sm--available__badge-size);
  line-height: calc(12px - 2px);
  line-height: var(--zd-avatar--sm--available__badge-size); /* [4] */
}

.c-avatar--lg.is-active::after {
  padding: 0 5px;
  padding: var(--zd-avatar--lg--active__badge-padding);
}

.c-avatar--lg.is-available::after {
  min-width: calc(12px + 2px);
  min-width: var(--zd-avatar--lg--available__badge-size);
  height: calc(12px + 2px);
  height: var(--zd-avatar--lg--available__badge-size);
  line-height: calc(12px + 2px);
  line-height: var(--zd-avatar--lg--available__badge-size); /* [4] */
}

.c-avatar.is-rtl::after {
  right: auto;
  left: calc(12px / 2 + -4px);
  left: var(--zd-avatar__badge-position);
}

.c-avatar--lg.is-rtl::after {
  left: calc((12px + 2px) / 2 + -4px);
  left: calc(calc(12px + 2px) / 2 + -4px);
  left: var(--zd-avatar--lg__badge-position);
}

.c-avatar--sm.is-rtl::after {
  left: calc((12px - 2px) / 2 + -4px);
  left: calc(calc(12px - 2px) / 2 + -4px);
  left: var(--zd-avatar--sm__badge-position);
}

.c-avatar--xs.is-rtl::after {
  left: calc((12px - 4px) / 2 + -4px);
  left: calc(calc(12px - 4px) / 2 + -4px);
  left: var(--zd-avatar--xs__badge-position);
}

.c-avatar.is-active.is-rtl::after {
  left: -4px;
  left: var(--zd-avatar--active__badge-position);
}

.c-avatar.is-available.is-rtl::after {
  left: -4px;
  left: var(--zd-avatar--available__badge-position);
}

.c-avatar:not(.c-avatar--xs).is-active::after {
  max-width: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  font-size: var(--zd-avatar__badge-font-size);
  content: attr(data-badge);
}

@-webkit-keyframes zd-avatar--active__badge {
  0% {
    -webkit-transform: scale(.1);
            transform: scale(.1);
  }
}

@keyframes zd-avatar--active__badge {
  0% {
    -webkit-transform: scale(.1);
            transform: scale(.1);
  }
}

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

:root {
  --zd-forms-accent-color: var(--zd-color-blue-600);
  --zd-forms-border-color: var(--zd-color-grey-300);
  --zd-forms-disabled-color: var(--zd-color-grey-400);
  --zd-forms-disabled-background-color: var(--zd-color-grey-100);
  --zd-forms-focused-box-shadow-spread-radius: 3px;
  --zd-forms-focused-box-shadow:
    0 0 0 var(--zd-forms-focused-box-shadow-spread-radius)
    color-mod(var(--zd-forms-accent-color) alpha(35%));
  --zd-forms-hovered-border-color: var(--zd-color-blue-400);
  --zd-forms-error-color: var(--zd-color-red-400);
  --zd-forms-success-color: var(--zd-color-green-400);
  --zd-forms-warning-color: var(--zd-color-yellow-400);
  --zd-input__hint-color: var(--zd-color-grey-600);
  --zd-input__hint-font-size: var(--zd-font-size-md);
  --zd-input__label-color: var(--zd-color-grey-800);
  --zd-input__label-font-size: var(--zd-font-size-md);
  --zd-input__label-font-weight: var(--zd-font-weight-semibold);
  --zd-input__message-color: var(--zd-color-grey-700);
  --zd-input__message-font-size: var(--zd-font-size-sm);
  --zd-input__message--error-background-image: svg-load('16/alert-error-stroke.svg', color: var(--zd-color-red-600));
  --zd-input__message--error-color: var(--zd-color-red-600);
  --zd-input__message--success-background-image: svg-load('16/check-circle-stroke.svg', color: var(--zd-color-green-600));
  --zd-input__message--success-color: var(--zd-color-green-600);
  --zd-input__message--warning-background-image: svg-load('16/alert-warning-stroke.svg', color: var(--zd-color-yellow-700));
  --zd-input__message--warning-color: var(--zd-color-yellow-700);
}

:root {
  --zd-chk-background-color: var(--zd-color-white);
  --zd-chk-background-size: 12px;
  --zd-chk-border: 1px solid var(--zd-forms-border-color);
  --zd-chk-border-radius: 4px;
  --zd-chk-size: 16px;
  --zd-chk-transition:
    border-color .25s ease-in-out,
    box-shadow .1s ease-in-out,
    background-color .25s ease-in-out,
    background-image .25s ease-in-out,
    color .25s ease-in-out;
  --zd-chk__label-line-height: calc(20 / 14);
  --zd-chk__label-padding: calc(var(--zd-chk-size) + var(--zd-chk__label-spacing));
  --zd-chk__label-spacing: 8px;
  --zd-chk__label-top: 10px;
  --zd-chk__label--regular-font-weight: var(--zd-font-weight-regular);
  --zd-chk__hint-line-height: calc(20 / 14);
  --zd-chk__message-line-height: calc(16 / 12);
  --zd-chk__message-margin-top: 4px;
}

/* 1. Reset for <fieldset> element.
   2. Hide <input type="checkbox"> but retain accessibility.
   3. Vertical centering. */

.c-chk {
  position: relative;
  margin: 0; /* [1] */
  border: 0; /* [1] */
  padding: 0; /* [1] */
}

.c-chk__input {
  position: absolute; /* [2] */
  clip: rect(1px, 1px, 1px, 1px); /* [2] */
}

.c-chk__label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: calc(16px + 8px);
  padding-left: var(--zd-chk__label-padding);
  vertical-align: middle;
  line-height: calc(20 / 14);
  line-height: var(--zd-chk__label-line-height);
  white-space: normal;
  color: #2f3941;
  color: var(--zd-input__label-color);
  font-size: 14px;
  font-size: var(--zd-input__label-font-size);
  font-weight: 600;
  font-weight: var(--zd-input__label-font-weight);
  -webkit-user-select: none;
              -ms-user-select: none;
          user-select: none;
}

.c-chk__label::before {
  position: absolute;
  top: 10px;
  top: var(--zd-chk__label-top); /* [3] */
  left: 0;
  transition: border-color .25s ease-in-out,
    box-shadow .1s ease-in-out,
    background-color .25s ease-in-out,
    background-image .25s ease-in-out,
    color .25s ease-in-out;
  transition: var(--zd-chk-transition);
  margin-top: calc(16px / 2 * -1);
  margin-top: calc(var(--zd-chk-size) / 2 * -1); /* [3] */
  border: 1px solid #d8dcde;
  border: var(--zd-chk-border);
  border-radius: 4px;
  border-radius: var(--zd-chk-border-radius);
  background-color: #fff;
  background-color: var(--zd-chk-background-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  background-size: var(--zd-chk-background-size);
  width: 16px;
  width: var(--zd-chk-size);
  height: 16px;
  height: var(--zd-chk-size);
  box-sizing: border-box;
  color: inherit;
  content: '';
}

.c-chk__label--regular {
  font-weight: 400;
  font-weight: var(--zd-chk__label--regular-font-weight);
}

.c-chk__hint {
  padding-left: calc(16px + 8px);
  padding-left: var(--zd-chk__label-padding);
  line-height: calc(20 / 14);
  line-height: var(--zd-chk__hint-line-height);
  color: #68737d;
  color: var(--zd-input__hint-color);
  font-size: 14px;
  font-size: var(--zd-input__hint-font-size);
}

.c-chk__message {
  display: inline-block;
  padding-left: calc(16px + 8px);
  padding-left: var(--zd-chk__label-padding);
  vertical-align: middle;
  line-height: calc(16 / 12);
  line-height: var(--zd-chk__message-line-height);
  color: #49545c;
  color: var(--zd-input__message-color);
  font-size: 12px;
  font-size: var(--zd-input__message-font-size);
}

.c-chk__message--error,
.c-chk__message--success,
.c-chk__message--warning {
  background-repeat: no-repeat;
  background-position: center left;
}

.c-chk__message--error {
  background-image: svg-load('16/alert-error-stroke.svg', color: #cc3340);
  background-image: var(--zd-input__message--error-background-image);
  color: #cc3340;
  color: var(--zd-input__message--error-color);
}

.c-chk__message--success {
  background-image: svg-load('16/check-circle-stroke.svg', color: #038153);
  background-image: var(--zd-input__message--success-background-image);
  color: #038153;
  color: var(--zd-input__message--success-color);
}

.c-chk__message--warning {
  background-image: svg-load('16/alert-warning-stroke.svg', color: #ad5e18);
  background-image: var(--zd-input__message--warning-background-image);
  color: #ad5e18;
  color: var(--zd-input__message--warning-color);
}

.c-chk__label.is-hidden {
  padding-left: 16px;
  padding-left: var(--zd-chk-size);
  text-indent: -100%;
  font-size: 0;
}

.c-chk__label.is-hidden::before {
  top: 0;
}

.is-rtl.c-chk {
  direction: rtl;
}

.is-rtl.c-chk__label::before {
  right: 0;
  left: auto;
}

.is-rtl.c-chk__label,
.is-rtl.c-chk__hint,
.is-rtl.c-chk__message {
  direction: rtl;
  padding-right: calc(16px + 8px);
  padding-right: var(--zd-chk__label-padding);
  padding-left: 0;
}

.is-rtl.c-chk__message--error,
.is-rtl.c-chk__message--success,
.is-rtl.c-chk__message--warning {
  background-position: center right;
  padding-left: 0;
}

.is-rtl.c-chk__label.is-hidden {
  padding-right: 16px;
  padding-right: var(--zd-chk-size);
}

.c-chk__label + .c-chk__message {
  display: block;
  margin-top: 4px;
  margin-top: var(--zd-chk__message-margin-top);
}

.c-chk .c-chk__hint {
  display: block;
}

/* stylelint-disable at-rule-empty-line-before, max-line-length, no-descending-specificity */

/* stylelint-disable at-rule-empty-line-before, max-line-length */

/* 1. Class = checked/indeterminate & active.
 * 2. Class = checked/indeterminate; psuedo = active.
 * 3. Class = active; psuedo = checked/indeterminate.
 * 4. Psuedo = checked/indeterminate & active. */

/* [4] */

/* [4] */

/* 1. Class = checked/indeterminate & disabled.
 * 2. Class = checked/indeterminate; attribute = disabled.
 * 3. Class = disabled; psuedo = checked/indeterminate.
 * 4. Psuedo = checked/indeterminate; attribute = disabled. */

/* [4] */

/* [4] */

/* stylelint-enable at-rule-empty-line-before, max-line-length */

:root {
  --zd-chk-active-background-color: var(--zd-color-blue-200);
  --zd-chk-active-border-color: var(--zd-forms-accent-color);
  --zd-chk-active-transition:
    border-color .1s ease-in-out,
    background-color .1s ease-in-out,
    background-image .1s ease-in-out,
    color .1s ease-in-out;
  --zd-chk-checked-background-color: var(--zd-forms-accent-color);
  --zd-chk-checked-background-image: svg-load('12/check-sm-fill.svg', color: var(--zd-color-white));
  --zd-chk-checked-border-color: var(--zd-chk-checked-background-color);
  --zd-chk-checked-active-background-color: var(--zd-color-blue-700);
  --zd-chk-checked-active-border-color: var(--zd-chk-checked-active-background-color);
  --zd-chk-disabled-background-color: var(--zd-color-grey-200);
  --zd-chk-focused-border-color: var(--zd-forms-accent-color);
  --zd-chk-focused-box-shadow: var(--zd-forms-focused-box-shadow);
  --zd-chk-hovered-border-color: var(--zd-chk-focused-border-color);
  --zd-chk-indeterminate-background-image: svg-load('12/dash-fill.svg', color: var(--zd-color-white));
}

.c-chk__label.is-checked::before,.c-chk__input:checked ~ .c-chk__label::before {
  background-image: svg-load('12/check-sm-fill.svg', color: #fff);
  background-image: var(--zd-chk-checked-background-image);
}

.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-indeterminate::before,.c-chk__input:indeterminate ~ .c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle)::before {
  background-image: svg-load('12/dash-fill.svg', color: #fff);
  background-image: var(--zd-chk-indeterminate-background-image);
}

/* HACK [jtz] until postcss/postcss-custom-selectors#40 is fixed */

/* stylelint-disable-next-line selector-list-comma-space-after */

.c-chk__input:enabled ~ .c-chk__label.is-checked:not(.is-disabled)::before,.c-chk__input:checked:enabled ~ .c-chk__label:not(.is-disabled)::before,.c-chk__input:enabled ~ .c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-indeterminate:not(.is-disabled)::before,.c-chk__input:indeterminate:enabled ~ .c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle):not(.is-disabled)::before {
  border-color: #1f73b7;
  border-color: var(--zd-chk-checked-border-color);
  background-color: #1f73b7;
  background-color: var(--zd-chk-checked-background-color);
}

.c-chk__input:enabled ~ .c-chk__label.is-hovered:not(.is-disabled)::before,.c-chk__input:enabled ~ .c-chk__label:not(.is-disabled):hover::before {
  border-color: #1f73b7;
  border-color: var(--zd-chk-hovered-border-color);
}

.c-chk__input:enabled ~ .c-chk__label.is-focused:not(.is-disabled)::before {
  outline: none;
  border-color: #1f73b7;
  border-color: var(--zd-chk-focused-border-color);
  box-shadow: 0 0 0 3px
    color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-chk-focused-box-shadow);
}

.c-chk__input:enabled ~ .c-chk__label.is-active:not(.is-disabled)::before,.c-chk__input:enabled ~ .c-chk__label:not(.is-disabled):active::before {
  transition: border-color .1s ease-in-out,
    background-color .1s ease-in-out,
    background-image .1s ease-in-out,
    color .1s ease-in-out;
  transition: var(--zd-chk-active-transition);
  border-color: #1f73b7;
  border-color: var(--zd-chk-active-border-color);
  background-color: #cee2f2;
  background-color: var(--zd-chk-active-background-color);
}

/* HACK [jtz] until postcss/postcss-custom-selectors#40 is fixed */

/* stylelint-disable-next-line selector-list-comma-space-after */

.c-chk__input:enabled ~ .c-chk__label.is-checked.is-active:not(.is-disabled)::before,.c-chk__input:enabled ~ .c-chk__label.is-checked:not(.is-disabled):active::before,.c-chk__input:enabled:checked ~ .c-chk__label.is-active:not(.is-disabled)::before,.c-chk__input:enabled:checked ~ .c-chk__label:not(.is-disabled):active::before,.c-chk__input:enabled ~ .c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-indeterminate.is-active:not(.is-disabled)::before,.c-chk__input:enabled ~ .c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-indeterminate:not(.is-disabled):active::before,.c-chk__input:enabled:indeterminate ~ .c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-active:not(.is-disabled)::before,.c-chk__input:enabled:indeterminate ~ .c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle):not(.is-disabled):active::before {
  border-color: #144a75;
  border-color: var(--zd-chk-checked-active-border-color);
  background-color: #144a75;
  background-color: var(--zd-chk-checked-active-background-color);
}

.c-chk__label.is-disabled,.c-chk__input[disabled] ~ .c-chk__label {
  cursor: default;
}

.c-chk__label.is-disabled::before,.c-chk__input[disabled] ~ .c-chk__label::before {
  border-color: transparent;
  box-shadow: none;
  background-color: #e9ebed;
  background-color: var(--zd-chk-disabled-background-color);
}

/* stylelint-enable at-rule-empty-line-before, max-line-length, no-descending-specificity */

/* stylelint-disable */

@svg-load circle url('12/circle-fill.svg') {
  color: #fff;
  color: var(--zd-color-white);
  circle {
    r: 2;
  }
}

/* stylelint-enable */

:root {
  --zd-chk--radio-checked-background-image: svg-inline(circle);
}

.c-chk__label--radio::before {
  border-radius: 50%;
}

.c-chk__label.is-checked.c-chk__label--radio::before,.c-chk__input:checked ~ .c-chk__label.c-chk__label--radio::before {
  background-image: svg-inline(circle);
  background-image: var(--zd-chk--radio-checked-background-image);
}

:root {
  --zd-chk--toggle-background-color: var(--zd-color-grey-500);
  --zd-chk--toggle-background-image: svg-load('16/circle-fill.svg', color: var(--zd-color-white));
  --zd-chk--toggle-background-size: 14px;
  --zd-chk--toggle-active-background-color: var(--zd-color-grey-600);
  --zd-chk--toggle-checked-active-background-color: var(--zd-chk-checked-active-background-color);
  --zd-chk--toggle-disabled-background-color: var(--zd-chk-disabled-background-color);
  --zd-chk--toggle-height: 20px;
  --zd-chk--toggle-transition:
    box-shadow .1s ease-in-out,
    background-color .15s ease-in-out,
    background-position .15s ease-in-out,
    color .25s ease-in-out;
  --zd-chk--toggle-width: 40px;
  --zd-chk--toggle__label-padding: calc(var(--zd-chk--toggle-width) + var(--zd-chk__label-spacing));
  --zd-chk--toggle__message-background-position: calc(var(--zd-chk--toggle-width) - var(--zd-font-size-md));
}

.c-chk__label--toggle,
.c-chk__hint--toggle,
.c-chk__message--toggle {
  padding-left: calc(40px + 8px);
  padding-left: var(--zd-chk--toggle__label-padding);
}

.c-chk__message--toggle {
  background-position: calc(40px - 14px);
  background-position: var(--zd-chk--toggle__message-background-position);
}

.c-chk__label--toggle::before {
  top: 0;
  transition: box-shadow .1s ease-in-out,
    background-color .15s ease-in-out,
    background-position .15s ease-in-out,
    color .25s ease-in-out;
  transition: var(--zd-chk--toggle-transition);
  margin-top: 0;
  border: none;
  border-radius: 100px;
  background-color: #87929d;
  background-color: var(--zd-chk--toggle-background-color);
  background-image: svg-load('16/circle-fill.svg', color: #fff);
  background-image: var(--zd-chk--toggle-background-image);
  background-position: 10%;
  background-size: 14px;
  background-size: var(--zd-chk--toggle-background-size);
  width: 40px;
  width: var(--zd-chk--toggle-width);
  height: 20px;
  height: var(--zd-chk--toggle-height);
}

.c-chk__label.is-checked.c-chk__label--toggle::before,.c-chk__input:checked ~ .c-chk__label.c-chk__label--toggle::before {
  background-image: svg-load('16/circle-fill.svg', color: #fff);
  background-image: var(--zd-chk--toggle-background-image);
  background-position: 90%;
}

.c-chk__input:enabled ~ .c-chk__label.is-active:not(.is-disabled).c-chk__label--toggle::before,.c-chk__input:enabled ~ .c-chk__label:not(.is-disabled):active.c-chk__label--toggle::before {
  background-color: #68737d;
  background-color: var(--zd-chk--toggle-active-background-color);
}

.c-chk__input:enabled ~ .c-chk__label.is-checked.is-active:not(.is-disabled).c-chk__label--toggle::before,.c-chk__input:enabled ~ .c-chk__label.is-checked:not(.is-disabled):active.c-chk__label--toggle::before,.c-chk__input:enabled:checked ~ .c-chk__label.is-active:not(.is-disabled).c-chk__label--toggle::before,.c-chk__input:enabled:checked ~ .c-chk__label:not(.is-disabled):active.c-chk__label--toggle::before {
  background-color: #144a75;
  background-color: var(--zd-chk--toggle-checked-active-background-color);
}

.c-chk__label.is-disabled.c-chk__label--toggle::before,.c-chk__input[disabled] ~ .c-chk__label.c-chk__label--toggle::before {
  background-color: #e9ebed;
  background-color: var(--zd-chk--toggle-disabled-background-color);
}

.c-chk__label--toggle.is-hidden {
  padding-left: 40px;
  padding-left: var(--zd-chk--toggle-width);
  vertical-align: top;
}

.is-rtl.c-chk__label--toggle,
.is-rtl.c-chk__hint--toggle,
.is-rtl.c-chk__message--toggle {
  padding-right: calc(40px + 8px);
  padding-right: var(--zd-chk--toggle__label-padding);
  padding-left: 0;
}

.is-rtl.c-chk__message--toggle {
  background-position: calc(100% - (40px - 14px));
  background-position: calc(100% - calc(40px - 14px));
  background-position: calc(100% - var(--zd-chk--toggle__message-background-position));
}

.is-rtl.c-chk__label--toggle.is-hidden {
  padding-right: 40px;
  padding-right: var(--zd-chk--toggle-width);
}

.is-rtl.c-chk__label--toggle::before {
  background-position: 90%;
}

.c-chk__label.is-checked.is-rtl.c-chk__label--toggle::before,.c-chk__input:checked ~ .c-chk__label.is-rtl.c-chk__label--toggle::before {
  background-position: 10%;
}

/* stylelint-disable no-unsupported-browser-features */

:root {
  --zd-range-margin: calc((var(--zd-range-thumb-size) - var(--zd-range-track-height)) / 2);
  --zd-range-thumb-background-color: var(--zd-forms-accent-color);
  --zd-range-thumb-border: 3px solid var(--zd-range-thumb-background-color);
  --zd-range-thumb-border-radius: 100%;
  --zd-range-thumb-box-shadow:
    0 4px 8px 0
    color-mod(var(--zd-color-grey-800) alpha(24%));
  --zd-range-thumb-margin: calc(var(--zd-range-margin) * -1) 0;
  --zd-range-thumb-size: var(--zd-spacing);
  --zd-range-track-background-color: var(--zd-color-grey-200);
  --zd-range-track-background-image: linear-gradient(var(--zd-range-thumb-background-color), var(--zd-range-thumb-background-color));
  --zd-range-track-border-radius: 5px;
  --zd-range-track-height: var(--zd-range-track-border-radius);
  --zd-range-track-margin-y: calc(var(--zd-range-margin) + var(--zd-forms-focused-box-shadow-spread-radius));
  --zd-range-track-margin: var(--zd-range-track-margin-y) 0;
  --zd-range-lower-background-color: var(--zd-range-thumb-background-color);
}

/* 1. Reset for <fieldset> element.
 * 2. Reset for WebKit & Firefox.
 * 3. Reset for IE.
 * 4. Fix for IE which cuts off the upper track's border radius.
 * 5. Provide means for styling lower range on Webkit.
 * 6. Remove dotted outline from Firefox on focus. */

.c-range {
  margin: 0; /* [1] */
  border: 0; /* [1] */
  padding: 0; /* [1] */
}

.c-range__input {
  -webkit-appearance: none;
          appearance: none;
  margin: 0; /* [2] */
  outline: none;
  background-color: inherit;
  background-size: 0; /* [5] */
  cursor: pointer;
  padding: 0; /* [3] */
  width: 100%;
  vertical-align: middle;
}

/* stylelint-disable selector-pseudo-element-no-unknown */

.c-range__input::range-track {
  -webkit-appearance: none;
          appearance: none;
  margin: calc(((20px - 5px) / 2) + 3px) 0;
  margin: calc(calc((20px - 5px) / 2) + 3px) 0;
  margin: var(--zd-range-track-margin);
  border-radius: 5px;
  border-radius: var(--zd-range-track-border-radius);
  border-color: transparent; /* [3] */
  background-color: #e9ebed;
  background-color: var(--zd-range-track-background-color);
  background-image: linear-gradient(#1f73b7, #1f73b7);
  background-image: var(--zd-range-track-background-image); /* [5] */
  background-repeat: repeat-y;
  background-size: 0;
  width: 99.8%; /* [4] */
  height: 5px;
  height: var(--zd-range-track-height);
  color: transparent; /* [3] */
  box-sizing: border-box; /* [3] */
}

.c-range__input::range-thumb {
  -webkit-appearance: none;
          appearance: none;
  margin: calc(((20px - 5px) / 2) * -1) 0;
  margin: calc(calc((20px - 5px) / 2) * -1) 0;
  margin: var(--zd-range-thumb-margin); /* [3] */
  border: 3px solid #1f73b7;
  border: var(--zd-range-thumb-border);
  border-radius: 100%;
  border-radius: var(--zd-range-thumb-border-radius);
  box-shadow: 0 4px 8px 0
    color-mod(#2f3941 alpha(24%));
  box-shadow: var(--zd-range-thumb-box-shadow);
  background-color: #1f73b7;
  background-color: var(--zd-range-thumb-background-color);
  width: 20px;
  width: var(--zd-range-thumb-size);
  height: 20px;
  height: var(--zd-range-thumb-size);
  box-sizing: border-box;
}

.c-range__input::range-lower {
  border-top-left-radius: 5px;
  border-top-left-radius: var(--zd-range-track-border-radius);
  border-bottom-left-radius: 5px;
  border-bottom-left-radius: var(--zd-range-track-border-radius);
  background-color: #1f73b7;
  background-color: var(--zd-range-lower-background-color);
  height: 5px;
  height: var(--zd-range-track-height);
}

.c-range__input::-moz-focus-outer {
  border: 0; /* [6] */
}

.c-range__input::-ms-tooltip {
  display: none; /* [3] */
}

.c-range__input::-webkit-slider-container,
.c-range__input::-webkit-slider-runnable-track {
  background-size: inherit; /* [5] */
}

.c-range.is-rtl,
.c-range__input.is-rtl,
.c-range__label.is-rtl,
.c-range__hint.is-rtl,
.c-range__message.is-rtl {
  direction: rtl;
}

.c-range__input.is-rtl::range-track {
  background-position: right bottom;
}

.c-range__input.is-rtl::range-lower {
  border-top-right-radius: 5px;
  border-top-right-radius: var(--zd-range-track-border-radius);
  border-bottom-right-radius: 5px;
  border-bottom-right-radius: var(--zd-range-track-border-radius);
}

.c-range--inline {
  display: inline-block;
}

.c-range--inline .c-range__input {
  width: auto;
}

/* stylelint-enable selector-pseudo-element-no-unknown */

/* stylelint-enable no-unsupported-browser-features */

/* stylelint-disable at-rule-empty-line-before */

/* stylelint-enable at-rule-empty-line-before */

:root {
  --zd-range-active-thumb-background-color: var(--zd-color-blue-700);
  --zd-range-disabled-lower-background-color: var(--zd-color-grey-300);
  --zd-range-disabled-thumb-background-color: var(--zd-range-disabled-lower-background-color);
  --zd-range-disabled-thumb-border-color: var(--zd-range-disabled-thumb-background-color);
  --zd-range-disabled-track-background-color: var(--zd-range-track-background-color);
  --zd-range-disabled-track-background-image: linear-gradient(var(--zd-range-disabled-thumb-background-color), var(--zd-range-disabled-thumb-background-color));
}

.c-range__input.is-disabled,.c-range__input[disabled] {
  cursor: default;
}

/* stylelint-disable selector-pseudo-element-no-unknown */

.c-range__input.is-focused:not(.is-disabled):not([disabled])::range-thumb {
  box-shadow: 0 0 0 3px
    color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-forms-focused-box-shadow);
}

.c-range__input.is-active:not(.is-disabled):not([disabled])::range-thumb,.c-range__input:active:not(.is-disabled):not([disabled])::range-thumb {
  background-color: #144a75;
  background-color: var(--zd-range-active-thumb-background-color);
}

.c-range__input.is-disabled::range-track,.c-range__input[disabled]::range-track {
  background-color: #e9ebed;
  background-color: var(--zd-range-disabled-track-background-color);
  background-image: linear-gradient(#d8dcde, #d8dcde);
  background-image: var(--zd-range-disabled-track-background-image);
}

.c-range__input.is-disabled::range-thumb,.c-range__input[disabled]::range-thumb {
  border-color: #d8dcde;
  border-color: var(--zd-range-disabled-thumb-border-color);
  box-shadow: none;
  background-color: #d8dcde;
  background-color: var(--zd-range-disabled-thumb-background-color);
}

.c-range__input.is-disabled::range-lower,.c-range__input[disabled]::range-lower {
  background-color: #d8dcde;
  background-color: var(--zd-range-disabled-lower-background-color);
}

/* stylelint-enable selector-pseudo-element-no-unknown */

:root {
  --zd-range__slider-height: calc(calc(var(--zd-range-track-margin-y) * 2) + var(--zd-range-track-height));
  --zd-range__slider__track-margin-top: calc(var(--zd-range-track-height) / 2 * -1);
  --zd-range__slider__track-padding: 0 calc(var(--zd-range-thumb-size) / 2);
  --zd-range__slider__track__rail-margin: 0 calc(var(--zd-range-thumb-size) / 2) 0 calc(var(--zd-range-thumb-size) / 2 * -1);
  --zd-range__slider__track__rail-margin-rtl: 0 calc(var(--zd-range-thumb-size) / 2 * -1) 0 calc(var(--zd-range-thumb-size) / 2);
  --zd-range--inline__slider-inline-size: 129px; /* match Chrome default */
}

.c-range__slider {
  display: block;
  position: relative;
  z-index: 0;
  cursor: pointer;
  height: calc(((((20px - 5px) / 2) + 3px) * 2) + 5px);
  height: calc(calc(calc(calc((20px - 5px) / 2) + 3px) * 2) + 5px);
  height: var(--zd-range__slider-height);
}

.c-range__slider__track {
  position: absolute;
  top: 50%;
  margin-top: calc(5px / 2 * -1);
  margin-top: var(--zd-range__slider__track-margin-top);
  border-radius: 5px;
  border-radius: var(--zd-range-track-border-radius);
  background-color: #e9ebed;
  background-color: var(--zd-range-track-background-color);
  background-origin: content-box;
  background-image: linear-gradient(#1f73b7, #1f73b7);
  background-image: var(--zd-range-track-background-image);
  background-repeat: repeat-y;
  padding: 0 calc(20px / 2);
  padding: var(--zd-range__slider__track-padding);
  width: 100%;
}

.c-range__slider__track__rail {
  position: relative;
  margin: 0 calc(20px / 2) 0 calc(20px / 2 * -1);
  margin: var(--zd-range__slider__track__rail-margin);
  height: 5px;
  height: var(--zd-range-track-height);
}

.c-range__slider__track__rail__thumb {
  -webkit-appearance: none;
          appearance: none;
  position: absolute;
  top: 50%;
  z-index: 1;
  margin-top: calc(20px / 2 * -1);
  margin-top: calc(var(--zd-range-thumb-size) / 2 * -1);
  border: 3px solid #1f73b7;
  border: var(--zd-range-thumb-border);
  border-radius: 100%;
  border-radius: var(--zd-range-thumb-border-radius);
  box-shadow: 0 4px 8px 0
    color-mod(#2f3941 alpha(24%));
  box-shadow: var(--zd-range-thumb-box-shadow);
  background-color: #1f73b7;
  background-color: var(--zd-range-thumb-background-color);
  cursor: inherit;
  width: 20px;
  width: var(--zd-range-thumb-size);
  height: 20px;
  height: var(--zd-range-thumb-size);
  box-sizing: border-box;
  font-size: 0;
}

/* stylelint-disable selector-pseudo-element-no-unknown */

.c-range__slider__track__rail__thumb::range-track,
.c-range__slider__track__rail__thumb::range-thumb {
  -webkit-appearance: none;
          appearance: none;
}

/* stylelint-enable selector-pseudo-element-no-unknown */

.c-range__slider__track__rail__thumb::-ms-tooltip {
  display: none;
}

.c-range__slider__track__rail__thumb:first-of-type {
  left: 0;
}

.c-range__slider__track__rail__thumb:last-of-type {
  left: 100%;
}

/* stylelint-disable selector-max-specificity, no-descending-specificity */

.c-range__slider__track__rail__thumb:focus {
  outline: none;
}

.c-range__slider__track__rail__thumb.is-focused {
  box-shadow: 0 0 0 3px
    color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-forms-focused-box-shadow);
}

.c-range__slider:not(.is-disabled) .c-range__slider__track__rail__thumb:active,
.c-range__slider.is-active .c-range__slider__track__rail__thumb:first-of-type {
  background-color: #144a75;
  background-color: var(--zd-range-active-thumb-background-color);
}

.c-range__slider__track__rail__thumb.is-focused:active {
  box-shadow: none;
}

.c-range__slider.is-disabled {
  cursor: default;
}

.c-range__slider.is-disabled .c-range__slider__track {
  background-color: #e9ebed;
  background-color: var(--zd-range-disabled-track-background-color);
  background-image: linear-gradient(#d8dcde, #d8dcde);
  background-image: var(--zd-range-disabled-track-background-image);
}

.c-range__slider.is-disabled .c-range__slider__track__rail__thumb {
  border-color: #d8dcde;
  border-color: var(--zd-range-disabled-thumb-border-color);
  box-shadow: none;
  background-color: #d8dcde;
  background-color: var(--zd-range-disabled-thumb-background-color);
}

.c-range--inline .c-range__slider {
  display: inline-block;
  vertical-align: middle;
  width: 129px;
  width: var(--zd-range--inline__slider-inline-size);
}

/* stylelint-enable selector-max-specificity, no-descending-specificity */

.c-range__slider.is-rtl .c-range__slider__track__rail {
  margin: 0 calc(20px / 2 * -1) 0 calc(20px / 2);
  margin: var(--zd-range__slider__track__rail-margin-rtl);
}

:root {
  --zd-range__label-line-height: calc(30 / 14);
  --zd-range__label--regular-font-weight: var(--zd-font-weight-regular);
  --zd-range__label--sm-line-height: calc(28 / 14);
  --zd-range__hint-line-height: calc(20 / 14);
  --zd-range__hint-margin-top: 8px;
  --zd-range__hint--sm-margin-top: 4px;
  --zd-range__message-line-height: calc(16 / 12);
  --zd-range__message-margin-top: 8px;
  --zd-range__message-padding: var(--zd-spacing);
}

.c-range__label {
  vertical-align: middle;
  line-height: calc(30 / 14);
  line-height: var(--zd-range__label-line-height);
  color: #2f3941;
  color: var(--zd-input__label-color);
  font-size: 14px;
  font-size: var(--zd-input__label-font-size);
  font-weight: 600;
  font-weight: var(--zd-input__label-font-weight);
}

.c-range__label--regular {
  font-weight: 400;
  font-weight: var(--zd-range__label--regular-font-weight);
}

.c-range__label--sm {
  line-height: calc(28 / 14);
  line-height: var(--zd-range__label--sm-line-height);
}

.c-range__hint {
  line-height: calc(20 / 14);
  line-height: var(--zd-range__hint-line-height);
  color: #68737d;
  color: var(--zd-input__hint-color);
  font-size: 14px;
  font-size: var(--zd-input__hint-font-size);
}

.c-range__message {
  display: inline-block;
  vertical-align: middle;
  line-height: calc(16 / 12);
  line-height: var(--zd-range__message-line-height);
  color: #49545c;
  color: var(--zd-input__message-color);
  font-size: 12px;
  font-size: var(--zd-input__message-font-size);
}

.c-range__message--error,
.c-range__message--success,
.c-range__message--warning {
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 20px;
  padding-left: var(--zd-range__message-padding);
}

.c-range__message--error {
  background-image: svg-load('16/alert-error-stroke.svg', color: #cc3340);
  background-image: var(--zd-input__message--error-background-image);
  color: #cc3340;
  color: var(--zd-input__message--error-color);
}

.c-range__message--success {
  background-image: svg-load('16/check-circle-stroke.svg', color: #038153);
  background-image: var(--zd-input__message--success-background-image);
  color: #038153;
  color: var(--zd-input__message--success-color);
}

.c-range__message--warning {
  background-image: svg-load('16/alert-warning-stroke.svg', color: #ad5e18);
  background-image: var(--zd-input__message--warning-background-image);
  color: #ad5e18;
  color: var(--zd-input__message--warning-color);
}

.is-rtl.c-range__message--error,
.is-rtl.c-range__message--success,
.is-rtl.c-range__message--warning {
  background-position: center right;
  padding-right: 20px;
  padding-right: var(--zd-range__message-padding);
  padding-left: 0;
}

.c-range__hint + .c-range__input,
.c-range__hint + .c-range__slider,
.c-range__input + .c-range__hint,
.c-range__slider + .c-range__hint {
  margin-top: 8px;
  margin-top: var(--zd-range__hint-margin-top);
}

.c-range__hint--sm + .c-range__input--sm,
.c-range__hint--sm + .c-range__slider--sm,
.c-range__input--sm + .c-range__hint--sm,
.c-range__slider--sm + .c-range__hint--sm {
  margin-top: 4px;
  margin-top: var(--zd-range__hint--sm-margin-top);
}

.c-range__label + .c-range__hint {
  margin-top: calc(8px / 2 * -1);
  margin-top: calc(var(--zd-range__hint-margin-top) / 2 * -1);
}

.c-range__input + .c-range__message,
.c-range__slider + .c-range__message {
  margin-top: 8px;
  margin-top: var(--zd-range__message-margin-top);
}

.c-range .c-range__hint {
  display: block;
}

.c-range--inline .c-range__input + .c-range__message,
.c-range--inline .c-range__slider + .c-range__message {
  margin-top: 0;
}

:root {
  --zd-txt__input-background-color: var(--zd-color-white);
  --zd-txt__input-border: 1px solid var(--zd-forms-border-color);
  --zd-txt__input-border-radius: 4px;
  --zd-txt__input-color: var(--zd-color-grey-800);
  --zd-txt__input-color-placeholder: var(--zd-color-grey-400);
  --zd-txt__input-font-size: var(--zd-font-size-md);
  --zd-txt__input-line-height: calc(18 / 14);
  --zd-txt__input-height: var(--zd-spacing-xl);
  --zd-txt__input-padding-horizontal: calc(16 / 14 * 1em);
  --zd-txt__input-padding: calc(10 / 14 * 1em) var(--zd-txt__input-padding-horizontal);
  --zd-txt__input-transition:
    border-color .25s ease-in-out,
    box-shadow .1s ease-in-out,
    background-color .25s ease-in-out,
    color .25s ease-in-out;
}

/* 1. Reset for <fieldset> element.
 * 2. Use 100% input width vs. block diplay label to limit label mouse
 *    interaction area.
 * 3. Support label inline with input layout.
 * 4. Hack for browser <= IE10 which fails to render custom @font-face subsets
 *    for input fields.
 * 5. Remove clear button, password reveal, and file entry in IE.
 * 6. Remove non-standard browser features.
 * 7. Prevent FireFox validation styling.
 * 8. Remove number spinner in FireFox. */

.c-txt {
  margin: 0; /* [1] */
  border: 0; /* [1] */
  padding: 0; /* [1] */
}

.c-txt__input {
  -webkit-appearance: none;
          appearance: none;
  transition: border-color .25s ease-in-out,
    box-shadow .1s ease-in-out,
    background-color .25s ease-in-out,
    color .25s ease-in-out;
  transition: var(--zd-txt__input-transition);
  outline: none;
  border: 1px solid #d8dcde;
  border: var(--zd-txt__input-border);
  border-radius: 4px;
  border-radius: var(--zd-txt__input-border-radius);
  background-color: #fff;
  background-color: var(--zd-txt__input-background-color);
  padding: calc(10 / 14 * 1em) calc(16 / 14 * 1em);
  padding: var(--zd-txt__input-padding);
  width: 100%; /* [2] */
  min-height: 40px;
  min-height: var(--zd-txt__input-height);
  box-sizing: border-box;
  vertical-align: middle; /* [3] */
  line-height: calc(18 / 14);
  line-height: var(--zd-txt__input-line-height);
  color: #2f3941;
  color: var(--zd-txt__input-color);
  font-family: inherit;
  font-size: 14px;
  font-size: var(--zd-txt__input-font-size);
}

@media screen\0 {
  .c-txt__input { font-family: sans-serif; } /* [4] */
}

.c-txt__input::-ms-browse {
  border-radius: calc(4px / 2);
  border-radius: calc(var(--zd-txt__input-border-radius) / 2);
  font-size: 12px;
  font-size: var(--zd-font-size-sm);
}

.c-txt__input::-ms-clear,
.c-txt__input::-ms-reveal {
  display: none; /* [5] */
}

.c-txt__input::-webkit-clear-button,
.c-txt__input::-webkit-inner-spin-button,
.c-txt__input::-webkit-search-cancel-button,
.c-txt__input::-webkit-search-results-button {
  display: none; /* [6] */
}

.c-txt__input::-webkit-datetime-edit {
  line-height: 1;
}

.c-txt__input::-webkit-input-placeholder {
  opacity: 1;
  color: #c2c8cc;
  color: var(--zd-txt__input-color-placeholder);
}

.c-txt__input:-ms-input-placeholder {
  opacity: 1;
  color: #c2c8cc;
  color: var(--zd-txt__input-color-placeholder);
}

.c-txt__input::placeholder {
  opacity: 1;
  color: #c2c8cc;
  color: var(--zd-txt__input-color-placeholder);
}

.c-txt__input:invalid { box-shadow: none; }

/* [7] */

.c-txt__input[type='date'],
.c-txt__input[type='datetime-local'],
.c-txt__input[type='file'],
.c-txt__input[type='month'],
.c-txt__input[type='time'],
.c-txt__input[type='week'] {
  max-height: 40px;
  max-height: var(--zd-txt__input-height);
}

.c-txt__input[type='file'] {
  line-height: 1;
}

.c-txt__input[type='file']::-ms-value {
  display: none; /* [5] */
}

/* stylelint-disable-next-line */

@media screen and (min--moz-device-pixel-ratio: 0) {
  .c-txt__input[type='number'] { -webkit-appearance: textfield; appearance: textfield; } /* [8] */
}

.c-txt.is-rtl,
.c-txt__input.is-rtl,
.c-txt__label.is-rtl,
.c-txt__hint.is-rtl,
.c-txt__message.is-rtl {
  direction: rtl;
}

.c-txt__input.is-rtl::-webkit-datetime-edit {
  direction: rtl;
}

.c-txt--inline { display: inline-block; }

.c-txt--inline .c-txt__input { width: auto; }

:root {
  --zd-txt__input-color-swatch-border-radius: calc(var(--zd-txt__input-border-radius) / 2);
  --zd-txt__input-color-swatch-height: 28px;
  --zd-txt__input-color-swatch-margin-horizontal: -11px;
  --zd-txt__input-color-swatch-margin-vertical: calc(calc(var(--zd-txt__input-height) - var(--zd-txt__input-color-swatch-height)) / 2 * -1 + 1px);
  --zd-txt__input-color-swatch-width: calc(100% + calc(var(--zd-txt__input-color-swatch-margin-horizontal) * -2));
  --zd-txt--sm__input-color-swatch-height: 24px;
  --zd-txt--sm__input-color-swatch-margin-horizontal: -9px;
  --zd-txt--sm__input-color-swatch-margin-vertical: calc(calc(var(--zd-txt--sm__input-height) - var(--zd-txt--sm__input-color-swatch-height)) / 2 * -1 + 1px);
  --zd-txt--sm__input-color-swatch-width: calc(100% + calc(var(--zd-txt--sm__input-color-swatch-margin-horizontal) * -2));
}

/* 1. Update color swatch size for Edge. */

@supports (-ms-ime-align: auto) {
  .c-txt__input[type='color'] { padding: 1px 3px; } /* [1] */

  .c-txt--sm__input[type='color'] { padding: 0 2px; } /* [1] */
}

.c-txt__input::-moz-color-swatch {
  margin-top: calc((40px - 28px) / 2 * -1 + 1px);
  margin-top: calc(calc(40px - 28px) / 2 * -1 + 1px);
  margin-top: var(--zd-txt__input-color-swatch-margin-vertical);
  margin-left: -11px;
  margin-left: var(--zd-txt__input-color-swatch-margin-horizontal);
  border: none;
  border-radius: calc(4px / 2);
  border-radius: var(--zd-txt__input-color-swatch-border-radius);
  width: calc(100% + (-11px * -2));
  width: calc(100% + calc(-11px * -2));
  width: var(--zd-txt__input-color-swatch-width);
  height: 28px;
  height: var(--zd-txt__input-color-swatch-height);
}

.c-txt__input::-webkit-color-swatch {
  margin: calc((40px - 28px) / 2 * -1 + 1px) -11px;
  margin: calc(calc(40px - 28px) / 2 * -1 + 1px) -11px;
  margin: var(--zd-txt__input-color-swatch-margin-vertical) var(--zd-txt__input-color-swatch-margin-horizontal);
  border: none;
  border-radius: calc(4px / 2);
  border-radius: var(--zd-txt__input-color-swatch-border-radius);
}

.c-txt__input--sm::-webkit-color-swatch {
  margin: calc((32px - 24px) / 2 * -1 + 1px) -9px;
  margin: calc(calc(32px - 24px) / 2 * -1 + 1px) -9px;
  margin: var(--zd-txt--sm__input-color-swatch-margin-vertical) var(--zd-txt--sm__input-color-swatch-margin-horizontal);
}

.c-txt__input--sm::-moz-color-swatch {
  margin-top: calc((32px - 24px) / 2 * -1 + 1px);
  margin-top: calc(calc(32px - 24px) / 2 * -1 + 1px);
  margin-top: var(--zd-txt--sm__input-color-swatch-margin-vertical);
  margin-left: -9px;
  margin-left: var(--zd-txt--sm__input-color-swatch-margin-horizontal);
  width: calc(100% + (-9px * -2));
  width: calc(100% + calc(-9px * -2));
  width: var(--zd-txt--sm__input-color-swatch-width);
  height: 24px;
  height: var(--zd-txt--sm__input-color-swatch-height);
}

.c-txt__input::-webkit-color-swatch-wrapper { padding: 0; }

/* 1. Remove vertical scrollbar on <textarea> (IE). */

.c-txt__input--area {
  resize: none;
  overflow: auto; /* [1] */
}

.c-txt__input--area.is-resizable {
  resize: vertical;
}

/* stylelint-disable at-rule-empty-line-before */

/* stylelint-enable at-rule-empty-line-before */

:root {
  --zd-txt__input--select-padding-unit: 40;
  --zd-txt__input--select-padding-right: calc(var(--zd-txt__input--select-padding-unit) / 14 * 1em);
  --zd-txt__input--select-transition:
    background-image .25s ease-in-out,
    transform .25s ease-in-out,
    var(--zd-txt__input-transition);
  --zd-txt__input--select__chevron-background-image: svg-load('16/chevron-down-stroke.svg', color: var(--zd-color-grey-600));
  --zd-txt__input--select__chevron-background-position-x: calc((var(--zd-txt__input--select-padding-unit) / 2 - 8) / 14 * 1em);
  --zd-txt__input--select__chevron-background-position: right var(--zd-txt__input--select__chevron-background-position-x) center;
  --zd-txt__input--select__chevron-disabled-background-image: svg-load('16/chevron-down-stroke.svg', color: var(--zd-forms-disabled-color));
  --zd-txt__input--select__chevron-hovered-background-image: svg-load('16/chevron-down-stroke.svg', color: var(--zd-color-grey-700));
  --zd-txt__input--select__chevron-width: calc(var(--zd-txt__input--select-padding-unit) * 1px);
  --zd-txt__input--select__chevron-transform:
    rotate(180deg)
    translateY(-1px);
  --zd-txt-rtl__input--select__chevron-background-position: left var(--zd-txt__input--select__chevron-background-position-x) center;
  --zd-txt-rtl__input--select__chevron-transform:
    rotate(-180deg)
    translateY(-1px);
}

/* 1. Select reset.
 * 2. Button reset.
 * 3. Calendar picker reset (standalone ruleset prevents non-Chrome parsing
 *    errors). */

.c-txt__input--select {
  -webkit-appearance: none;
          appearance: none; /* [1] */
  position: relative;
  padding-right: calc(40 / 14 * 1em);
  padding-right: var(--zd-txt__input--select-padding-right);
  text-align: left; /* [2] */
}

.c-txt__input--select:not(select)::before {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: calc(40 * 1px);
  width: var(--zd-txt__input--select__chevron-width);
  height: 40px;
  height: var(--zd-txt__input-height);
  content: '';
}

.c-txt__input--select:not(select)::before,
select.c-txt__input--select {
  transition: background-image .25s ease-in-out,
    border-color .25s ease-in-out,
    box-shadow .1s ease-in-out,
    background-color .25s ease-in-out,
    color .25s ease-in-out,
    -webkit-transform .25s ease-in-out;
  transition: background-image .25s ease-in-out,
    transform .25s ease-in-out,
    border-color .25s ease-in-out,
    box-shadow .1s ease-in-out,
    background-color .25s ease-in-out,
    color .25s ease-in-out;
  transition: background-image .25s ease-in-out,
    transform .25s ease-in-out,
    border-color .25s ease-in-out,
    box-shadow .1s ease-in-out,
    background-color .25s ease-in-out,
    color .25s ease-in-out,
    -webkit-transform .25s ease-in-out;
  transition: var(--zd-txt__input--select-transition);
  background-image: svg-load('16/chevron-down-stroke.svg', color: #68737d);
  background-image: var(--zd-txt__input--select__chevron-background-image);
  background-repeat: no-repeat;
  background-position: right calc((40 / 2 - 8) / 14 * 1em) center;
  background-position: var(--zd-txt__input--select__chevron-background-position);
}

select.c-txt__input--select::-ms-expand {
  display: none; /* [1] */
}

select.c-txt__input--select::-ms-value {
  background-color: transparent; /* [1] */
  color: inherit; /* [1] */
}

select.c-txt__input--select:-moz-focusring {
  -moz-transition: none;
  transition: none;
  text-shadow: 0 0 0 #2f3941;
  text-shadow: 0 0 0 var(--zd-txt__input-color); /* [1] */
  color: transparent; /* [1] */
}

.c-txt__input--select::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent; /* [3] */
  cursor: pointer;
  padding: 0; /* [3] */
  width: calc(40 * 1px);
  width: var(--zd-txt__input--select__chevron-width);
  height: 40px;
  height: var(--zd-txt__input-height);
  color: transparent; /* [3] */
  content: '';
}

.c-txt__input--select.is-hovered:not(.is-disabled):not([disabled]):not(select)::before,.c-txt__input--select:hover:not(.is-disabled):not([disabled]):not(select)::before,
.c-txt__input--select.is-focused:not(.is-disabled):not([disabled]):not(select)::before,
.c-txt__input--select:focus:not(.is-disabled):not([disabled]):not(select)::before,
select.c-txt__input--select.is-hovered:not(.is-disabled):not([disabled]),
select.c-txt__input--select:hover:not(.is-disabled):not([disabled]),
select.c-txt__input--select.is-focused:not(.is-disabled):not([disabled]),
select.c-txt__input--select:focus:not(.is-disabled):not([disabled]) {
  background-image: svg-load('16/chevron-down-stroke.svg', color: #49545c);
  background-image: var(--zd-txt__input--select__chevron-hovered-background-image);
}

/* stylelint-disable-next-line no-descending-specificity */

.c-txt__input--select.is-open:not(.is-disabled):not([disabled]):not(select)::before {
  -webkit-transform: rotate(180deg)
    translateY(-1px);
          transform: rotate(180deg)
    translateY(-1px);
  -webkit-transform: var(--zd-txt__input--select__chevron-transform);
          transform: var(--zd-txt__input--select__chevron-transform);
}

/* stylelint-disable selector-no-qualifying-type */

.c-txt__input--select.is-disabled:not(select)::before,.c-txt__input--select[disabled]:not(select)::before,
select.c-txt__input--select[disabled] {
  background-image: svg-load('16/chevron-down-stroke.svg', color: #c2c8cc);
  background-image: var(--zd-txt__input--select__chevron-disabled-background-image);
  cursor: default;
}

/* stylelint-enable selector-no-qualifying-type */

.is-rtl.c-txt__input--select {
  padding-right: calc(16 / 14 * 1em);
  padding-right: var(--zd-txt__input-padding-horizontal);
  padding-left: calc(40 / 14 * 1em);
  padding-left: var(--zd-txt__input--select-padding-right);
  text-align: right;
}

.is-rtl.c-txt__input--select:not(select)::before {
  right: auto;
  left: 0;
}

.is-rtl.c-txt__input--select::-webkit-calendar-picker-indicator {
  right: auto; /* [3] */
  left: 0; /* [3] */
}

.is-rtl.c-txt__input--select:not(select)::before,
select.is-rtl.c-txt__input--select {
  background-position: left calc((40 / 2 - 8) / 14 * 1em) center;
  background-position: var(--zd-txt-rtl__input--select__chevron-background-position);
}

.is-rtl.c-txt__input--select.is-open:not(.is-disabled):not([disabled]):not(select)::before {
  -webkit-transform: rotate(-180deg)
    translateY(-1px);
          transform: rotate(-180deg)
    translateY(-1px);
  -webkit-transform: var(--zd-txt-rtl__input--select__chevron-transform);
          transform: var(--zd-txt-rtl__input--select__chevron-transform);
}

:root {
  --zd-txt__input--tag-padding-horizontal: 8px;
  --zd-txt__input--tag-padding: 2px var(--zd-txt__input--tag-padding-horizontal);
  --zd-txt__input__tag-margin: 2px;
  --zd-txt__input--tag--sm-padding-vertical: 3px;
}

.c-txt__input--tag .c-label,
.c-txt__input--tag .c-tag {
  margin: 2px;
  margin: var(--zd-txt__input__tag-margin);
}

.c-txt__input.c-txt__input--tag {
  display: flex;
  flex-wrap: wrap;
  padding: 2px 8px;
  padding: var(--zd-txt__input--tag-padding);
}

.c-txt__input--tag.c-txt__input--sm {
  padding-top: 3px;
  padding-top: var(--zd-txt__input--tag--sm-padding-vertical);
  padding-bottom: 3px;
  padding-bottom: var(--zd-txt__input--tag--sm-padding-vertical);
}

.c-txt__input--tag.c-txt__input--select {
  padding-right: calc(40 / 14 * 1em);
  padding-right: var(--zd-txt__input--select-padding-right);
}

/* stylelint-disable-next-line selector-max-specificity */

.is-rtl.c-txt__input.c-txt__input--tag.c-txt__input--select {
  padding-right: 8px;
  padding-right: var(--zd-txt__input--tag-padding-horizontal);
  padding-left: calc(40 / 14 * 1em);
  padding-left: var(--zd-txt__input--select-padding-right);
}

:root {
  --zd-txt-disabled-background-color: var(--zd-color-grey-100);
  --zd-txt-disabled-border-color: var(--zd-color-grey-200);
  --zd-txt-disabled-color: var(--zd-forms-disabled-color);
  --zd-txt-focused-border-color: var(--zd-forms-accent-color);
  --zd-txt-focused-box-shadow: var(--zd-forms-focused-box-shadow);
  --zd-txt-focused-box-shadow-inset: inset var(--zd-forms-focused-box-shadow);
  --zd-txt-hovered-border-color: var(--zd-forms-hovered-border-color);
}

.c-txt__input.is-hovered:not(.is-disabled):not([disabled]),.c-txt__input:hover:not(.is-disabled):not([disabled]) {
  border-color: #5293c7;
  border-color: var(--zd-txt-hovered-border-color);
}

.c-txt__input:focus {
  outline: none;
}

.c-txt__input.is-focused:not(.is-disabled):not([disabled]),.c-txt__input:focus:not(.is-disabled):not([disabled]) {
  border-color: #1f73b7;
  border-color: var(--zd-txt-focused-border-color);
  box-shadow: 0 0 0 3px
    color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-txt-focused-box-shadow);
}

.c-txt__input--focus-inset.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--focus-inset:focus:not(.is-disabled):not([disabled]) {
  box-shadow: inset 0 0 0 3px
    color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-txt-focused-box-shadow-inset);
}

.c-txt__input.is-disabled,.c-txt__input[disabled] {
  border-color: #e9ebed;
  border-color: var(--zd-txt-disabled-border-color);
  box-shadow: none;
  background-color: #f8f9f9;
  background-color: var(--zd-txt-disabled-background-color);
  cursor: default;
  color: #c2c8cc;
  color: var(--zd-txt-disabled-color);
}

/* Necessary to override common bedrock placeholder styling */

.c-txt__input.is-disabled::-webkit-input-placeholder, .c-txt__input[disabled]::-webkit-input-placeholder {
  color: #c2c8cc;
  color: var(--zd-txt-disabled-color);
}

.c-txt__input.is-disabled:-ms-input-placeholder, .c-txt__input[disabled]:-ms-input-placeholder {
  color: #c2c8cc;
  color: var(--zd-txt-disabled-color);
}

.c-txt__input.is-disabled::placeholder,.c-txt__input[disabled]::placeholder {
  color: #c2c8cc;
  color: var(--zd-txt-disabled-color);
}

:root {
  --zd-txt--error-focused-border-color: var(--zd-color-red-600);
  --zd-txt--error-focused-box-shadow:
    0 0 0 3px
    color-mod(var(--zd-txt--error-focused-border-color) alpha(35%));
  --zd-txt--error-focused-box-shadow-inset: inset var(--zd-txt--error-focused-box-shadow);
  --zd-txt--success-focused-border-color: var(--zd-color-green-600);
  --zd-txt--success-focused-box-shadow:
    0 0 0 3px
    color-mod(var(--zd-txt--success-focused-border-color) alpha(35%));
  --zd-txt--success-focused-box-shadow-inset: inset var(--zd-txt--success-focused-box-shadow);
  --zd-txt--warning-focused-border-color: var(--zd-color-yellow-600);
  --zd-txt--warning-focused-box-shadow:
    0 0 0 3px
    color-mod(var(--zd-txt--warning-focused-border-color) alpha(35%));
  --zd-txt--warning-focused-box-shadow-inset: inset var(--zd-txt--warning-focused-box-shadow);
}

/* stylelint-disable selector-max-specificity */

.c-txt__input.c-txt__input--error:not(.is-disabled):not([disabled]) {
  border-color: #e35b66;
  border-color: var(--zd-forms-error-color);
}

.c-txt__input--error.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--error:focus:not(.is-disabled):not([disabled]) {
  box-shadow: 0 0 0 3px
    color-mod(#cc3340 alpha(35%));
  box-shadow: var(--zd-txt--error-focused-box-shadow);
}

.c-txt__input--focus-inset.c-txt__input--error.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--focus-inset.c-txt__input--error:focus:not(.is-disabled):not([disabled]) {
  box-shadow: inset 0 0 0 3px
    color-mod(#cc3340 alpha(35%));
  box-shadow: var(--zd-txt--error-focused-box-shadow-inset);
}

.c-txt__input.c-txt__input--success:not(.is-disabled):not([disabled]) {
  border-color: #5eae91;
  border-color: var(--zd-forms-success-color);
}

.c-txt__input--success.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--success:focus:not(.is-disabled):not([disabled]) {
  box-shadow: 0 0 0 3px
    color-mod(#038153 alpha(35%));
  box-shadow: var(--zd-txt--success-focused-box-shadow);
}

.c-txt__input--focus-inset.c-txt__input--success.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--focus-inset.c-txt__input--success:focus:not(.is-disabled):not([disabled]) {
  box-shadow: inset 0 0 0 3px
    color-mod(#038153 alpha(35%));
  box-shadow: var(--zd-txt--success-focused-box-shadow-inset);
}

.c-txt__input.c-txt__input--warning:not(.is-disabled):not([disabled]) {
  border-color: #ffb648;
  border-color: var(--zd-forms-warning-color);
}

.c-txt__input--warning.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--warning:focus:not(.is-disabled):not([disabled]) {
  box-shadow: 0 0 0 3px
    color-mod(#ed961c alpha(35%));
  box-shadow: var(--zd-txt--warning-focused-box-shadow);
}

.c-txt__input--focus-inset.c-txt__input--warning.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--focus-inset.c-txt__input--warning:focus:not(.is-disabled):not([disabled]) {
  box-shadow: inset 0 0 0 3px
    color-mod(#ed961c alpha(35%));
  box-shadow: var(--zd-txt--warning-focused-box-shadow-inset);
}

/* stylelint-enable selector-max-specificity */

:root {
  --zd-txt--sm__input-padding-horizontal: calc(12 / 14 * 1em);
  --zd-txt--sm__input-padding: calc(6 / 14 * 1em) var(--zd-txt--sm__input-padding-horizontal);
  --zd-txt--sm__input-height: 32px;
  --zd-txt--sm__input--select-padding-unit: 32;
  --zd-txt--sm__input--select-padding-right: calc(var(--zd-txt--sm__input--select-padding-unit) / 14 * 1em);
  --zd-txt--sm__input--select__chevron-background-position-x: calc((var(--zd-txt--sm__input--select-padding-unit) / 2 - 6) / 14 * 1em);
  --zd-txt--sm__input--select__chevron-background-position: right var(--zd-txt--sm__input--select__chevron-background-position-x) center;
  --zd-txt--sm__input--select__chevron-background-size: 12px;
  --zd-txt--sm__input--select__chevron-width: calc(var(--zd-txt--sm__input--select-padding-unit) * 1px);
  --zd-txt-rtl--sm__input--select__chevron-background-position: left var(--zd-txt--sm__input--select__chevron-background-position-x) center;
  --zd-txt--sm__media-end-margin: calc(var(--zd-txt--sm__input-padding-horizontal) * (3 / 4));
}

.c-txt__input--sm {
  padding: calc(6 / 14 * 1em) calc(12 / 14 * 1em);
  padding: var(--zd-txt--sm__input-padding);
  min-height: 32px;
  min-height: var(--zd-txt--sm__input-height);
}

.c-txt__input--sm[type='date'],
.c-txt__input--sm[type='datetime-local'],
.c-txt__input--sm[type='file'],
.c-txt__input--sm[type='month'],
.c-txt__input--sm[type='time'],
.c-txt__input--sm[type='week'] {
  max-height: 32px;
  max-height: var(--zd-txt--sm__input-height);
}

.c-txt__input--sm::-ms-browse {
  font-size: 11px;
}

.c-txt__input--sm.c-txt__input--select {
  padding-right: calc(32 / 14 * 1em);
  padding-right: var(--zd-txt--sm__input--select-padding-right);
}

.c-txt__input--sm.c-txt__input--select:not(select)::before {
  width: calc(32 * 1px);
  width: var(--zd-txt--sm__input--select__chevron-width);
}

.c-txt__input--sm.c-txt__input--select:not(select)::before,
select.c-txt__input--sm.c-txt__input--select {
  background-position: right calc((32 / 2 - 6) / 14 * 1em) center;
  background-position: var(--zd-txt--sm__input--select__chevron-background-position);
  background-size: 12px;
  background-size: var(--zd-txt--sm__input--select__chevron-background-size);
  height: 32px;
  height: var(--zd-txt--sm__input-height);
}

.c-txt__input--sm.c-txt__input--select::-webkit-calendar-picker-indicator {
  width: calc(32 * 1px);
  width: var(--zd-txt--sm__input--select__chevron-width);
  height: 32px;
  height: var(--zd-txt--sm__input-height);
}

.is-rtl.c-txt__input--sm.c-txt__input--select {
  padding-right: calc(12 / 14 * 1em);
  padding-right: var(--zd-txt--sm__input-padding-horizontal);
  padding-left: calc(32 / 14 * 1em);
  padding-left: var(--zd-txt--sm__input--select-padding-right);
}

/* stylelint-disable no-descending-specificity, selector-max-specificity */

.is-rtl.c-txt__input--sm.c-txt__input--select:not(select)::before,
select.is-rtl.c-txt__input--sm.c-txt__input--select {
  background-position: left calc((32 / 2 - 6) / 14 * 1em) center;
  background-position: var(--zd-txt-rtl--sm__input--select__chevron-background-position);
}

.c-txt__input--sm .c-txt__input--media__figure:first-child,
.is-rtl.c-txt__input--sm .c-txt__input--media__figure:last-child {
  margin: auto calc((12 / 14 * 1em) * (3 / 4)) auto 0;
  margin: auto calc(calc(12 / 14 * 1em) * (3 / 4)) auto 0;
  margin: auto var(--zd-txt--sm__media-end-margin) auto 0;
}

.c-txt__input--sm .c-txt__input--media__figure:last-child,
.is-rtl.c-txt__input--sm .c-txt__input--media__figure:first-child {
  margin: auto 0 auto calc((12 / 14 * 1em) * (3 / 4));
  margin: auto 0 auto calc(calc(12 / 14 * 1em) * (3 / 4));
  margin: auto 0 auto var(--zd-txt--sm__media-end-margin);
}

/* stylelint-enable no-descending-specificity, selector-max-specificity */

:root {
  --zd-txt__input--select--bare-height: calc(var(--zd-txt__input-line-height) * 1em);
}

/* 1. Calendar picker reset (standalone ruleset prevents non-Chrome parsing
 *    errors). */

.c-txt__input.c-txt__input--bare {
  border: none;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  min-height: 1em;
}

.c-txt__input.is-focused:not(.is-disabled):not([disabled]).c-txt__input--bare,.c-txt__input:focus:not(.is-disabled):not([disabled]).c-txt__input--bare {
  box-shadow: none;
}

.c-txt__input.is-disabled.c-txt__input--bare,.c-txt__input[disabled].c-txt__input--bare {
  background-color: transparent;
}

.c-txt__input--select.c-txt__input--bare:not(select)::before {
  height: calc((18 / 14) * 1em);
  height: calc(calc(18 / 14) * 1em);
  height: var(--zd-txt__input--select--bare-height);
}

.c-txt__input--select.c-txt__input--bare::-webkit-calendar-picker-indicator {
  height: calc((18 / 14) * 1em);
  height: calc(calc(18 / 14) * 1em);
  height: var(--zd-txt__input--select--bare-height); /* [1] */
}

:root {
  --zd-txt__input--media__figure-color: var(--zd-color-grey-400);
  --zd-txt__input--media__figure-margin: calc(var(--zd-txt__input-padding-horizontal) * (3 / 4));
  --zd-txt__input--media__figure-size: 16px;
  --zd-txt__input--sm--media__figure-size: 12px;
}

.c-txt__input--media {
  display: flex;
  align-items: baseline;
  cursor: text;
}

.c-txt__input--media__body {
  flex-grow: 1;
}

.c-txt__input--media__figure {
  margin: auto;
  max-height: 16px;
  max-height: var(--zd-txt__input--media__figure-size);
  color: #c2c8cc;
  color: var(--zd-txt__input--media__figure-color);
}

.c-txt__input--media__figure svg {
  width: 16px;
  width: var(--zd-txt__input--media__figure-size);
  height: 16px;
  height: var(--zd-txt__input--media__figure-size);
}

.c-txt__input--sm .c-txt__input--media__figure svg {
  width: 12px;
  width: var(--zd-txt__input--sm--media__figure-size);
  height: 12px;
  height: var(--zd-txt__input--sm--media__figure-size);
}

/* stylelint-disable no-descending-specificity */

.c-txt__input--media__figure:first-child,
.is-rtl .c-txt__input--media__figure:last-child {
  margin-right: calc((16 / 14 * 1em) * (3 / 4));
  margin-right: calc(calc(16 / 14 * 1em) * (3 / 4));
  margin-right: var(--zd-txt__input--media__figure-margin);
  margin-left: 0;
}

.c-txt__input--media__figure:last-child,
.is-rtl .c-txt__input--media__figure:first-child {
  margin-right: 0;
  margin-left: calc((16 / 14 * 1em) * (3 / 4));
  margin-left: calc(calc(16 / 14 * 1em) * (3 / 4));
  margin-left: var(--zd-txt__input--media__figure-margin);
}

/* stylelint-enable no-descending-specificity */

:root {
  --zd-txt__label-line-height: calc(30 / 14);
  --zd-txt__label--regular-font-weight: var(--zd-font-weight-regular);
  --zd-txt__label--sm-line-height: calc(28 / 14);
  --zd-txt__hint-line-height: calc(20 / 14);
  --zd-txt__hint-margin-top: 8px;
  --zd-txt__hint--sm-margin-top: 4px;
  --zd-txt__message-line-height: calc(16 / 12);
  --zd-txt__message-margin-top: 8px;
  --zd-txt__message-padding: 20px;
}

/* 1. Support label inline with input layout. */

.c-txt__label {
  vertical-align: middle; /* [1] */
  line-height: calc(30 / 14);
  line-height: var(--zd-txt__label-line-height);
  color: #2f3941;
  color: var(--zd-input__label-color);
  font-size: 14px;
  font-size: var(--zd-input__label-font-size);
  font-weight: 600;
  font-weight: var(--zd-input__label-font-weight);
}

.c-txt__label--regular {
  font-weight: 400;
  font-weight: var(--zd-txt__label--regular-font-weight);
}

.c-txt__label--sm {
  line-height: calc(28 / 14);
  line-height: var(--zd-txt__label--sm-line-height);
}

.c-txt__hint {
  line-height: calc(20 / 14);
  line-height: var(--zd-txt__hint-line-height);
  color: #68737d;
  color: var(--zd-input__hint-color);
  font-size: 14px;
  font-size: var(--zd-input__hint-font-size);
}

.c-txt__message {
  display: inline-block;
  vertical-align: middle; /* [1] */
  line-height: calc(16 / 12);
  line-height: var(--zd-txt__message-line-height);
  color: #49545c;
  color: var(--zd-input__message-color);
  font-size: 12px;
  font-size: var(--zd-input__message-font-size);
}

.c-txt__message--error,
.c-txt__message--success,
.c-txt__message--warning {
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 20px;
  padding-left: var(--zd-txt__message-padding);
}

.c-txt__message--error {
  background-image: svg-load('16/alert-error-stroke.svg', color: #cc3340);
  background-image: var(--zd-input__message--error-background-image);
  color: #cc3340;
  color: var(--zd-input__message--error-color);
}

.c-txt__message--success {
  background-image: svg-load('16/check-circle-stroke.svg', color: #038153);
  background-image: var(--zd-input__message--success-background-image);
  color: #038153;
  color: var(--zd-input__message--success-color);
}

.c-txt__message--warning {
  background-image: svg-load('16/alert-warning-stroke.svg', color: #ad5e18);
  background-image: var(--zd-input__message--warning-background-image);
  color: #ad5e18;
  color: var(--zd-input__message--warning-color);
}

.is-rtl.c-txt__message--error,
.is-rtl.c-txt__message--success,
.is-rtl.c-txt__message--warning {
  background-position: center right;
  padding-right: 20px;
  padding-right: var(--zd-txt__message-padding);
  padding-left: 0;
}

.c-txt__hint + .c-txt__input,
.c-txt__input + .c-txt__hint {
  margin-top: 8px;
  margin-top: var(--zd-txt__hint-margin-top);
}

.c-txt__hint--sm + .c-txt__input--sm,
.c-txt__input--sm + .c-txt__hint--sm {
  margin-top: 4px;
  margin-top: var(--zd-txt__hint--sm-margin-top);
}

.c-txt__label + .c-txt__hint {
  margin-top: calc(8px / 2 * -1);
  margin-top: calc(var(--zd-txt__hint-margin-top) / 2 * -1);
}

.c-txt__input + .c-txt__message {
  margin-top: 8px;
  margin-top: var(--zd-txt__message-margin-top);
}

.c-txt .c-txt__hint {
  display: block;
}

.c-txt--inline .c-txt__input + .c-txt__message {
  margin-top: 0;
}

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

:root {
  --zd-tag-accent-color: var(--zd-color-grey-200);
  --zd-tag-focused-box-shadow: 0 0 0 2px;
}

:root {
  --zd-tag-background-color: var(--zd-color-grey-200);
  --zd-tag-border-radius: 2px;
  --zd-tag-color: var(--zd-color-grey-700);
  --zd-tag-font-size: var(--zd-font-size-sm);
  --zd-tag-font-weight: var(--zd-font-weight-semibold);
  --zd-tag-height: var(--zd-spacing);
  --zd-tag-line-height: calc(20 / 12);
  --zd-tag-padding-horizontal: 8px;
  --zd-tag-padding: 0 var(--zd-tag-padding-horizontal);
  --zd-tag-transition: box-shadow .1s ease-in-out;
}

/* 1. Reset for <button> element.
 * 2. Reset for <a>nchor element. */

.c-tag {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  transition: box-shadow .1s ease-in-out;
  transition: var(--zd-tag-transition);
  border: 0; /* [1] */
  border-radius: 2px;
  border-radius: var(--zd-tag-border-radius);
  background-color: #e9ebed;
  background-color: var(--zd-tag-background-color);
  padding: 0 8px;
  padding: var(--zd-tag-padding);
  max-width: 100%;
  height: 20px;
  height: var(--zd-tag-height);
  overflow: hidden;
  text-decoration: none; /* [2] */
  line-height: calc(20 / 12);
  line-height: var(--zd-tag-line-height);
  color: #49545c;
  color: var(--zd-tag-color);
  font-size: 12px;
  font-size: var(--zd-tag-font-size);
  font-weight: 600;
  font-weight: var(--zd-tag-font-weight);
}

.c-tag:hover {
  cursor: default;
  text-decoration: none; /* [2] */
  color: #49545c;
  color: var(--zd-tag-color); /* [2] */
}

.c-tag.is-focused,.c-tag:focus {
  outline: 0;
  box-shadow:
    0 0 0 2px
    color-mod(#68737d alpha(35%));
  box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-color-grey-600) alpha(35%));
  text-decoration: none; /* [2] */
  color: #49545c;
  color: var(--zd-tag-color); /* [2] */
}

.c-tag:link:hover, .c-tag:visited:hover, area[href].c-tag:hover {
  cursor: pointer;
}

.c-tag:-webkit-any-link:hover {
  cursor: pointer;
}

.c-tag:any-link:hover {
  cursor: pointer;
}

.c-tag.is-rtl {
  direction: rtl;
}

.c-tag > * {
  min-width: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:root {
  --zd-tag--azure-background-color: var(--zd-color-secondary-azure-600);
  --zd-tag--blue-background-color: var(--zd-color-blue-600);
  --zd-tag--crimson-background-color: var(--zd-color-secondary-crimson-600);
  --zd-tag--fuschia-background-color: var(--zd-color-secondary-fuschia-600);
  --zd-tag--green-background-color: var(--zd-color-green-600);
  --zd-tag--grey-background-color: var(--zd-color-grey-600);
  --zd-tag--kale-background-color: var(--zd-color-kale-600);
  --zd-tag--lemon-background-color: var(--zd-color-secondary-lemon-600);
  --zd-tag--lime-background-color: var(--zd-color-secondary-lime-600);
  --zd-tag--mint-background-color: var(--zd-color-secondary-mint-600);
  --zd-tag--orange-background-color: var(--zd-color-secondary-orange-600);
  --zd-tag--pink-background-color: var(--zd-color-secondary-pink-600);
  --zd-tag--purple-background-color: var(--zd-color-secondary-purple-600);
  --zd-tag--red-background-color: var(--zd-color-red-600);
  --zd-tag--royal-background-color: var(--zd-color-secondary-royal-600);
  --zd-tag--teal-background-color: var(--zd-color-secondary-teal-600);
  --zd-tag--yellow-background-color: var(--zd-color-yellow-400);
  --zd-tag--color-color: var(--zd-color-white);
  --zd-tag--yellow-color: var(--zd-color-yellow-800);
  --zd-tag--azure-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--azure-background-color) alpha(35%));
  --zd-tag--blue-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--blue-background-color) alpha(35%));
  --zd-tag--crimson-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--crimson-background-color) alpha(35%));
  --zd-tag--fuschia-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--fuschia-background-color) alpha(35%));
  --zd-tag--green-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--green-background-color) alpha(35%));
  --zd-tag--grey-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--grey-background-color) alpha(35%));
  --zd-tag--kale-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--kale-background-color) alpha(35%));
  --zd-tag--lemon-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--lemon-background-color) alpha(35%));
  --zd-tag--lime-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--lime-background-color) alpha(35%));
  --zd-tag--mint-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--mint-background-color) alpha(35%));
  --zd-tag--orange-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--orange-background-color) alpha(35%));
  --zd-tag--pink-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--pink-background-color) alpha(35%));
  --zd-tag--purple-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--purple-background-color) alpha(35%));
  --zd-tag--red-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--red-background-color) alpha(35%));
  --zd-tag--royal-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--royal-background-color) alpha(35%));
  --zd-tag--teal-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--teal-background-color) alpha(35%));
  --zd-tag--yellow-focused-box-shadow:
    var(--zd-tag-focused-box-shadow)
    color-mod(var(--zd-tag--yellow-background-color) alpha(35%));
}

.c-tag.c-tag--azure {
  background-color: #1371d6;
  background-color: var(--zd-tag--azure-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--blue {
  background-color: #1f73b7;
  background-color: var(--zd-tag--blue-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--crimson {
  background-color: #c72a1c;
  background-color: var(--zd-tag--crimson-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--fuschia {
  background-color: #a81897;
  background-color: var(--zd-tag--fuschia-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--green {
  background-color: #038153;
  background-color: var(--zd-tag--green-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--grey {
  background-color: #68737d;
  background-color: var(--zd-tag--grey-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--kale {
  background-color: #04444d;
  background-color: var(--zd-tag--kale-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--lemon {
  background-color: #ffbb10;
  background-color: var(--zd-tag--lemon-background-color);
  color: #703b15;
  color: var(--zd-tag--yellow-color);
}

.c-tag.c-tag--lime {
  background-color: #2e8200;
  background-color: var(--zd-tag--lime-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--mint {
  background-color: #058541;
  background-color: var(--zd-tag--mint-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--orange {
  background-color: #bf5000;
  background-color: var(--zd-tag--orange-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--pink {
  background-color: #d42054;
  background-color: var(--zd-tag--pink-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--purple {
  background-color: #6a27b8;
  background-color: var(--zd-tag--purple-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--red {
  background-color: #cc3340;
  background-color: var(--zd-tag--red-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--royal {
  background-color: #3353e2;
  background-color: var(--zd-tag--royal-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--teal {
  background-color: #028079;
  background-color: var(--zd-tag--teal-background-color);
  color: #fff;
  color: var(--zd-tag--color-color);
}

.c-tag.c-tag--yellow {
  background-color: #ffb648;
  background-color: var(--zd-tag--yellow-background-color);
  color: #703b15;
  color: var(--zd-tag--yellow-color);
}

.c-tag.c-tag--azure.is-focused,.c-tag.c-tag--azure:focus {
  box-shadow: 0 0 0 2px
    color-mod(#1371d6 alpha(35%));
  box-shadow: var(--zd-tag--azure-focused-box-shadow);
}

.c-tag.c-tag--blue.is-focused,.c-tag.c-tag--blue:focus {
  box-shadow: 0 0 0 2px
    color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-tag--blue-focused-box-shadow);
}

.c-tag.c-tag--crimson.is-focused,.c-tag.c-tag--crimson:focus {
  box-shadow: 0 0 0 2px
    color-mod(#c72a1c alpha(35%));
  box-shadow: var(--zd-tag--crimson-focused-box-shadow);
}

.c-tag.c-tag--fuschia.is-focused,.c-tag.c-tag--fuschia:focus {
  box-shadow: 0 0 0 2px
    color-mod(#a81897 alpha(35%));
  box-shadow: var(--zd-tag--fuschia-focused-box-shadow);
}

.c-tag.c-tag--green.is-focused,.c-tag.c-tag--green:focus {
  box-shadow: 0 0 0 2px
    color-mod(#038153 alpha(35%));
  box-shadow: var(--zd-tag--green-focused-box-shadow);
}

.c-tag.c-tag--grey.is-focused,.c-tag.c-tag--grey:focus {
  box-shadow: 0 0 0 2px
    color-mod(#68737d alpha(35%));
  box-shadow: var(--zd-tag--grey-focused-box-shadow);
}

.c-tag.c-tag--kale.is-focused,.c-tag.c-tag--kale:focus {
  box-shadow: 0 0 0 2px
    color-mod(#04444d alpha(35%));
  box-shadow: var(--zd-tag--kale-focused-box-shadow);
}

.c-tag.c-tag--lemon.is-focused,.c-tag.c-tag--lemon:focus {
  box-shadow: 0 0 0 2px
    color-mod(#ffbb10 alpha(35%));
  box-shadow: var(--zd-tag--lemon-focused-box-shadow);
}

.c-tag.c-tag--lime.is-focused,.c-tag.c-tag--lime:focus {
  box-shadow: 0 0 0 2px
    color-mod(#2e8200 alpha(35%));
  box-shadow: var(--zd-tag--lime-focused-box-shadow);
}

.c-tag.c-tag--mint.is-focused,.c-tag.c-tag--mint:focus {
  box-shadow: 0 0 0 2px
    color-mod(#058541 alpha(35%));
  box-shadow: var(--zd-tag--mint-focused-box-shadow);
}

.c-tag.c-tag--orange.is-focused,.c-tag.c-tag--orange:focus {
  box-shadow: 0 0 0 2px
    color-mod(#bf5000 alpha(35%));
  box-shadow: var(--zd-tag--orange-focused-box-shadow);
}

.c-tag.c-tag--pink.is-focused,.c-tag.c-tag--pink:focus {
  box-shadow: 0 0 0 2px
    color-mod(#d42054 alpha(35%));
  box-shadow: var(--zd-tag--pink-focused-box-shadow);
}

.c-tag.c-tag--purple.is-focused,.c-tag.c-tag--purple:focus {
  box-shadow: 0 0 0 2px
    color-mod(#6a27b8 alpha(35%));
  box-shadow: var(--zd-tag--purple-focused-box-shadow);
}

.c-tag.c-tag--red.is-focused,.c-tag.c-tag--red:focus {
  box-shadow: 0 0 0 2px
    color-mod(#cc3340 alpha(35%));
  box-shadow: var(--zd-tag--red-focused-box-shadow);
}

.c-tag.c-tag--royal.is-focused,.c-tag.c-tag--royal:focus {
  box-shadow: 0 0 0 2px
    color-mod(#3353e2 alpha(35%));
  box-shadow: var(--zd-tag--royal-focused-box-shadow);
}

.c-tag.c-tag--teal.is-focused,.c-tag.c-tag--teal:focus {
  box-shadow: 0 0 0 2px
    color-mod(#028079 alpha(35%));
  box-shadow: var(--zd-tag--teal-focused-box-shadow);
}

.c-tag.c-tag--yellow.is-focused,.c-tag.c-tag--yellow:focus {
  box-shadow: 0 0 0 2px
    color-mod(#ffb648 alpha(35%));
  box-shadow: var(--zd-tag--yellow-focused-box-shadow);
}

.c-tag.c-tag--yellow:hover,
.c-tag.c-tag--yellow.is-focused,
.c-tag.c-tag--yellow:focus {
  color: #703b15;
  color: var(--zd-tag--yellow-color);
}

:root {
  --zd-tag--lg-border-radius: 4px;
  --zd-tag--lg-height: 32px;
  --zd-tag--lg-line-height: calc(32 / 12);
  --zd-tag--lg-padding-horizontal: 12px;
  --zd-tag--lg-padding: 0 var(--zd-tag--lg-padding-horizontal);
  --zd-tag--sm-font-size: 10px;
  --zd-tag--sm-height: 16px;
  --zd-tag--sm-line-height: calc(16 / 10);
  --zd-tag--sm-padding-horizontal: 4px;
  --zd-tag--sm-padding: 0 var(--zd-tag--sm-padding-horizontal);
  --zd-tag--pill--lg-min-width: 48px;
  --zd-tag--pill--sm-min-width: 24px;
  --zd-tag--pill--sm-padding-horizontal: 6px;
  --zd-tag--pill--sm-padding: 0 var(--zd-tag--pill--sm-padding-horizontal);
  --zd-tag--pill--lg__-min-width: calc(var(--zd-tag--pill--lg-min-width) - calc(var(--zd-tag--lg-padding-horizontal) * 2));
  --zd-tag--pill--sm__-min-width: calc(var(--zd-tag--pill--sm-min-width) - calc(var(--zd-tag--pill--sm-padding-horizontal) * 2));
  --zd-tag--round--lg-min-width: var(--zd-tag--lg-height);
  --zd-tag--round--sm-min-width: var(--zd-tag--sm-height);
}

.c-tag--sm {
  padding: 0 4px;
  padding: var(--zd-tag--sm-padding);
  height: 16px;
  height: var(--zd-tag--sm-height);
  line-height: calc(16 / 10);
  line-height: var(--zd-tag--sm-line-height);
  font-size: 10px;
  font-size: var(--zd-tag--sm-font-size);
}

.c-tag--lg {
  border-radius: 4px;
  border-radius: var(--zd-tag--lg-border-radius);
  padding: 0 12px;
  padding: var(--zd-tag--lg-padding);
  height: 32px;
  height: var(--zd-tag--lg-height);
  line-height: calc(32 / 12);
  line-height: var(--zd-tag--lg-line-height);
}

.c-tag--pill.c-tag--sm {
  padding: 0 6px;
  padding: var(--zd-tag--pill--sm-padding);
  min-width: 24px;
  min-width: var(--zd-tag--pill--sm-min-width);
}

.c-tag--pill.c-tag--sm > * {
  min-width: calc(24px - (6px * 2));
  min-width: calc(24px - calc(6px * 2));
  min-width: var(--zd-tag--pill--sm__-min-width);
}

.c-tag--pill.c-tag--lg {
  min-width: 48px;
  min-width: var(--zd-tag--pill--lg-min-width);
}

.c-tag--pill.c-tag--lg > * {
  min-width: calc(48px - (12px * 2));
  min-width: calc(48px - calc(12px * 2));
  min-width: var(--zd-tag--pill--lg__-min-width);
}

.c-tag--round.c-tag--sm,
.c-tag--round.c-tag--sm > * {
  min-width: 16px;
  min-width: var(--zd-tag--round--sm-min-width);
}

.c-tag--round.c-tag--lg,
.c-tag--round.c-tag--lg > * {
  min-width: 32px;
  min-width: var(--zd-tag--round--lg-min-width);
}

:root {
  --zd-tag--pill-min-width: 30px;
  --zd-tag--pill__-min-width: calc(var(--zd-tag--pill-min-width) - calc(var(--zd-tag-padding-horizontal) * 2));
  --zd-tag--round-min-width: var(--zd-spacing);
}

.c-tag--pill {
  justify-content: center;
  border-radius: 100px;
  min-width: 30px;
  min-width: var(--zd-tag--pill-min-width);
}

.c-tag--round {
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  min-width: 20px;
  min-width: var(--zd-tag--round-min-width);
}

.c-tag--pill > * {
  min-width: calc(30px - (8px * 2));
  min-width: calc(30px - calc(8px * 2));
  min-width: var(--zd-tag--pill__-min-width);
  text-align: center;
}

.c-tag--round > * {
  min-width: 20px;
  min-width: var(--zd-tag--round-min-width);
  text-align: center;
}

/* stylelint-disable no-unsupported-browser-features */

:root {
  --zd-tag__remove-background-image: svg-load('12/x-stroke.svg', color: var(--zd-color-grey-600));
  --zd-tag__remove-background:
    no-repeat
    center
    var(--zd-tag__remove-background-image)
    transparent; /* [1] */
  --zd-tag__remove-margin: calc(var(--zd-tag-padding-horizontal) * -1);
  --zd-tag__remove-opacity: .8;
  --zd-tag__remove-size: var(--zd-spacing);
  --zd-tag__remove-transition: opacity .25s ease-in-out;
  --zd-tag__remove-hovered-opacity: .9;
  --zd-tag--lg__remove-size: var(--zd-tag--lg-height);
  --zd-tag--lg__remove-margin: calc(var(--zd-tag--lg-padding-horizontal) * -1);
  --zd-tag--color__remove-background-image: svg-load('12/x-stroke.svg', color: var(--zd-tag--color-color));
  --zd-tag--yellow__remove-background-image: svg-load('12/x-stroke.svg', color: var(--zd-tag--yellow-color));
  --zd-tag--sm__remove-margin: calc(var(--zd-tag--sm-padding-horizontal) * -1);
  --zd-tag--sm__remove-size: var(--zd-tag--sm-height);
}

/* 1. Reset for <button> element.
 * 2. Reset for text content. */

.c-tag__remove {
  flex-shrink: 0;
  transition: opacity .25s ease-in-out;
  transition: var(--zd-tag__remove-transition);
  opacity: .8;
  opacity: var(--zd-tag__remove-opacity);
  margin-right: calc(8px * -1);
  margin-right: var(--zd-tag__remove-margin);
  border: 0; /* [1] */
  border-radius: 2px;
  border-radius: var(--zd-tag-border-radius);
  background: no-repeat
    center
    svg-load('12/x-stroke.svg', color: #68737d)
    transparent;
  background: var(--zd-tag__remove-background);
  cursor: pointer;
  padding: 0; /* [1] */
  width: 20px;
  width: var(--zd-tag__remove-size);
  height: 20px;
  height: var(--zd-tag__remove-size);
  font-size: 0; /* [2] */
}

.c-tag__remove.is-hovered,.c-tag__remove:hover {
  opacity: .9;
  opacity: var(--zd-tag__remove-hovered-opacity);
}

.c-tag__remove:focus {
  outline: none;
}

.c-tag--lg .c-tag__remove {
  margin-right: calc(12px * -1);
  margin-right: var(--zd-tag--lg__remove-margin);
  width: 32px;
  width: var(--zd-tag--lg__remove-size);
  height: 32px;
  height: var(--zd-tag--lg__remove-size);
}

.c-tag--sm .c-tag__remove {
  margin-right: calc(4px * -1);
  margin-right: var(--zd-tag--sm__remove-margin);
  width: 16px;
  width: var(--zd-tag--sm__remove-size);
  height: 16px;
  height: var(--zd-tag--sm__remove-size);
}

.c-tag--pill .c-tag__remove {
  border-radius: 50%;
}

.c-tag--round .c-tag__remove {
  display: none;
}

.c-tag:matches(
.c-tag--azure, .c-tag--blue, .c-tag--crimson, .c-tag--fuschia,
.c-tag--green, .c-tag--grey, .c-tag--kale, .c-tag--lime, .c-tag--mint,
.c-tag--orange, .c-tag--pink, .c-tag--purple, .c-tag--red, .c-tag--royal,
.c-tag--teal) .c-tag__remove {
  background-image: svg-load('12/x-stroke.svg', color: #fff);
  background-image: var(--zd-tag--color__remove-background-image);
}

.c-tag:matches(.c-tag--lemon, .c-tag--yellow) .c-tag__remove {
  background-image: svg-load('12/x-stroke.svg', color: #703b15);
  background-image: var(--zd-tag--yellow__remove-background-image);
}

.c-tag.is-rtl .c-tag__remove {
  flex-direction: row-reverse;
  margin-right: 0;
  margin-left: calc(8px * -1);
  margin-left: var(--zd-tag__remove-margin);
}

.c-tag--lg.is-rtl .c-tag__remove {
  margin-left: calc(12px * -1);
  margin-left: var(--zd-tag--lg__remove-margin);
}

.c-tag--sm.is-rtl .c-tag__remove {
  margin-left: calc(4px * -1);
  margin-left: var(--zd-tag--sm__remove-margin);
}

/* stylelint-enable no-unsupported-browser-features */

:root {
  --zd-tag__avatar-border-radius: var(--zd-tag-border-radius);
  --zd-tag__avatar-margin-left: calc(var(--zd-tag__avatar-margin-vertical) - var(--zd-tag-padding-horizontal));
  --zd-tag__avatar-margin-right: 5px;
  --zd-tag__avatar-margin-vertical: 2px;
  --zd-tag__avatar-margin:
    var(--zd-tag__avatar-margin-vertical)
    var(--zd-tag__avatar-margin-right)
    var(--zd-tag__avatar-margin-vertical)
    var(--zd-tag__avatar-margin-left);
  --zd-tag__avatar-size: calc(var(--zd-tag-height) - (var(--zd-tag__avatar-margin-vertical) * 2));
  --zd-tag--lg__avatar-border-radius: calc(var(--zd-tag--lg-border-radius) - 1px);
  --zd-tag--lg__avatar-margin-left: calc(var(--zd-tag--lg__avatar-margin-vertical) - var(--zd-tag--lg-padding-horizontal));
  --zd-tag--lg__avatar-margin-right: 10px;
  --zd-tag--lg__avatar-margin-vertical: 3px;
  --zd-tag--lg__avatar-margin:
    var(--zd-tag--lg__avatar-margin-vertical)
    var(--zd-tag--lg__avatar-margin-right)
    var(--zd-tag--lg__avatar-margin-vertical)
    var(--zd-tag--lg__avatar-margin-left);
  --zd-tag--lg__avatar-size: calc(var(--zd-tag--lg-height) - (var(--zd-tag--lg__avatar-margin-vertical) * 2));
}

/* 1. Prevent flex shrink */

.c-tag__avatar {
  flex-shrink: 0;
  margin: 2px
    5px
    2px
    calc(2px - 8px);
  margin: var(--zd-tag__avatar-margin);
  border-radius: 2px;
  border-radius: var(--zd-tag__avatar-border-radius);
  width: calc(20px - (2px * 2));
  width: var(--zd-tag__avatar-size);
  min-width: calc(20px - (2px * 2));
  min-width: var(--zd-tag__avatar-size); /* [1] */
  height: calc(20px - (2px * 2));
  height: var(--zd-tag__avatar-size);
  font-size: 0;
}

.c-tag--lg .c-tag__avatar {
  margin: 3px
    10px
    3px
    calc(3px - 12px);
  margin: var(--zd-tag--lg__avatar-margin);
  border-radius: calc(4px - 1px);
  border-radius: var(--zd-tag--lg__avatar-border-radius);
  width: calc(32px - (3px * 2));
  width: var(--zd-tag--lg__avatar-size);
  min-width: calc(32px - (3px * 2));
  min-width: var(--zd-tag--lg__avatar-size); /* [1] */
  height: calc(32px - (3px * 2));
  height: var(--zd-tag--lg__avatar-size);
}

.c-tag--pill .c-tag__avatar {
  border-radius: 50%;
}

.c-tag--round .c-tag__avatar,
.c-tag--sm .c-tag__avatar {
  display: none;
}

.c-tag.is-rtl .c-tag__avatar {
  margin-right: calc(2px - 8px);
  margin-right: var(--zd-tag__avatar-margin-left);
  margin-left: 5px;
  margin-left: var(--zd-tag__avatar-margin-right);
}

.c-tag--lg.is-rtl .c-tag__avatar {
  margin-right: calc(3px - 12px);
  margin-right: var(--zd-tag--lg__avatar-margin-left);
  margin-left: 10px;
  margin-left: var(--zd-tag--lg__avatar-margin-right);
}

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

:root {
  --zd-border-radius: 4px;
  --zd-border-radius-lg: calc(var(--zd-border-radius) * 1.5);
  --zd-border-radius-sm: calc(var(--zd-border-radius) / 2);
  --zd-border-radius-xl: calc(var(--zd-border-radius) * 2);
}

/* stylelint-disable declaration-no-important */

.u-br {
  border-radius: 4px !important;
  border-radius: var(--zd-border-radius) !important;
}

.u-br-b {
  border-bottom-left-radius: 4px !important;
  border-bottom-left-radius: var(--zd-border-radius) !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-right-radius: var(--zd-border-radius) !important;
}

.u-br-bl {
  border-bottom-left-radius: 4px !important;
  border-bottom-left-radius: var(--zd-border-radius) !important;
}

.u-br-br {
  border-bottom-right-radius: 4px !important;
  border-bottom-right-radius: var(--zd-border-radius) !important;
}

.u-br-l {
  border-top-left-radius: 4px !important;
  border-top-left-radius: var(--zd-border-radius) !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-left-radius: var(--zd-border-radius) !important;
}

.u-br-r {
  border-top-right-radius: 4px !important;
  border-top-right-radius: var(--zd-border-radius) !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-right-radius: var(--zd-border-radius) !important;
}

.u-br-t {
  border-top-left-radius: 4px !important;
  border-top-left-radius: var(--zd-border-radius) !important;
  border-top-right-radius: 4px !important;
  border-top-right-radius: var(--zd-border-radius) !important;
}

.u-br-tl {
  border-top-left-radius: 4px !important;
  border-top-left-radius: var(--zd-border-radius) !important;
}

.u-br-tr {
  border-top-right-radius: 4px !important;
  border-top-right-radius: var(--zd-border-radius) !important;
}

/* Zero Radius */

.u-br-0 {
  border-radius: 0 !important;
}

.u-br-b-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.u-br-bl-0 {
  border-bottom-left-radius: 0 !important;
}

.u-br-br-0 {
  border-bottom-right-radius: 0 !important;
}

.u-br-l-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.u-br-r-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.u-br-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.u-br-tl-0 {
  border-top-left-radius: 0 !important;
}

.u-br-tr-0 {
  border-top-right-radius: 0 !important;
}

/* Round Radius */

.u-br-50\% {
  border-radius: 50% !important;
}

/* Large Radius */

.u-br-lg {
  border-radius: calc(4px * 1.5) !important;
  border-radius: var(--zd-border-radius-lg) !important;
}

.u-br-b-lg {
  border-bottom-left-radius: calc(4px * 1.5) !important;
  border-bottom-left-radius: var(--zd-border-radius-lg) !important;
  border-bottom-right-radius: calc(4px * 1.5) !important;
  border-bottom-right-radius: var(--zd-border-radius-lg) !important;
}

.u-br-bl-lg {
  border-bottom-left-radius: calc(4px * 1.5) !important;
  border-bottom-left-radius: var(--zd-border-radius-lg) !important;
}

.u-br-br-lg {
  border-bottom-right-radius: calc(4px * 1.5) !important;
  border-bottom-right-radius: var(--zd-border-radius-lg) !important;
}

.u-br-l-lg {
  border-top-left-radius: calc(4px * 1.5) !important;
  border-top-left-radius: var(--zd-border-radius-lg) !important;
  border-bottom-left-radius: calc(4px * 1.5) !important;
  border-bottom-left-radius: var(--zd-border-radius-lg) !important;
}

.u-br-r-lg {
  border-top-right-radius: calc(4px * 1.5) !important;
  border-top-right-radius: var(--zd-border-radius-lg) !important;
  border-bottom-right-radius: calc(4px * 1.5) !important;
  border-bottom-right-radius: var(--zd-border-radius-lg) !important;
}

.u-br-t-lg {
  border-top-left-radius: calc(4px * 1.5) !important;
  border-top-left-radius: var(--zd-border-radius-lg) !important;
  border-top-right-radius: calc(4px * 1.5) !important;
  border-top-right-radius: var(--zd-border-radius-lg) !important;
}

.u-br-tl-lg {
  border-top-left-radius: calc(4px * 1.5) !important;
  border-top-left-radius: var(--zd-border-radius-lg) !important;
}

.u-br-tr-lg {
  border-top-right-radius: calc(4px * 1.5) !important;
  border-top-right-radius: var(--zd-border-radius-lg) !important;
}

/* Small Radius */

.u-br-sm {
  border-radius: calc(4px / 2) !important;
  border-radius: var(--zd-border-radius-sm) !important;
}

.u-br-b-sm {
  border-bottom-left-radius: calc(4px / 2) !important;
  border-bottom-left-radius: var(--zd-border-radius-sm) !important;
  border-bottom-right-radius: calc(4px / 2) !important;
  border-bottom-right-radius: var(--zd-border-radius-sm) !important;
}

.u-br-bl-sm {
  border-bottom-left-radius: calc(4px / 2) !important;
  border-bottom-left-radius: var(--zd-border-radius-sm) !important;
}

.u-br-br-sm {
  border-bottom-right-radius: calc(4px / 2) !important;
  border-bottom-right-radius: var(--zd-border-radius-sm) !important;
}

.u-br-l-sm {
  border-top-left-radius: calc(4px / 2) !important;
  border-top-left-radius: var(--zd-border-radius-sm) !important;
  border-bottom-left-radius: calc(4px / 2) !important;
  border-bottom-left-radius: var(--zd-border-radius-sm) !important;
}

.u-br-r-sm {
  border-top-right-radius: calc(4px / 2) !important;
  border-top-right-radius: var(--zd-border-radius-sm) !important;
  border-bottom-right-radius: calc(4px / 2) !important;
  border-bottom-right-radius: var(--zd-border-radius-sm) !important;
}

.u-br-t-sm {
  border-top-left-radius: calc(4px / 2) !important;
  border-top-left-radius: var(--zd-border-radius-sm) !important;
  border-top-right-radius: calc(4px / 2) !important;
  border-top-right-radius: var(--zd-border-radius-sm) !important;
}

.u-br-tl-sm {
  border-top-left-radius: calc(4px / 2) !important;
  border-top-left-radius: var(--zd-border-radius-sm) !important;
}

.u-br-tr-sm {
  border-top-right-radius: calc(4px / 2) !important;
  border-top-right-radius: var(--zd-border-radius-sm) !important;
}

/* Extra-Large Radius */

.u-br-xl {
  border-radius: calc(4px * 2) !important;
  border-radius: var(--zd-border-radius-xl) !important;
}

.u-br-b-xl {
  border-bottom-left-radius: calc(4px * 2) !important;
  border-bottom-left-radius: var(--zd-border-radius-xl) !important;
  border-bottom-right-radius: calc(4px * 2) !important;
  border-bottom-right-radius: var(--zd-border-radius-xl) !important;
}

.u-br-bl-xl {
  border-bottom-left-radius: calc(4px * 2) !important;
  border-bottom-left-radius: var(--zd-border-radius-xl) !important;
}

.u-br-br-xl {
  border-bottom-right-radius: calc(4px * 2) !important;
  border-bottom-right-radius: var(--zd-border-radius-xl) !important;
}

.u-br-l-xl {
  border-top-left-radius: calc(4px * 2) !important;
  border-top-left-radius: var(--zd-border-radius-xl) !important;
  border-bottom-left-radius: calc(4px * 2) !important;
  border-bottom-left-radius: var(--zd-border-radius-xl) !important;
}

.u-br-r-xl {
  border-top-right-radius: calc(4px * 2) !important;
  border-top-right-radius: var(--zd-border-radius-xl) !important;
  border-bottom-right-radius: calc(4px * 2) !important;
  border-bottom-right-radius: var(--zd-border-radius-xl) !important;
}

.u-br-t-xl {
  border-top-left-radius: calc(4px * 2) !important;
  border-top-left-radius: var(--zd-border-radius-xl) !important;
  border-top-right-radius: calc(4px * 2) !important;
  border-top-right-radius: var(--zd-border-radius-xl) !important;
}

.u-br-tl-xl {
  border-top-left-radius: calc(4px * 2) !important;
  border-top-left-radius: var(--zd-border-radius-xl) !important;
}

.u-br-tr-xl {
  border-top-right-radius: calc(4px * 2) !important;
  border-top-right-radius: var(--zd-border-radius-xl) !important;
}

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-border { border: 1px solid !important; }

.u-border-b { border-bottom: 1px solid !important; }

.u-border-h {
  border-right: 1px solid !important;
  border-left: 1px solid !important;
}

.u-border-l { border-left: 1px solid !important; }

.u-border-r { border-right: 1px solid !important; }

.u-border-t { border-top: 1px solid !important; }

.u-border-v {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-bs-none { box-shadow: none !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* css-101.org/articles/clearfix/latest-new-clearfix-so-far.php */

.u-cf::after {
  display: table;
  clear: both;
  content: '';
}

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

/* Background Colors */

.u-bg-green-100 { background-color: #edf8f4 !important; background-color: var(--zd-color-green-100) !important; }

.u-bg-green-200 { background-color: #d1e8df !important; background-color: var(--zd-color-green-200) !important; }

.u-bg-green-300 { background-color: #aecfc2 !important; background-color: var(--zd-color-green-300) !important; }

.u-bg-green-400 { background-color: #5eae91 !important; background-color: var(--zd-color-green-400) !important; }

.u-bg-green-500 { background-color: #228f67 !important; background-color: var(--zd-color-green-500) !important; }

.u-bg-green-600 { background-color: #038153 !important; background-color: var(--zd-color-green-600) !important; }

.u-bg-green-700 { background-color: #186146 !important; background-color: var(--zd-color-green-700) !important; }

.u-bg-green-800 { background-color: #0b3b29 !important; background-color: var(--zd-color-green-800) !important; }

.u-bg-grey-100 { background-color: #f8f9f9 !important; background-color: var(--zd-color-grey-100) !important; }

.u-bg-grey-200 { background-color: #e9ebed !important; background-color: var(--zd-color-grey-200) !important; }

.u-bg-grey-300 { background-color: #d8dcde !important; background-color: var(--zd-color-grey-300) !important; }

.u-bg-grey-400 { background-color: #c2c8cc !important; background-color: var(--zd-color-grey-400) !important; }

.u-bg-grey-500 { background-color: #87929d !important; background-color: var(--zd-color-grey-500) !important; }

.u-bg-grey-600 { background-color: #68737d !important; background-color: var(--zd-color-grey-600) !important; }

.u-bg-grey-700 { background-color: #49545c !important; background-color: var(--zd-color-grey-700) !important; }

.u-bg-grey-800 { background-color: #2f3941 !important; background-color: var(--zd-color-grey-800) !important; }

.u-bg-blue-100 { background-color: #edf7ff !important; background-color: var(--zd-color-blue-100) !important; }

.u-bg-blue-200 { background-color: #cee2f2 !important; background-color: var(--zd-color-blue-200) !important; }

.u-bg-blue-300 { background-color: #adcce4 !important; background-color: var(--zd-color-blue-300) !important; }

.u-bg-blue-400 { background-color: #5293c7 !important; background-color: var(--zd-color-blue-400) !important; }

.u-bg-blue-500 { background-color: #337fbd !important; background-color: var(--zd-color-blue-500) !important; }

.u-bg-blue-600 { background-color: #1f73b7 !important; background-color: var(--zd-color-blue-600) !important; }

.u-bg-blue-700 { background-color: #144a75 !important; background-color: var(--zd-color-blue-700) !important; }

.u-bg-blue-800 { background-color: #0f3554 !important; background-color: var(--zd-color-blue-800) !important; }

.u-bg-kale-100 { background-color: #f5fbfc !important; background-color: var(--zd-color-kale-100) !important; }

.u-bg-kale-200 { background-color: #c1d6d9 !important; background-color: var(--zd-color-kale-200) !important; }

.u-bg-kale-300 { background-color: #819a9e !important; background-color: var(--zd-color-kale-300) !important; }

.u-bg-kale-400 { background-color: #56777a !important; background-color: var(--zd-color-kale-400) !important; }

.u-bg-kale-500 { background-color: #335d63 !important; background-color: var(--zd-color-kale-500) !important; }

.u-bg-kale-600 { background-color: #04444d !important; background-color: var(--zd-color-kale-600) !important; }

.u-bg-kale-700 { background-color: #03363d !important; background-color: var(--zd-color-kale-700) !important; }

.u-bg-kale-800 { background-color: #012b30 !important; background-color: var(--zd-color-kale-800) !important; }

.u-bg-red-100 { background-color: #fff0f1 !important; background-color: var(--zd-color-red-100) !important; }

.u-bg-red-200 { background-color: #f5d5d8 !important; background-color: var(--zd-color-red-200) !important; }

.u-bg-red-300 { background-color: #f5b5ba !important; background-color: var(--zd-color-red-300) !important; }

.u-bg-red-400 { background-color: #e35b66 !important; background-color: var(--zd-color-red-400) !important; }

.u-bg-red-500 { background-color: #d93f4c !important; background-color: var(--zd-color-red-500) !important; }

.u-bg-red-600 { background-color: #cc3340 !important; background-color: var(--zd-color-red-600) !important; }

.u-bg-red-700 { background-color: #8c232c !important; background-color: var(--zd-color-red-700) !important; }

.u-bg-red-800 { background-color: #681219 !important; background-color: var(--zd-color-red-800) !important; }

.u-bg-yellow-100 { background-color: #fff8ed !important; background-color: var(--zd-color-yellow-100) !important; }

.u-bg-yellow-200 { background-color: #fff0db !important; background-color: var(--zd-color-yellow-200) !important; }

.u-bg-yellow-300 { background-color: #fcdba9 !important; background-color: var(--zd-color-yellow-300) !important; }

.u-bg-yellow-400 { background-color: #ffb648 !important; background-color: var(--zd-color-yellow-400) !important; }

.u-bg-yellow-500 { background-color: #f5a133 !important; background-color: var(--zd-color-yellow-500) !important; }

.u-bg-yellow-600 { background-color: #ed961c !important; background-color: var(--zd-color-yellow-600) !important; }

.u-bg-yellow-700 { background-color: #ad5e18 !important; background-color: var(--zd-color-yellow-700) !important; }

.u-bg-yellow-800 { background-color: #703b15 !important; background-color: var(--zd-color-yellow-800) !important; }

/* Background Colors (secondary) */

.u-bg-azure-400 { background-color: #3091ec !important; background-color: var(--zd-color-secondary-azure-400) !important; }

.u-bg-azure-600 { background-color: #1371d6 !important; background-color: var(--zd-color-secondary-azure-600) !important; }

.u-bg-crimson-400 { background-color: #e34f32 !important; background-color: var(--zd-color-secondary-crimson-400) !important; }

.u-bg-crimson-600 { background-color: #c72a1c !important; background-color: var(--zd-color-secondary-crimson-600) !important; }

.u-bg-fuschia-400 { background-color: #d653c2 !important; background-color: var(--zd-color-secondary-fuschia-400) !important; }

.u-bg-fuschia-600 { background-color: #a81897 !important; background-color: var(--zd-color-secondary-fuschia-600) !important; }

.u-bg-lemon-400 { background-color: #ffd424 !important; background-color: var(--zd-color-secondary-lemon-400) !important; }

.u-bg-lemon-600 { background-color: #ffbb10 !important; background-color: var(--zd-color-secondary-lemon-600) !important; }

.u-bg-lime-400 { background-color: #43b324 !important; background-color: var(--zd-color-secondary-lime-400) !important; }

.u-bg-lime-600 { background-color: #2e8200 !important; background-color: var(--zd-color-secondary-lime-600) !important; }

.u-bg-mint-400 { background-color: #00a656 !important; background-color: var(--zd-color-secondary-mint-400) !important; }

.u-bg-mint-600 { background-color: #058541 !important; background-color: var(--zd-color-secondary-mint-600) !important; }

.u-bg-orange-400 { background-color: #de701d !important; background-color: var(--zd-color-secondary-orange-400) !important; }

.u-bg-orange-600 { background-color: #bf5000 !important; background-color: var(--zd-color-secondary-orange-600) !important; }

.u-bg-pink-400 { background-color: #ec4d63 !important; background-color: var(--zd-color-secondary-pink-400) !important; }

.u-bg-pink-600 { background-color: #d42054 !important; background-color: var(--zd-color-secondary-pink-600) !important; }

.u-bg-purple-400 { background-color: #b552e2 !important; background-color: var(--zd-color-secondary-purple-400) !important; }

.u-bg-purple-600 { background-color: #6a27b8 !important; background-color: var(--zd-color-secondary-purple-600) !important; }

.u-bg-royal-400 { background-color: #5d7df5 !important; background-color: var(--zd-color-secondary-royal-400) !important; }

.u-bg-royal-600 { background-color: #3353e2 !important; background-color: var(--zd-color-secondary-royal-600) !important; }

.u-bg-teal-400 { background-color: #02a191 !important; background-color: var(--zd-color-secondary-teal-400) !important; }

.u-bg-teal-600 { background-color: #028079 !important; background-color: var(--zd-color-secondary-teal-600) !important; }

/* Background Colors (muted secondary) */

.u-bg-azure-M400 { background-color: #5f8dcf !important; background-color: var(--zd-color-secondary-azure-M400) !important; }

.u-bg-azure-M600 { background-color: #3a70b2 !important; background-color: var(--zd-color-secondary-azure-M600) !important; }

.u-bg-crimson-M400 { background-color: #cc6c5b !important; background-color: var(--zd-color-secondary-crimson-M400) !important; }

.u-bg-crimson-M600 { background-color: #b24a3c !important; background-color: var(--zd-color-secondary-crimson-M600) !important; }

.u-bg-fuschia-M400 { background-color: #cf62a8 !important; background-color: var(--zd-color-secondary-fuschia-M400) !important; }

.u-bg-fuschia-M600 { background-color: #a8458c !important; background-color: var(--zd-color-secondary-fuschia-M600) !important; }

.u-bg-lemon-M400 { background-color: #e7a500 !important; background-color: var(--zd-color-secondary-lemon-M400) !important; }

.u-bg-lemon-M600 { background-color: #c38f00 !important; background-color: var(--zd-color-secondary-lemon-M600) !important; }

.u-bg-lime-M400 { background-color: #519e2d !important; background-color: var(--zd-color-secondary-lime-M400) !important; }

.u-bg-lime-M600 { background-color: #47782c !important; background-color: var(--zd-color-secondary-lime-M600) !important; }

.u-bg-mint-M400 { background-color: #299c66 !important; background-color: var(--zd-color-secondary-mint-M400) !important; }

.u-bg-mint-M600 { background-color: #2e8057 !important; background-color: var(--zd-color-secondary-mint-M600) !important; }

.u-bg-orange-M400 { background-color: #d4772c !important; background-color: var(--zd-color-secondary-orange-M400) !important; }

.u-bg-orange-M600 { background-color: #b35827 !important; background-color: var(--zd-color-secondary-orange-M600) !important; }

.u-bg-pink-M400 { background-color: #d57287 !important; background-color: var(--zd-color-secondary-pink-M400) !important; }

.u-bg-pink-M600 { background-color: #b23a5d !important; background-color: var(--zd-color-secondary-pink-M600) !important; }

.u-bg-purple-M400 { background-color: #b072cc !important; background-color: var(--zd-color-secondary-purple-M400) !important; }

.u-bg-purple-M600 { background-color: #9358b0 !important; background-color: var(--zd-color-secondary-purple-M600) !important; }

.u-bg-royal-M400 { background-color: #7986d8 !important; background-color: var(--zd-color-secondary-royal-M400) !important; }

.u-bg-royal-M600 { background-color: #4b61c3 !important; background-color: var(--zd-color-secondary-royal-M600) !important; }

.u-bg-teal-M400 { background-color: #2d9e8f !important; background-color: var(--zd-color-secondary-teal-M400) !important; }

.u-bg-teal-M600 { background-color: #3c7873 !important; background-color: var(--zd-color-secondary-teal-M600) !important; }

/* Background Colors (standard) */

.u-bg-black { background-color: #000 !important; background-color: var(--zd-color-black) !important; }

.u-bg-current-color { background-color: currentColor !important; }

.u-bg-inherit { background-color: inherit !important; }

.u-bg-transparent { background-color: transparent !important; }

.u-bg-white { background-color: #fff !important; background-color: var(--zd-color-white) !important; }

/* Background Colors (product) */

.u-bg-chat-orange { background-color: #f79a3e !important; background-color: var(--zd-color-chat-orange) !important; }

.u-bg-connect-red { background-color: #eb6651 !important; background-color: var(--zd-color-connect-red) !important; }

.u-bg-explore-blue { background-color: #30aabc !important; background-color: var(--zd-color-explore-blue) !important; }

.u-bg-gather-pink { background-color: #e7afa2 !important; background-color: var(--zd-color-gather-pink) !important; }

.u-bg-guide-pink { background-color: #eb4962 !important; background-color: var(--zd-color-guide-pink) !important; }

.u-bg-message-green { background-color: #37b8af !important; background-color: var(--zd-color-message-green) !important; }

.u-bg-sell-gold { background-color: #d4ae5e !important; background-color: var(--zd-color-sell-gold) !important; }

.u-bg-support-green { background-color: #78a300 !important; background-color: var(--zd-color-support-green) !important; }

.u-bg-talk-yellow { background-color: #efc93d !important; background-color: var(--zd-color-talk-yellow) !important; }

/* Foreground Colors */

.u-fg-green-100 { color: #edf8f4 !important; color: var(--zd-color-green-100) !important; }

.u-fg-green-200 { color: #d1e8df !important; color: var(--zd-color-green-200) !important; }

.u-fg-green-300 { color: #aecfc2 !important; color: var(--zd-color-green-300) !important; }

.u-fg-green-400 { color: #5eae91 !important; color: var(--zd-color-green-400) !important; }

.u-fg-green-500 { color: #228f67 !important; color: var(--zd-color-green-500) !important; }

.u-fg-green-600 { color: #038153 !important; color: var(--zd-color-green-600) !important; }

.u-fg-green-700 { color: #186146 !important; color: var(--zd-color-green-700) !important; }

.u-fg-green-800 { color: #0b3b29 !important; color: var(--zd-color-green-800) !important; }

.u-fg-grey-100 { color: #f8f9f9 !important; color: var(--zd-color-grey-100) !important; }

.u-fg-grey-200 { color: #e9ebed !important; color: var(--zd-color-grey-200) !important; }

.u-fg-grey-300 { color: #d8dcde !important; color: var(--zd-color-grey-300) !important; }

.u-fg-grey-400 { color: #c2c8cc !important; color: var(--zd-color-grey-400) !important; }

.u-fg-grey-500 { color: #87929d !important; color: var(--zd-color-grey-500) !important; }

.u-fg-grey-600 { color: #68737d !important; color: var(--zd-color-grey-600) !important; }

.u-fg-grey-700 { color: #49545c !important; color: var(--zd-color-grey-700) !important; }

.u-fg-grey-800 { color: #2f3941 !important; color: var(--zd-color-grey-800) !important; }

.u-fg-blue-100 { color: #edf7ff !important; color: var(--zd-color-blue-100) !important; }

.u-fg-blue-200 { color: #cee2f2 !important; color: var(--zd-color-blue-200) !important; }

.u-fg-blue-300 { color: #adcce4 !important; color: var(--zd-color-blue-300) !important; }

.u-fg-blue-400 { color: #5293c7 !important; color: var(--zd-color-blue-400) !important; }

.u-fg-blue-500 { color: #337fbd !important; color: var(--zd-color-blue-500) !important; }

.u-fg-blue-600 { color: #1f73b7 !important; color: var(--zd-color-blue-600) !important; }

.u-fg-blue-700 { color: #144a75 !important; color: var(--zd-color-blue-700) !important; }

.u-fg-blue-800 { color: #0f3554 !important; color: var(--zd-color-blue-800) !important; }

.u-fg-kale-100 { color: #f5fbfc !important; color: var(--zd-color-kale-100) !important; }

.u-fg-kale-200 { color: #c1d6d9 !important; color: var(--zd-color-kale-200) !important; }

.u-fg-kale-300 { color: #819a9e !important; color: var(--zd-color-kale-300) !important; }

.u-fg-kale-400 { color: #56777a !important; color: var(--zd-color-kale-400) !important; }

.u-fg-kale-500 { color: #335d63 !important; color: var(--zd-color-kale-500) !important; }

.u-fg-kale-600 { color: #04444d !important; color: var(--zd-color-kale-600) !important; }

.u-fg-kale-700 { color: #03363d !important; color: var(--zd-color-kale-700) !important; }

.u-fg-kale-800 { color: #012b30 !important; color: var(--zd-color-kale-800) !important; }

.u-fg-red-100 { color: #fff0f1 !important; color: var(--zd-color-red-100) !important; }

.u-fg-red-200 { color: #f5d5d8 !important; color: var(--zd-color-red-200) !important; }

.u-fg-red-300 { color: #f5b5ba !important; color: var(--zd-color-red-300) !important; }

.u-fg-red-400 { color: #e35b66 !important; color: var(--zd-color-red-400) !important; }

.u-fg-red-500 { color: #d93f4c !important; color: var(--zd-color-red-500) !important; }

.u-fg-red-600 { color: #cc3340 !important; color: var(--zd-color-red-600) !important; }

.u-fg-red-700 { color: #8c232c !important; color: var(--zd-color-red-700) !important; }

.u-fg-red-800 { color: #681219 !important; color: var(--zd-color-red-800) !important; }

.u-fg-yellow-100 { color: #fff8ed !important; color: var(--zd-color-yellow-100) !important; }

.u-fg-yellow-200 { color: #fff0db !important; color: var(--zd-color-yellow-200) !important; }

.u-fg-yellow-300 { color: #fcdba9 !important; color: var(--zd-color-yellow-300) !important; }

.u-fg-yellow-400 { color: #ffb648 !important; color: var(--zd-color-yellow-400) !important; }

.u-fg-yellow-500 { color: #f5a133 !important; color: var(--zd-color-yellow-500) !important; }

.u-fg-yellow-600 { color: #ed961c !important; color: var(--zd-color-yellow-600) !important; }

.u-fg-yellow-700 { color: #ad5e18 !important; color: var(--zd-color-yellow-700) !important; }

.u-fg-yellow-800 { color: #703b15 !important; color: var(--zd-color-yellow-800) !important; }

/* Foreground Colors (secondary) */

.u-fg-azure-400 { color: #3091ec !important; color: var(--zd-color-secondary-azure-400) !important; }

.u-fg-azure-600 { color: #1371d6 !important; color: var(--zd-color-secondary-azure-600) !important; }

.u-fg-crimson-400 { color: #e34f32 !important; color: var(--zd-color-secondary-crimson-400) !important; }

.u-fg-crimson-600 { color: #c72a1c !important; color: var(--zd-color-secondary-crimson-600) !important; }

.u-fg-fuschia-400 { color: #d653c2 !important; color: var(--zd-color-secondary-fuschia-400) !important; }

.u-fg-fuschia-600 { color: #a81897 !important; color: var(--zd-color-secondary-fuschia-600) !important; }

.u-fg-lemon-400 { color: #ffd424 !important; color: var(--zd-color-secondary-lemon-400) !important; }

.u-fg-lemon-600 { color: #ffbb10 !important; color: var(--zd-color-secondary-lemon-600) !important; }

.u-fg-lime-400 { color: #43b324 !important; color: var(--zd-color-secondary-lime-400) !important; }

.u-fg-lime-600 { color: #2e8200 !important; color: var(--zd-color-secondary-lime-600) !important; }

.u-fg-mint-400 { color: #00a656 !important; color: var(--zd-color-secondary-mint-400) !important; }

.u-fg-mint-600 { color: #058541 !important; color: var(--zd-color-secondary-mint-600) !important; }

.u-fg-orange-400 { color: #de701d !important; color: var(--zd-color-secondary-orange-400) !important; }

.u-fg-orange-600 { color: #bf5000 !important; color: var(--zd-color-secondary-orange-600) !important; }

.u-fg-pink-400 { color: #ec4d63 !important; color: var(--zd-color-secondary-pink-400) !important; }

.u-fg-pink-600 { color: #d42054 !important; color: var(--zd-color-secondary-pink-600) !important; }

.u-fg-purple-400 { color: #b552e2 !important; color: var(--zd-color-secondary-purple-400) !important; }

.u-fg-purple-600 { color: #6a27b8 !important; color: var(--zd-color-secondary-purple-600) !important; }

.u-fg-royal-400 { color: #5d7df5 !important; color: var(--zd-color-secondary-royal-400) !important; }

.u-fg-royal-600 { color: #3353e2 !important; color: var(--zd-color-secondary-royal-600) !important; }

.u-fg-teal-400 { color: #02a191 !important; color: var(--zd-color-secondary-teal-400) !important; }

.u-fg-teal-600 { color: #028079 !important; color: var(--zd-color-secondary-teal-600) !important; }

/* Foreground Colors (muted secondary) */

.u-fg-azure-M400 { color: #5f8dcf !important; color: var(--zd-color-secondary-azure-M400) !important; }

.u-fg-azure-M600 { color: #3a70b2 !important; color: var(--zd-color-secondary-azure-M600) !important; }

.u-fg-crimson-M400 { color: #cc6c5b !important; color: var(--zd-color-secondary-crimson-M400) !important; }

.u-fg-crimson-M600 { color: #b24a3c !important; color: var(--zd-color-secondary-crimson-M600) !important; }

.u-fg-fuschia-M400 { color: #cf62a8 !important; color: var(--zd-color-secondary-fuschia-M400) !important; }

.u-fg-fuschia-M600 { color: #a8458c !important; color: var(--zd-color-secondary-fuschia-M600) !important; }

.u-fg-lemon-M400 { color: #e7a500 !important; color: var(--zd-color-secondary-lemon-M400) !important; }

.u-fg-lemon-M600 { color: #c38f00 !important; color: var(--zd-color-secondary-lemon-M600) !important; }

.u-fg-lime-M400 { color: #519e2d !important; color: var(--zd-color-secondary-lime-M400) !important; }

.u-fg-lime-M600 { color: #47782c !important; color: var(--zd-color-secondary-lime-M600) !important; }

.u-fg-mint-M400 { color: #299c66 !important; color: var(--zd-color-secondary-mint-M400) !important; }

.u-fg-mint-M600 { color: #2e8057 !important; color: var(--zd-color-secondary-mint-M600) !important; }

.u-fg-orange-M400 { color: #d4772c !important; color: var(--zd-color-secondary-orange-M400) !important; }

.u-fg-orange-M600 { color: #b35827 !important; color: var(--zd-color-secondary-orange-M600) !important; }

.u-fg-pink-M400 { color: #d57287 !important; color: var(--zd-color-secondary-pink-M400) !important; }

.u-fg-pink-M600 { color: #b23a5d !important; color: var(--zd-color-secondary-pink-M600) !important; }

.u-fg-purple-M400 { color: #b072cc !important; color: var(--zd-color-secondary-purple-M400) !important; }

.u-fg-purple-M600 { color: #9358b0 !important; color: var(--zd-color-secondary-purple-M600) !important; }

.u-fg-royal-M400 { color: #7986d8 !important; color: var(--zd-color-secondary-royal-M400) !important; }

.u-fg-royal-M600 { color: #4b61c3 !important; color: var(--zd-color-secondary-royal-M600) !important; }

.u-fg-teal-M400 { color: #2d9e8f !important; color: var(--zd-color-secondary-teal-M400) !important; }

.u-fg-teal-M600 { color: #3c7873 !important; color: var(--zd-color-secondary-teal-M600) !important; }

/* Foreground Colors (standard) */

.u-fg-black { color: #000 !important; color: var(--zd-color-black) !important; }

.u-fg-inherit { color: inherit !important; }

.u-fg-transparent { color: transparent !important; }

.u-fg-white { color: #fff !important; color: var(--zd-color-white) !important; }

/* Foreground Colors (product) */

.u-fg-chat-orange { color: #f79a3e !important; color: var(--zd-color-chat-orange) !important; }

.u-fg-connect-red { color: #eb6651 !important; color: var(--zd-color-connect-red) !important; }

.u-fg-explore-blue { color: #30aabc !important; color: var(--zd-color-explore-blue) !important; }

.u-fg-gather-pink { color: #e7afa2 !important; color: var(--zd-color-gather-pink) !important; }

.u-fg-guide-pink { color: #eb4962 !important; color: var(--zd-color-guide-pink) !important; }

.u-fg-message-green { color: #37b8af !important; color: var(--zd-color-message-green) !important; }

.u-fg-sell-gold { color: #d4ae5e !important; color: var(--zd-color-sell-gold) !important; }

.u-fg-support-green { color: #78a300 !important; color: var(--zd-color-support-green) !important; }

.u-fg-talk-yellow { color: #efc93d !important; color: var(--zd-color-talk-yellow) !important; }

/* Border Colors */

.u-bc-green-100 { border-color: #edf8f4 !important; border-color: var(--zd-color-green-100) !important; }

.u-bc-green-200 { border-color: #d1e8df !important; border-color: var(--zd-color-green-200) !important; }

.u-bc-green-300 { border-color: #aecfc2 !important; border-color: var(--zd-color-green-300) !important; }

.u-bc-green-400 { border-color: #5eae91 !important; border-color: var(--zd-color-green-400) !important; }

.u-bc-green-500 { border-color: #228f67 !important; border-color: var(--zd-color-green-500) !important; }

.u-bc-green-600 { border-color: #038153 !important; border-color: var(--zd-color-green-600) !important; }

.u-bc-green-700 { border-color: #186146 !important; border-color: var(--zd-color-green-700) !important; }

.u-bc-green-800 { border-color: #0b3b29 !important; border-color: var(--zd-color-green-800) !important; }

.u-bc-grey-100 { border-color: #f8f9f9 !important; border-color: var(--zd-color-grey-100) !important; }

.u-bc-grey-200 { border-color: #e9ebed !important; border-color: var(--zd-color-grey-200) !important; }

.u-bc-grey-300 { border-color: #d8dcde !important; border-color: var(--zd-color-grey-300) !important; }

.u-bc-grey-400 { border-color: #c2c8cc !important; border-color: var(--zd-color-grey-400) !important; }

.u-bc-grey-500 { border-color: #87929d !important; border-color: var(--zd-color-grey-500) !important; }

.u-bc-grey-600 { border-color: #68737d !important; border-color: var(--zd-color-grey-600) !important; }

.u-bc-grey-700 { border-color: #49545c !important; border-color: var(--zd-color-grey-700) !important; }

.u-bc-grey-800 { border-color: #2f3941 !important; border-color: var(--zd-color-grey-800) !important; }

.u-bc-blue-100 { border-color: #edf7ff !important; border-color: var(--zd-color-blue-100) !important; }

.u-bc-blue-200 { border-color: #cee2f2 !important; border-color: var(--zd-color-blue-200) !important; }

.u-bc-blue-300 { border-color: #adcce4 !important; border-color: var(--zd-color-blue-300) !important; }

.u-bc-blue-400 { border-color: #5293c7 !important; border-color: var(--zd-color-blue-400) !important; }

.u-bc-blue-500 { border-color: #337fbd !important; border-color: var(--zd-color-blue-500) !important; }

.u-bc-blue-600 { border-color: #1f73b7 !important; border-color: var(--zd-color-blue-600) !important; }

.u-bc-blue-700 { border-color: #144a75 !important; border-color: var(--zd-color-blue-700) !important; }

.u-bc-blue-800 { border-color: #0f3554 !important; border-color: var(--zd-color-blue-800) !important; }

.u-bc-kale-100 { border-color: #f5fbfc !important; border-color: var(--zd-color-kale-100) !important; }

.u-bc-kale-200 { border-color: #c1d6d9 !important; border-color: var(--zd-color-kale-200) !important; }

.u-bc-kale-300 { border-color: #819a9e !important; border-color: var(--zd-color-kale-300) !important; }

.u-bc-kale-400 { border-color: #56777a !important; border-color: var(--zd-color-kale-400) !important; }

.u-bc-kale-500 { border-color: #335d63 !important; border-color: var(--zd-color-kale-500) !important; }

.u-bc-kale-600 { border-color: #04444d !important; border-color: var(--zd-color-kale-600) !important; }

.u-bc-kale-700 { border-color: #03363d !important; border-color: var(--zd-color-kale-700) !important; }

.u-bc-kale-800 { border-color: #012b30 !important; border-color: var(--zd-color-kale-800) !important; }

.u-bc-red-100 { border-color: #fff0f1 !important; border-color: var(--zd-color-red-100) !important; }

.u-bc-red-200 { border-color: #f5d5d8 !important; border-color: var(--zd-color-red-200) !important; }

.u-bc-red-300 { border-color: #f5b5ba !important; border-color: var(--zd-color-red-300) !important; }

.u-bc-red-400 { border-color: #e35b66 !important; border-color: var(--zd-color-red-400) !important; }

.u-bc-red-500 { border-color: #d93f4c !important; border-color: var(--zd-color-red-500) !important; }

.u-bc-red-600 { border-color: #cc3340 !important; border-color: var(--zd-color-red-600) !important; }

.u-bc-red-700 { border-color: #8c232c !important; border-color: var(--zd-color-red-700) !important; }

.u-bc-red-800 { border-color: #681219 !important; border-color: var(--zd-color-red-800) !important; }

.u-bc-yellow-100 { border-color: #fff8ed !important; border-color: var(--zd-color-yellow-100) !important; }

.u-bc-yellow-200 { border-color: #fff0db !important; border-color: var(--zd-color-yellow-200) !important; }

.u-bc-yellow-300 { border-color: #fcdba9 !important; border-color: var(--zd-color-yellow-300) !important; }

.u-bc-yellow-400 { border-color: #ffb648 !important; border-color: var(--zd-color-yellow-400) !important; }

.u-bc-yellow-500 { border-color: #f5a133 !important; border-color: var(--zd-color-yellow-500) !important; }

.u-bc-yellow-600 { border-color: #ed961c !important; border-color: var(--zd-color-yellow-600) !important; }

.u-bc-yellow-700 { border-color: #ad5e18 !important; border-color: var(--zd-color-yellow-700) !important; }

.u-bc-yellow-800 { border-color: #703b15 !important; border-color: var(--zd-color-yellow-800) !important; }

/* Border Colors (secondary) */

.u-bc-azure-400 { border-color: #3091ec !important; border-color: var(--zd-color-secondary-azure-400) !important; }

.u-bc-azure-600 { border-color: #1371d6 !important; border-color: var(--zd-color-secondary-azure-600) !important; }

.u-bc-crimson-400 { border-color: #e34f32 !important; border-color: var(--zd-color-secondary-crimson-400) !important; }

.u-bc-crimson-600 { border-color: #c72a1c !important; border-color: var(--zd-color-secondary-crimson-600) !important; }

.u-bc-fuschia-400 { border-color: #d653c2 !important; border-color: var(--zd-color-secondary-fuschia-400) !important; }

.u-bc-fuschia-600 { border-color: #a81897 !important; border-color: var(--zd-color-secondary-fuschia-600) !important; }

.u-bc-lemon-400 { border-color: #ffd424 !important; border-color: var(--zd-color-secondary-lemon-400) !important; }

.u-bc-lemon-600 { border-color: #ffbb10 !important; border-color: var(--zd-color-secondary-lemon-600) !important; }

.u-bc-lime-400 { border-color: #43b324 !important; border-color: var(--zd-color-secondary-lime-400) !important; }

.u-bc-lime-600 { border-color: #2e8200 !important; border-color: var(--zd-color-secondary-lime-600) !important; }

.u-bc-mint-400 { border-color: #00a656 !important; border-color: var(--zd-color-secondary-mint-400) !important; }

.u-bc-mint-600 { border-color: #058541 !important; border-color: var(--zd-color-secondary-mint-600) !important; }

.u-bc-orange-400 { border-color: #de701d !important; border-color: var(--zd-color-secondary-orange-400) !important; }

.u-bc-orange-600 { border-color: #bf5000 !important; border-color: var(--zd-color-secondary-orange-600) !important; }

.u-bc-pink-400 { border-color: #ec4d63 !important; border-color: var(--zd-color-secondary-pink-400) !important; }

.u-bc-pink-600 { border-color: #d42054 !important; border-color: var(--zd-color-secondary-pink-600) !important; }

.u-bc-purple-400 { border-color: #b552e2 !important; border-color: var(--zd-color-secondary-purple-400) !important; }

.u-bc-purple-600 { border-color: #6a27b8 !important; border-color: var(--zd-color-secondary-purple-600) !important; }

.u-bc-royal-400 { border-color: #5d7df5 !important; border-color: var(--zd-color-secondary-royal-400) !important; }

.u-bc-royal-600 { border-color: #3353e2 !important; border-color: var(--zd-color-secondary-royal-600) !important; }

.u-bc-teal-400 { border-color: #02a191 !important; border-color: var(--zd-color-secondary-teal-400) !important; }

.u-bc-teal-600 { border-color: #028079 !important; border-color: var(--zd-color-secondary-teal-600) !important; }

/* Border Colors (muted secondary) */

.u-bc-azure-M400 { border-color: #5f8dcf !important; border-color: var(--zd-color-secondary-azure-M400) !important; }

.u-bc-azure-M600 { border-color: #3a70b2 !important; border-color: var(--zd-color-secondary-azure-M600) !important; }

.u-bc-crimson-M400 { border-color: #cc6c5b !important; border-color: var(--zd-color-secondary-crimson-M400) !important; }

.u-bc-crimson-M600 { border-color: #b24a3c !important; border-color: var(--zd-color-secondary-crimson-M600) !important; }

.u-bc-fuschia-M400 { border-color: #cf62a8 !important; border-color: var(--zd-color-secondary-fuschia-M400) !important; }

.u-bc-fuschia-M600 { border-color: #a8458c !important; border-color: var(--zd-color-secondary-fuschia-M600) !important; }

.u-bc-lemon-M400 { border-color: #e7a500 !important; border-color: var(--zd-color-secondary-lemon-M400) !important; }

.u-bc-lemon-M600 { border-color: #c38f00 !important; border-color: var(--zd-color-secondary-lemon-M600) !important; }

.u-bc-lime-M400 { border-color: #519e2d !important; border-color: var(--zd-color-secondary-lime-M400) !important; }

.u-bc-lime-M600 { border-color: #47782c !important; border-color: var(--zd-color-secondary-lime-M600) !important; }

.u-bc-mint-M400 { border-color: #299c66 !important; border-color: var(--zd-color-secondary-mint-M400) !important; }

.u-bc-mint-M600 { border-color: #2e8057 !important; border-color: var(--zd-color-secondary-mint-M600) !important; }

.u-bc-orange-M400 { border-color: #d4772c !important; border-color: var(--zd-color-secondary-orange-M400) !important; }

.u-bc-orange-M600 { border-color: #b35827 !important; border-color: var(--zd-color-secondary-orange-M600) !important; }

.u-bc-pink-M400 { border-color: #d57287 !important; border-color: var(--zd-color-secondary-pink-M400) !important; }

.u-bc-pink-M600 { border-color: #b23a5d !important; border-color: var(--zd-color-secondary-pink-M600) !important; }

.u-bc-purple-M400 { border-color: #b072cc !important; border-color: var(--zd-color-secondary-purple-M400) !important; }

.u-bc-purple-M600 { border-color: #9358b0 !important; border-color: var(--zd-color-secondary-purple-M600) !important; }

.u-bc-royal-M400 { border-color: #7986d8 !important; border-color: var(--zd-color-secondary-royal-M400) !important; }

.u-bc-royal-M600 { border-color: #4b61c3 !important; border-color: var(--zd-color-secondary-royal-M600) !important; }

.u-bc-teal-M400 { border-color: #2d9e8f !important; border-color: var(--zd-color-secondary-teal-M400) !important; }

.u-bc-teal-M600 { border-color: #3c7873 !important; border-color: var(--zd-color-secondary-teal-M600) !important; }

/* Border Colors (standard) */

.u-bc-black { border-color: #000 !important; border-color: var(--zd-color-black) !important; }

.u-bc-current-color { border-color: currentColor !important; }

.u-bc-inherit { border-color: inherit !important; }

.u-bc-transparent { border-color: transparent !important; }

.u-bc-white { border-color: #fff !important; border-color: var(--zd-color-white) !important; }

/* Border Colors (product) */

.u-bc-chat-orange { border-color: #f79a3e !important; border-color: var(--zd-color-chat-orange) !important; }

.u-bc-connect-red { border-color: #eb6651 !important; border-color: var(--zd-color-connect-red) !important; }

.u-bc-explore-blue { border-color: #30aabc !important; border-color: var(--zd-color-explore-blue) !important; }

.u-bc-gather-pink { border-color: #e7afa2 !important; border-color: var(--zd-color-gather-pink) !important; }

.u-bc-guide-pink { border-color: #eb4962 !important; border-color: var(--zd-color-guide-pink) !important; }

.u-bc-message-green { border-color: #37b8af !important; border-color: var(--zd-color-message-green) !important; }

.u-bc-sell-gold { border-color: #d4ae5e !important; border-color: var(--zd-color-sell-gold) !important; }

.u-bc-support-green { border-color: #78a300 !important; border-color: var(--zd-color-support-green) !important; }

.u-bc-talk-yellow { border-color: #efc93d !important; border-color: var(--zd-color-talk-yellow) !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-display-block { display: block !important; }

.u-display-flex { display: flex !important; }

.u-display-inherit { display: inherit !important; }

.u-display-inline { display: inline !important; }

.u-display-inline-block { display: inline-block !important; }

.u-display-inline-flex { display: inline-flex !important; }

.u-display-list-item { display: list-item !important; }

.u-display-none { display: none !important; }

.u-display-table { display: table !important; }

.u-display-table-cell { display: table-cell !important; }

.u-display-table-row { display: table-row !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-float-left { float: left !important; }

.u-float-none { float: none !important; }

.u-float-right { float: right !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-font-family-inherit { font-family: inherit !important; }

.u-font-family-monospace { font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace !important; font-family: var(--zd-font-family-monospace) !important; }

.u-font-family-system { font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif !important; font-family: var(--zd-font-family-system) !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

/* Font Sizes */

.u-fs-sm { font-size: 12px !important; font-size: var(--zd-font-size-sm) !important; }

.u-fs-md { font-size: 14px !important; font-size: var(--zd-font-size-md) !important; }

.u-fs-lg { font-size: 18px !important; font-size: var(--zd-font-size-lg) !important; }

.u-fs-xl { font-size: 22px !important; font-size: var(--zd-font-size-xl) !important; }

.u-fs-xxl { font-size: 26px !important; font-size: var(--zd-font-size-xxl) !important; }

.u-fs-xxxl { font-size: 36px !important; font-size: var(--zd-font-size-xxxl) !important; }

/* Monospace Font Sizes */

.u-fs-sm.u-font-family-monospace,
.u-fs-sm .u-font-family-monospace { font-size: 11px !important; font-size: var(--zd-font-size-sm-monospace) !important; }

.u-fs-md.u-font-family-monospace,
.u-fs-md .u-font-family-monospace { font-size: 13px !important; font-size: var(--zd-font-size-md-monospace) !important; }

.u-fs-lg.u-font-family-monospace,
.u-fs-lg .u-font-family-monospace { font-size: 17px !important; font-size: var(--zd-font-size-lg-monospace) !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-thin { font-weight: 100 !important; font-weight: var(--zd-font-weight-thin) !important; }

.u-extralight { font-weight: 200 !important; font-weight: var(--zd-font-weight-extralight) !important; }

.u-light { font-weight: 300 !important; font-weight: var(--zd-font-weight-light) !important; }

.u-regular { font-weight: 400 !important; font-weight: var(--zd-font-weight-regular) !important; }

.u-medium { font-weight: 500 !important; font-weight: var(--zd-font-weight-medium) !important; }

.u-semibold { font-weight: 600 !important; font-weight: var(--zd-font-weight-semibold) !important; }

.u-bold { font-weight: 700 !important; font-weight: var(--zd-font-weight-bold) !important; }

.u-extrabold { font-weight: 800 !important; font-weight: var(--zd-font-weight-extrabold) !important; }

.u-black { font-weight: 900 !important; font-weight: var(--zd-font-weight-black) !important; }

.u-ultralight { font-weight: 200 !important; font-weight: var(--zd-font-weight-ultralight) !important; }

.u-ultrabold { font-weight: 800 !important; font-weight: var(--zd-font-weight-ultrabold) !important; }

.u-heavy { font-weight: 900 !important; font-weight: var(--zd-font-weight-heavy) !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* Reserve space for transitioning to weighted text.
 * stackoverflow.com/a/20249560 */

:root {
  --zd-jitterfix-content: attr(data-text);
}

.u-jitterfix {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.u-jitterfix::after {
  display: block;
  visibility: hidden;
  line-height: 0;
  content: attr(data-text);
  content: var(--zd-jitterfix-content);
}

/* stylelint-disable declaration-no-important, max-line-length */

.u-jitterfix--thin::after { font-weight: 100; font-weight: var(--zd-font-weight-thin); }

.u-jitterfix--extralight::after { font-weight: 200; font-weight: var(--zd-font-weight-extralight); }

.u-jitterfix--light::after { font-weight: 300; font-weight: var(--zd-font-weight-light); }

.u-jitterfix--regular::after { font-weight: 400; font-weight: var(--zd-font-weight-regular); }

.u-jitterfix--medium::after { font-weight: 500; font-weight: var(--zd-font-weight-medium); }

.u-jitterfix--semibold::after { font-weight: 600; font-weight: var(--zd-font-weight-semibold); }

.u-jitterfix--bold::after { font-weight: 700; font-weight: var(--zd-font-weight-bold); }

.u-jitterfix--extrabold::after { font-weight: 800; font-weight: var(--zd-font-weight-extrabold); }

.u-jitterfix--black::after { font-weight: 900; font-weight: var(--zd-font-weight-black); }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

/* Line Heights (to be paired with corresponding font sizes) */

.u-lh-sm { line-height: 16px !important; line-height: var(--zd-line-height-sm) !important; }

.u-lh-md { line-height: 20px !important; line-height: var(--zd-line-height-md) !important; }

.u-lh-lg { line-height: 24px !important; line-height: var(--zd-line-height-lg) !important; }

.u-lh-xl { line-height: 28px !important; line-height: var(--zd-line-height-xl) !important; }

.u-lh-xxl { line-height: 32px !important; line-height: var(--zd-line-height-xxl) !important; }

.u-lh-xxxl { line-height: 44px !important; line-height: var(--zd-line-height-xxxl) !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-list-style-circle {
  list-style: circle outside !important;
}

.u-list-style-decimal {
  list-style: decimal outside !important;
}

.u-list-style-decimal-leading-zero {
  list-style: decimal-leading-zero outside !important;
}

.u-list-style-disc {
  list-style: disc outside !important;
}

.u-list-style-lower-alpha {
  list-style: lower-alpha outside !important;
}

.u-list-style-lower-roman {
  list-style: lower-roman outside !important;
}

.u-list-style-none {
  list-style: none outside !important;
}

.u-list-style-square {
  list-style: square outside !important;
}

.u-list-style-upper-alpha {
  list-style: upper-alpha outside !important;
}

.u-list-style-upper-roman {
  list-style: upper-roman outside !important;
}

/* stylelint-enable declaration-no-important */

[class^='u-list-style-'] {
  margin-left: 24px;
  padding: 0;
}

[class^='u-list-style-'].is-rtl {
  direction: rtl;
  margin-right: 24px;
  margin-left: 0;
}

.u-list-style-decimal > li,
.u-list-style-decimal-leading-zero > li,
.u-list-style-lower-alpha > li,
.u-list-style-lower-roman > li,
.u-list-style-upper-alpha > li,
.u-list-style-upper-roman > li {
  margin-left: -4px;
  padding-left: 4px;
}

.u-list-style-decimal.is-rtl > li,
.u-list-style-decimal-leading-zero.is-rtl > li,
.u-list-style-lower-alpha.is-rtl > li,
.u-list-style-lower-roman.is-rtl > li,
.u-list-style-upper-alpha.is-rtl > li,
.u-list-style-upper-roman.is-rtl > li {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-opacity-opaque { opacity: 1 !important; }

.u-opacity-transparent { opacity: 0 !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-position-absolute { position: absolute !important; }

.u-position-fixed { position: fixed !important; }

.u-position-inherit { position: inherit !important; }

.u-position-relative { position: relative !important; }

.u-position-static { position: static !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-m { margin: 20px !important; margin: var(--zd-spacing) !important; }

.u-mt { margin-top: 20px !important; margin-top: var(--zd-spacing) !important; }

.u-mr { margin-right: 20px !important; margin-right: var(--zd-spacing) !important; }

.u-mb { margin-bottom: 20px !important; margin-bottom: var(--zd-spacing) !important; }

.u-ml { margin-left: 20px !important; margin-left: var(--zd-spacing) !important; }

.u-mh {
  margin-right: 20px !important;
  margin-right: var(--zd-spacing) !important;
  margin-left: 20px !important;
  margin-left: var(--zd-spacing) !important;
}

.u-mv {
  margin-top: 20px !important;
  margin-top: var(--zd-spacing) !important;
  margin-bottom: 20px !important;
  margin-bottom: var(--zd-spacing) !important;
}

.u-p { padding: 20px !important; padding: var(--zd-spacing) !important; }

.u-pt { padding-top: 20px !important; padding-top: var(--zd-spacing) !important; }

.u-pr { padding-right: 20px !important; padding-right: var(--zd-spacing) !important; }

.u-pb { padding-bottom: 20px !important; padding-bottom: var(--zd-spacing) !important; }

.u-pl { padding-left: 20px !important; padding-left: var(--zd-spacing) !important; }

.u-ph {
  padding-right: 20px !important;
  padding-right: var(--zd-spacing) !important;
  padding-left: 20px !important;
  padding-left: var(--zd-spacing) !important;
}

.u-pv {
  padding-top: 20px !important;
  padding-top: var(--zd-spacing) !important;
  padding-bottom: 20px !important;
  padding-bottom: var(--zd-spacing) !important;
}

.u-m- { margin: calc(20px * -1) !important; margin: calc(var(--zd-spacing) * -1) !important; }

.u-mt- { margin-top: calc(20px * -1) !important; margin-top: calc(var(--zd-spacing) * -1) !important; }

.u-mr- { margin-right: calc(20px * -1) !important; margin-right: calc(var(--zd-spacing) * -1) !important; }

.u-mb- { margin-bottom: calc(20px * -1) !important; margin-bottom: calc(var(--zd-spacing) * -1) !important; }

.u-ml- { margin-left: calc(20px * -1) !important; margin-left: calc(var(--zd-spacing) * -1) !important; }

.u-mh- {
  margin-right: calc(20px * -1) !important;
  margin-right: calc(var(--zd-spacing) * -1) !important;
  margin-left: calc(20px * -1) !important;
  margin-left: calc(var(--zd-spacing) * -1) !important;
}

.u-mv- {
  margin-top: calc(20px * -1) !important;
  margin-top: calc(var(--zd-spacing) * -1) !important;
  margin-bottom: calc(20px * -1) !important;
  margin-bottom: calc(var(--zd-spacing) * -1) !important;
}

/* Zero Spacing */

.u-m-0 { margin: 0 !important; }

.u-mt-0 { margin-top: 0 !important; }

.u-mr-0 { margin-right: 0 !important; }

.u-mb-0 { margin-bottom: 0 !important; }

.u-ml-0 { margin-left: 0 !important; }

.u-mh-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.u-mv-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.u-p-0 { padding: 0 !important; }

.u-pt-0 { padding-top: 0 !important; }

.u-pr-0 { padding-right: 0 !important; }

.u-pb-0 { padding-bottom: 0 !important; }

.u-pl-0 { padding-left: 0 !important; }

.u-ph-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.u-pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* Large Spacing */

.u-m-lg { margin: 32px !important; margin: var(--zd-spacing-lg) !important; }

.u-mt-lg { margin-top: 32px !important; margin-top: var(--zd-spacing-lg) !important; }

.u-mr-lg { margin-right: 32px !important; margin-right: var(--zd-spacing-lg) !important; }

.u-mb-lg { margin-bottom: 32px !important; margin-bottom: var(--zd-spacing-lg) !important; }

.u-ml-lg { margin-left: 32px !important; margin-left: var(--zd-spacing-lg) !important; }

.u-mh-lg {
  margin-right: 32px !important;
  margin-right: var(--zd-spacing-lg) !important;
  margin-left: 32px !important;
  margin-left: var(--zd-spacing-lg) !important;
}

.u-mv-lg {
  margin-top: 32px !important;
  margin-top: var(--zd-spacing-lg) !important;
  margin-bottom: 32px !important;
  margin-bottom: var(--zd-spacing-lg) !important;
}

.u-p-lg { padding: 32px !important; padding: var(--zd-spacing-lg) !important; }

.u-pt-lg { padding-top: 32px !important; padding-top: var(--zd-spacing-lg) !important; }

.u-pr-lg { padding-right: 32px !important; padding-right: var(--zd-spacing-lg) !important; }

.u-pb-lg { padding-bottom: 32px !important; padding-bottom: var(--zd-spacing-lg) !important; }

.u-pl-lg { padding-left: 32px !important; padding-left: var(--zd-spacing-lg) !important; }

.u-ph-lg {
  padding-right: 32px !important;
  padding-right: var(--zd-spacing-lg) !important;
  padding-left: 32px !important;
  padding-left: var(--zd-spacing-lg) !important;
}

.u-pv-lg {
  padding-top: 32px !important;
  padding-top: var(--zd-spacing-lg) !important;
  padding-bottom: 32px !important;
  padding-bottom: var(--zd-spacing-lg) !important;
}

.u-m-lg- { margin: calc(32px * -1) !important; margin: calc(var(--zd-spacing-lg) * -1) !important; }

.u-mt-lg- { margin-top: calc(32px * -1) !important; margin-top: calc(var(--zd-spacing-lg) * -1) !important; }

.u-mr-lg- { margin-right: calc(32px * -1) !important; margin-right: calc(var(--zd-spacing-lg) * -1) !important; }

.u-mb-lg- { margin-bottom: calc(32px * -1) !important; margin-bottom: calc(var(--zd-spacing-lg) * -1) !important; }

.u-ml-lg- { margin-left: calc(32px * -1) !important; margin-left: calc(var(--zd-spacing-lg) * -1) !important; }

.u-mh-lg- {
  margin-right: calc(32px * -1) !important;
  margin-right: calc(var(--zd-spacing-lg) * -1) !important;
  margin-left: calc(32px * -1) !important;
  margin-left: calc(var(--zd-spacing-lg) * -1) !important;
}

.u-mv-lg- {
  margin-top: calc(32px * -1) !important;
  margin-top: calc(var(--zd-spacing-lg) * -1) !important;
  margin-bottom: calc(32px * -1) !important;
  margin-bottom: calc(var(--zd-spacing-lg) * -1) !important;
}

/* Small Spacing */

.u-m-sm { margin: 12px !important; margin: var(--zd-spacing-sm) !important; }

.u-mt-sm { margin-top: 12px !important; margin-top: var(--zd-spacing-sm) !important; }

.u-mr-sm { margin-right: 12px !important; margin-right: var(--zd-spacing-sm) !important; }

.u-mb-sm { margin-bottom: 12px !important; margin-bottom: var(--zd-spacing-sm) !important; }

.u-ml-sm { margin-left: 12px !important; margin-left: var(--zd-spacing-sm) !important; }

.u-mh-sm {
  margin-right: 12px !important;
  margin-right: var(--zd-spacing-sm) !important;
  margin-left: 12px !important;
  margin-left: var(--zd-spacing-sm) !important;
}

.u-mv-sm {
  margin-top: 12px !important;
  margin-top: var(--zd-spacing-sm) !important;
  margin-bottom: 12px !important;
  margin-bottom: var(--zd-spacing-sm) !important;
}

.u-p-sm { padding: 12px !important; padding: var(--zd-spacing-sm) !important; }

.u-pt-sm { padding-top: 12px !important; padding-top: var(--zd-spacing-sm) !important; }

.u-pr-sm { padding-right: 12px !important; padding-right: var(--zd-spacing-sm) !important; }

.u-pb-sm { padding-bottom: 12px !important; padding-bottom: var(--zd-spacing-sm) !important; }

.u-pl-sm { padding-left: 12px !important; padding-left: var(--zd-spacing-sm) !important; }

.u-ph-sm {
  padding-right: 12px !important;
  padding-right: var(--zd-spacing-sm) !important;
  padding-left: 12px !important;
  padding-left: var(--zd-spacing-sm) !important;
}

.u-pv-sm {
  padding-top: 12px !important;
  padding-top: var(--zd-spacing-sm) !important;
  padding-bottom: 12px !important;
  padding-bottom: var(--zd-spacing-sm) !important;
}

.u-m-sm- { margin: calc(12px * -1) !important; margin: calc(var(--zd-spacing-sm) * -1) !important; }

.u-mt-sm- { margin-top: calc(12px * -1) !important; margin-top: calc(var(--zd-spacing-sm) * -1) !important; }

.u-mr-sm- { margin-right: calc(12px * -1) !important; margin-right: calc(var(--zd-spacing-sm) * -1) !important; }

.u-mb-sm- { margin-bottom: calc(12px * -1) !important; margin-bottom: calc(var(--zd-spacing-sm) * -1) !important; }

.u-ml-sm- { margin-left: calc(12px * -1) !important; margin-left: calc(var(--zd-spacing-sm) * -1) !important; }

.u-mh-sm- {
  margin-right: calc(12px * -1) !important;
  margin-right: calc(var(--zd-spacing-sm) * -1) !important;
  margin-left: calc(12px * -1) !important;
  margin-left: calc(var(--zd-spacing-sm) * -1) !important;
}

.u-mv-sm- {
  margin-top: calc(12px * -1) !important;
  margin-top: calc(var(--zd-spacing-sm) * -1) !important;
  margin-bottom: calc(12px * -1) !important;
  margin-bottom: calc(var(--zd-spacing-sm) * -1) !important;
}

/* Extra-Large Spacing */

.u-m-xl { margin: 40px !important; margin: var(--zd-spacing-xl) !important; }

.u-mt-xl { margin-top: 40px !important; margin-top: var(--zd-spacing-xl) !important; }

.u-mr-xl { margin-right: 40px !important; margin-right: var(--zd-spacing-xl) !important; }

.u-mb-xl { margin-bottom: 40px !important; margin-bottom: var(--zd-spacing-xl) !important; }

.u-ml-xl { margin-left: 40px !important; margin-left: var(--zd-spacing-xl) !important; }

.u-mh-xl {
  margin-right: 40px !important;
  margin-right: var(--zd-spacing-xl) !important;
  margin-left: 40px !important;
  margin-left: var(--zd-spacing-xl) !important;
}

.u-mv-xl {
  margin-top: 40px !important;
  margin-top: var(--zd-spacing-xl) !important;
  margin-bottom: 40px !important;
  margin-bottom: var(--zd-spacing-xl) !important;
}

.u-p-xl { padding: 40px !important; padding: var(--zd-spacing-xl) !important; }

.u-pt-xl { padding-top: 40px !important; padding-top: var(--zd-spacing-xl) !important; }

.u-pr-xl { padding-right: 40px !important; padding-right: var(--zd-spacing-xl) !important; }

.u-pb-xl { padding-bottom: 40px !important; padding-bottom: var(--zd-spacing-xl) !important; }

.u-pl-xl { padding-left: 40px !important; padding-left: var(--zd-spacing-xl) !important; }

.u-ph-xl {
  padding-right: 40px !important;
  padding-right: var(--zd-spacing-xl) !important;
  padding-left: 40px !important;
  padding-left: var(--zd-spacing-xl) !important;
}

.u-pv-xl {
  padding-top: 40px !important;
  padding-top: var(--zd-spacing-xl) !important;
  padding-bottom: 40px !important;
  padding-bottom: var(--zd-spacing-xl) !important;
}

.u-m-xl- { margin: calc(40px * -1) !important; margin: calc(var(--zd-spacing-xl) * -1) !important; }

.u-mt-xl- { margin-top: calc(40px * -1) !important; margin-top: calc(var(--zd-spacing-xl) * -1) !important; }

.u-mr-xl- { margin-right: calc(40px * -1) !important; margin-right: calc(var(--zd-spacing-xl) * -1) !important; }

.u-mb-xl- { margin-bottom: calc(40px * -1) !important; margin-bottom: calc(var(--zd-spacing-xl) * -1) !important; }

.u-ml-xl- { margin-left: calc(40px * -1) !important; margin-left: calc(var(--zd-spacing-xl) * -1) !important; }

.u-mh-xl- {
  margin-right: calc(40px * -1) !important;
  margin-right: calc(var(--zd-spacing-xl) * -1) !important;
  margin-left: calc(40px * -1) !important;
  margin-left: calc(var(--zd-spacing-xl) * -1) !important;
}

.u-mv-xl- {
  margin-top: calc(40px * -1) !important;
  margin-top: calc(var(--zd-spacing-xl) * -1) !important;
  margin-bottom: calc(40px * -1) !important;
  margin-bottom: calc(var(--zd-spacing-xl) * -1) !important;
}

/* Extra-Small Spacing */

.u-m-xs { margin: 8px !important; margin: var(--zd-spacing-xs) !important; }

.u-mt-xs { margin-top: 8px !important; margin-top: var(--zd-spacing-xs) !important; }

.u-mr-xs { margin-right: 8px !important; margin-right: var(--zd-spacing-xs) !important; }

.u-mb-xs { margin-bottom: 8px !important; margin-bottom: var(--zd-spacing-xs) !important; }

.u-ml-xs { margin-left: 8px !important; margin-left: var(--zd-spacing-xs) !important; }

.u-mh-xs {
  margin-right: 8px !important;
  margin-right: var(--zd-spacing-xs) !important;
  margin-left: 8px !important;
  margin-left: var(--zd-spacing-xs) !important;
}

.u-mv-xs {
  margin-top: 8px !important;
  margin-top: var(--zd-spacing-xs) !important;
  margin-bottom: 8px !important;
  margin-bottom: var(--zd-spacing-xs) !important;
}

.u-p-xs { padding: 8px !important; padding: var(--zd-spacing-xs) !important; }

.u-pt-xs { padding-top: 8px !important; padding-top: var(--zd-spacing-xs) !important; }

.u-pr-xs { padding-right: 8px !important; padding-right: var(--zd-spacing-xs) !important; }

.u-pb-xs { padding-bottom: 8px !important; padding-bottom: var(--zd-spacing-xs) !important; }

.u-pl-xs { padding-left: 8px !important; padding-left: var(--zd-spacing-xs) !important; }

.u-ph-xs {
  padding-right: 8px !important;
  padding-right: var(--zd-spacing-xs) !important;
  padding-left: 8px !important;
  padding-left: var(--zd-spacing-xs) !important;
}

.u-pv-xs {
  padding-top: 8px !important;
  padding-top: var(--zd-spacing-xs) !important;
  padding-bottom: 8px !important;
  padding-bottom: var(--zd-spacing-xs) !important;
}

.u-m-xs- { margin: calc(8px * -1) !important; margin: calc(var(--zd-spacing-xs) * -1) !important; }

.u-mt-xs- { margin-top: calc(8px * -1) !important; margin-top: calc(var(--zd-spacing-xs) * -1) !important; }

.u-mr-xs- { margin-right: calc(8px * -1) !important; margin-right: calc(var(--zd-spacing-xs) * -1) !important; }

.u-mb-xs- { margin-bottom: calc(8px * -1) !important; margin-bottom: calc(var(--zd-spacing-xs) * -1) !important; }

.u-ml-xs- { margin-left: calc(8px * -1) !important; margin-left: calc(var(--zd-spacing-xs) * -1) !important; }

.u-mh-xs- {
  margin-right: calc(8px * -1) !important;
  margin-right: calc(var(--zd-spacing-xs) * -1) !important;
  margin-left: calc(8px * -1) !important;
  margin-left: calc(var(--zd-spacing-xs) * -1) !important;
}

.u-mv-xs- {
  margin-top: calc(8px * -1) !important;
  margin-top: calc(var(--zd-spacing-xs) * -1) !important;
  margin-bottom: calc(8px * -1) !important;
  margin-bottom: calc(var(--zd-spacing-xs) * -1) !important;
}

/* Extra Extra-Large Spacing */

.u-m-xxl { margin: 48px !important; margin: var(--zd-spacing-xxl) !important; }

.u-mt-xxl { margin-top: 48px !important; margin-top: var(--zd-spacing-xxl) !important; }

.u-mr-xxl { margin-right: 48px !important; margin-right: var(--zd-spacing-xxl) !important; }

.u-mb-xxl { margin-bottom: 48px !important; margin-bottom: var(--zd-spacing-xxl) !important; }

.u-ml-xxl { margin-left: 48px !important; margin-left: var(--zd-spacing-xxl) !important; }

.u-mh-xxl {
  margin-right: 48px !important;
  margin-right: var(--zd-spacing-xxl) !important;
  margin-left: 48px !important;
  margin-left: var(--zd-spacing-xxl) !important;
}

.u-mv-xxl {
  margin-top: 48px !important;
  margin-top: var(--zd-spacing-xxl) !important;
  margin-bottom: 48px !important;
  margin-bottom: var(--zd-spacing-xxl) !important;
}

.u-p-xxl { padding: 48px !important; padding: var(--zd-spacing-xxl) !important; }

.u-pt-xxl { padding-top: 48px !important; padding-top: var(--zd-spacing-xxl) !important; }

.u-pr-xxl { padding-right: 48px !important; padding-right: var(--zd-spacing-xxl) !important; }

.u-pb-xxl { padding-bottom: 48px !important; padding-bottom: var(--zd-spacing-xxl) !important; }

.u-pl-xxl { padding-left: 48px !important; padding-left: var(--zd-spacing-xxl) !important; }

.u-ph-xxl {
  padding-right: 48px !important;
  padding-right: var(--zd-spacing-xxl) !important;
  padding-left: 48px !important;
  padding-left: var(--zd-spacing-xxl) !important;
}

.u-pv-xxl {
  padding-top: 48px !important;
  padding-top: var(--zd-spacing-xxl) !important;
  padding-bottom: 48px !important;
  padding-bottom: var(--zd-spacing-xxl) !important;
}

.u-m-xxl- { margin: calc(48px * -1) !important; margin: calc(var(--zd-spacing-xxl) * -1) !important; }

.u-mt-xxl- { margin-top: calc(48px * -1) !important; margin-top: calc(var(--zd-spacing-xxl) * -1) !important; }

.u-mr-xxl- { margin-right: calc(48px * -1) !important; margin-right: calc(var(--zd-spacing-xxl) * -1) !important; }

.u-mb-xxl- { margin-bottom: calc(48px * -1) !important; margin-bottom: calc(var(--zd-spacing-xxl) * -1) !important; }

.u-ml-xxl- { margin-left: calc(48px * -1) !important; margin-left: calc(var(--zd-spacing-xxl) * -1) !important; }

.u-mh-xxl- {
  margin-right: calc(48px * -1) !important;
  margin-right: calc(var(--zd-spacing-xxl) * -1) !important;
  margin-left: calc(48px * -1) !important;
  margin-left: calc(var(--zd-spacing-xxl) * -1) !important;
}

.u-mv-xxl- {
  margin-top: calc(48px * -1) !important;
  margin-top: calc(var(--zd-spacing-xxl) * -1) !important;
  margin-bottom: calc(48px * -1) !important;
  margin-bottom: calc(var(--zd-spacing-xxl) * -1) !important;
}

/* Extra Extra-Small Spacing */

.u-m-xxs { margin: 4px !important; margin: var(--zd-spacing-xxs) !important; }

.u-mt-xxs { margin-top: 4px !important; margin-top: var(--zd-spacing-xxs) !important; }

.u-mr-xxs { margin-right: 4px !important; margin-right: var(--zd-spacing-xxs) !important; }

.u-mb-xxs { margin-bottom: 4px !important; margin-bottom: var(--zd-spacing-xxs) !important; }

.u-ml-xxs { margin-left: 4px !important; margin-left: var(--zd-spacing-xxs) !important; }

.u-mh-xxs {
  margin-right: 4px !important;
  margin-right: var(--zd-spacing-xxs) !important;
  margin-left: 4px !important;
  margin-left: var(--zd-spacing-xxs) !important;
}

.u-mv-xxs {
  margin-top: 4px !important;
  margin-top: var(--zd-spacing-xxs) !important;
  margin-bottom: 4px !important;
  margin-bottom: var(--zd-spacing-xxs) !important;
}

.u-p-xxs { padding: 4px !important; padding: var(--zd-spacing-xxs) !important; }

.u-pt-xxs { padding-top: 4px !important; padding-top: var(--zd-spacing-xxs) !important; }

.u-pr-xxs { padding-right: 4px !important; padding-right: var(--zd-spacing-xxs) !important; }

.u-pb-xxs { padding-bottom: 4px !important; padding-bottom: var(--zd-spacing-xxs) !important; }

.u-pl-xxs { padding-left: 4px !important; padding-left: var(--zd-spacing-xxs) !important; }

.u-ph-xxs {
  padding-right: 4px !important;
  padding-right: var(--zd-spacing-xxs) !important;
  padding-left: 4px !important;
  padding-left: var(--zd-spacing-xxs) !important;
}

.u-pv-xxs {
  padding-top: 4px !important;
  padding-top: var(--zd-spacing-xxs) !important;
  padding-bottom: 4px !important;
  padding-bottom: var(--zd-spacing-xxs) !important;
}

.u-m-xxs- { margin: calc(4px * -1) !important; margin: calc(var(--zd-spacing-xxs) * -1) !important; }

.u-mt-xxs- { margin-top: calc(4px * -1) !important; margin-top: calc(var(--zd-spacing-xxs) * -1) !important; }

.u-mr-xxs- { margin-right: calc(4px * -1) !important; margin-right: calc(var(--zd-spacing-xxs) * -1) !important; }

.u-mb-xxs- { margin-bottom: calc(4px * -1) !important; margin-bottom: calc(var(--zd-spacing-xxs) * -1) !important; }

.u-ml-xxs- { margin-left: calc(4px * -1) !important; margin-left: calc(var(--zd-spacing-xxs) * -1) !important; }

.u-mh-xxs- {
  margin-right: calc(4px * -1) !important;
  margin-right: calc(var(--zd-spacing-xxs) * -1) !important;
  margin-left: calc(4px * -1) !important;
  margin-left: calc(var(--zd-spacing-xxs) * -1) !important;
}

.u-mv-xxs- {
  margin-top: calc(4px * -1) !important;
  margin-top: calc(var(--zd-spacing-xxs) * -1) !important;
  margin-bottom: calc(4px * -1) !important;
  margin-bottom: calc(var(--zd-spacing-xxs) * -1) !important;
}

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-ta-center { text-align: center !important; }

.u-ta-inherit { text-align: inherit !important; }

.u-ta-justify { text-align: justify !important; }

.u-ta-left { text-align: left !important; }

.u-ta-right { text-align: right !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

.u-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-va-baseline { vertical-align: baseline !important; }

.u-va-bottom { vertical-align: bottom !important; }

.u-va-inherit { vertical-align: inherit !important; }

.u-va-middle { vertical-align: middle !important; }

.u-va-sub { vertical-align: sub !important; }

.u-va-super { vertical-align: super !important; }

.u-va-text-bottom { vertical-align: text-bottom !important; }

.u-va-text-top { vertical-align: text-top !important; }

.u-va-top { vertical-align: top !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-visibility-visible { visibility: visible !important; }

.u-visibility-hidden { visibility: hidden !important; }

/**
  * Used to make hidden text accessible to screen-readers.
  * Borrowed from the Ebay accessibility standards:
  * https://ebay.gitbooks.io/mindpatterns/content/techniques/offscreentext.html
  */

.u-visibility-screenreader {
  position: absolute;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
}

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* stylelint-disable declaration-no-important */

.u-1\/1,
.u-2\/2,
.u-3\/3,
.u-4\/4,
.u-5\/5,
.u-6\/6,
.u-8\/8,
.u-12\/12,
.u-24\/24 { width: 100% !important; }

/* 5ths-Based Units */

.u-1\/5 { width: calc(100% / 5) !important; }

.u-2\/5 { width: calc(200% / 5) !important; }

.u-3\/5 { width: calc(300% / 5) !important; }

.u-4\/5 { width: calc(400% / 5) !important; }

/* 24ths-Based Units */

.u-1\/24 { width: calc(100% / 24) !important; }

.u-2\/24,
.u-1\/12 { width: calc(200% / 24) !important; }

.u-3\/24,
.u-1\/8 { width: calc(300% / 24) !important; }

.u-4\/24,
.u-2\/12,
.u-1\/6 { width: calc(400% / 24) !important; }

.u-5\/24 { width: calc(500% / 24) !important; }

.u-6\/24,
.u-3\/12,
.u-2\/8,
.u-1\/4 { width: calc(600% / 24) !important; }

.u-7\/24 { width: calc(700% / 24) !important; }

.u-8\/24,
.u-4\/12,
.u-2\/6,
.u-1\/3 { width: calc(800% / 24) !important; }

.u-9\/24,
.u-3\/8 { width: calc(900% / 24) !important; }

.u-10\/24,
.u-5\/12 { width: calc(1000% / 24) !important; }

.u-11\/24 { width: calc(1100% / 24) !important; }

.u-12\/24,
.u-6\/12,
.u-4\/8,
.u-3\/6,
.u-2\/4,
.u-1\/2 { width: calc(1200% / 24) !important; }

.u-13\/24 { width: calc(1300% / 24) !important; }

.u-14\/24,
.u-7\/12 { width: calc(1400% / 24) !important; }

.u-15\/24,
.u-5\/8 { width: calc(1500% / 24) !important; }

.u-16\/24,
.u-8\/12,
.u-4\/6,
.u-2\/3 { width: calc(1600% / 24) !important; }

.u-17\/24 { width: calc(1700% / 24) !important; }

.u-18\/24,
.u-9\/12,
.u-6\/8,
.u-3\/4 { width: calc(1800% / 24) !important; }

.u-19\/24 { width: calc(1900% / 24) !important; }

.u-20\/24,
.u-10\/12,
.u-5\/6 { width: calc(2000% / 24) !important; }

.u-21\/24,
.u-7\/8 { width: calc(2100% / 24) !important; }

.u-22\/24,
.u-11\/12 { width: calc(2200% / 24) !important; }

.u-23\/24 { width: calc(2300% / 24) !important; }

/* stylelint-enable declaration-no-important */

/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/* 1. Anchor tag reset.
 * 2. Override for <input> & <button> elements.
 * 3. FF <input type="submit" fix. */

.c-btn {
  display: inline-block;
  /* prettier-ignore */
  transition:
    border-color .25s ease-in-out,
    box-shadow .1s ease-in-out,
    background-color .25s ease-in-out,
    color .25s ease-in-out;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  text-decoration: none; /* [1] */
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: inherit; /* [2] */
  font-weight: 400;
  font-weight: var(--zd-font-weight-regular);
  -webkit-font-smoothing: subpixel-antialiased;
  box-sizing: border-box;
  -webkit-user-select: none;
              -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
}

.c-btn--pill {
  border-radius: 100px;
}

.c-btn--anchor {
  display: inline;
  border: none;
  border-radius: 0;
  vertical-align: baseline;
  white-space: normal;
  font-weight: inherit;
}

.c-btn::-moz-focus-inner {
  /* [3] */
  border: 0;
  padding: 0;
}

.c-btn:hover,.c-btn.is-hovered {
  text-decoration: none; /* [1] */
}

.c-btn--anchor:hover,.c-btn--anchor.is-hovered {
  text-decoration: underline;
}

.c-btn:focus {
  outline: none;
}

.c-btn.focus-visible,.c-btn.is-focused {
  text-decoration: none; /* [1] */
}

.c-btn:focus-visible,.c-btn.is-focused {
  text-decoration: none; /* [1] */
}

.c-btn--anchor.focus-visible,.c-btn--anchor.is-focused {
  text-decoration: underline;
}

.c-btn--anchor:focus-visible,.c-btn--anchor.is-focused {
  text-decoration: underline;
}

.c-btn:active,.c-btn.is-active {
  /* prettier-ignore */
  transition:
    border-color .1s ease-in-out,
    background-color .1s ease-in-out,
    color .1s ease-in-out;
  text-decoration: none; /* [1] */
}

.c-btn--anchor:active,.c-btn--anchor.is-active {
  text-decoration: underline;
}

.c-btn:disabled,.c-btn.is-disabled {
  cursor: default;
}

.c-btn--anchor:disabled,.c-btn--anchor.is-disabled {
  text-decoration: none;
}

:root {
  --zd-btn-height: 40px;
  --zd-btn-line-height: calc(var(--zd-btn-height) - 2px);
  --zd-btn-padding: calc(27 / 14 * 1em);
  --zd-btn--lg-height: 48px;
  --zd-btn--lg-line-height: calc(var(--zd-btn--lg-height) - 2px);
  --zd-btn--lg-padding: calc(27 / 14 * 1em);
  --zd-btn--sm-height: 32px;
  --zd-btn--sm-line-height: calc(var(--zd-btn--sm-height) - 2px);
  --zd-btn--sm-padding: calc(27 / 12 * 1em);
}

.c-btn {
  padding: 0 calc(27 / 14 * 1em);
  padding: 0 var(--zd-btn-padding);
  height: 40px;
  height: var(--zd-btn-height);
  line-height: calc(40px - 2px);
  line-height: var(--zd-btn-line-height);
  font-size: 14px;
  font-size: var(--zd-font-size-md);
}

.c-btn--full {
  width: 100%;
}

.c-btn--lg {
  padding: 0 calc(27 / 14 * 1em);
  padding: 0 var(--zd-btn--lg-padding);
  height: 48px;
  height: var(--zd-btn--lg-height);
  line-height: calc(48px - 2px);
  line-height: var(--zd-btn--lg-line-height);
}

.c-btn--sm {
  padding: 0 calc(27 / 12 * 1em);
  padding: 0 var(--zd-btn--sm-padding);
  height: 32px;
  height: var(--zd-btn--sm-height);
  line-height: calc(32px - 2px);
  line-height: var(--zd-btn--sm-line-height);
  font-size: 12px;
  font-size: var(--zd-font-size-sm);
}

.c-btn--icon {
  padding: 0;
  width: 40px;
  width: var(--zd-btn-height);
}

.c-btn--icon.c-btn--lg {
  width: 48px;
  width: var(--zd-btn--lg-height);
}

.c-btn--icon.c-btn--sm {
  width: 32px;
  width: var(--zd-btn--sm-height);
}

.c-btn--anchor {
  padding: 0;
  height: auto;
  line-height: normal;
  font-size: inherit;
}

:root {
  --zd-btn-color: var(--zd-color-blue-600);
  --zd-btn-color_hover: var(--zd-color-blue-700);
  --zd-btn-color_active: var(--zd-color-blue-800);
  --zd-btn-box-shadow: 0 0 0 3px color-mod(var(--zd-btn-color) alpha(35%));
  --zd-btn--danger-color: var(--zd-color-red-600);
  --zd-btn--danger-color_hover: var(--zd-color-red-700);
  --zd-btn--danger-color_active: var(--zd-color-red-800);
  --zd-btn--danger-box-shadow: 0 0 0 3px color-mod(var(--zd-btn--danger-color) alpha(35%));
}

.c-btn {
  border-color: #1f73b7;
  border-color: var(--zd-btn-color);
  background-color: transparent;
  color: #1f73b7;
  color: var(--zd-btn-color);
}

.c-btn--danger {
  border-color: #cc3340;
  border-color: var(--zd-btn--danger-color);
  color: #cc3340;
  color: var(--zd-btn--danger-color);
}

.c-btn--primary {
  border-color: transparent;
  background-color: #1f73b7;
  background-color: var(--zd-btn-color);
  color: #fff;
  color: var(--zd-color-white);
}

.c-btn--primary.c-btn--danger {
  background-color: #cc3340;
  background-color: var(--zd-btn--danger-color);
}

.c-btn--basic {
  border-color: transparent;
}

.c-btn--basic.c-btn--icon {
  border: none;
  color: #68737d;
  color: var(--zd-color-grey-600);
}

.c-btn--anchor {
  border-color: transparent;
}

.c-btn:hover,.c-btn.is-hovered {
  border-color: #144a75;
  border-color: var(--zd-btn-color_hover);
  background-color: color-mod(#1f73b7 alpha(8%));
  background-color: color-mod(var(--zd-btn-color) alpha(8%));
  color: #144a75;
  color: var(--zd-btn-color_hover);
}

.c-btn--danger:hover,.c-btn--danger.is-hovered {
  border-color: #8c232c;
  border-color: var(--zd-btn--danger-color_hover);
  background-color: color-mod(#cc3340 alpha(8%));
  background-color: color-mod(var(--zd-btn--danger-color) alpha(8%));
  color: #8c232c;
  color: var(--zd-btn--danger-color_hover);
}

.c-btn--primary:hover,.c-btn--primary.is-hovered {
  border-color: transparent;
  background-color: #144a75;
  background-color: var(--zd-btn-color_hover);
  color: #fff;
  color: var(--zd-color-white);
}

.c-btn--primary.c-btn--danger:hover,.c-btn--primary.c-btn--danger.is-hovered {
  background-color: #8c232c;
  background-color: var(--zd-btn--danger-color_hover);
}

.c-btn--basic:hover,.c-btn--basic.is-hovered {
  border-color: transparent;
}

.c-btn--basic.c-btn--icon:hover,.c-btn--basic.c-btn--icon.is-hovered {
  color: #49545c;
  color: var(--zd-color-grey-700);
}

.c-btn--anchor:hover,.c-btn--anchor.is-hovered {
  border-color: transparent;
  background-color: transparent;
}

.c-btn.focus-visible,.c-btn.is-focused {
  box-shadow: 0 0 0 3px color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-btn-box-shadow);
}

.c-btn.focus-visible,.c-btn.is-focused {
  box-shadow: 0 0 0 3px color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-btn-box-shadow);
}

.c-btn:focus-visible,.c-btn.is-focused {
  box-shadow: 0 0 0 3px color-mod(#1f73b7 alpha(35%));
  box-shadow: var(--zd-btn-box-shadow);
}

.c-btn--focus-inset.focus-visible,.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#1f73b7 alpha(35%));
  box-shadow: inset var(--zd-btn-box-shadow);
}

.c-btn--focus-inset.focus-visible,.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#1f73b7 alpha(35%));
  box-shadow: inset var(--zd-btn-box-shadow);
}

.c-btn--focus-inset:focus-visible,.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#1f73b7 alpha(35%));
  box-shadow: inset var(--zd-btn-box-shadow);
}

.c-btn--danger.focus-visible,.c-btn--danger.is-focused {
  box-shadow: 0 0 0 3px color-mod(#cc3340 alpha(35%));
  box-shadow: var(--zd-btn--danger-box-shadow);
}

.c-btn--danger.focus-visible,.c-btn--danger.is-focused {
  box-shadow: 0 0 0 3px color-mod(#cc3340 alpha(35%));
  box-shadow: var(--zd-btn--danger-box-shadow);
}

.c-btn--danger:focus-visible,.c-btn--danger.is-focused {
  box-shadow: 0 0 0 3px color-mod(#cc3340 alpha(35%));
  box-shadow: var(--zd-btn--danger-box-shadow);
}

.c-btn--danger.c-btn--focus-inset.focus-visible,.c-btn--danger.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#cc3340 alpha(35%));
  box-shadow: inset var(--zd-btn--danger-box-shadow);
}

.c-btn--danger.c-btn--focus-inset.focus-visible,.c-btn--danger.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#cc3340 alpha(35%));
  box-shadow: inset var(--zd-btn--danger-box-shadow);
}

.c-btn--danger.c-btn--focus-inset:focus-visible,.c-btn--danger.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#cc3340 alpha(35%));
  box-shadow: inset var(--zd-btn--danger-box-shadow);
}

.c-btn--primary.c-btn--focus-inset.focus-visible,.c-btn--primary.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#fff alpha(35%));
  box-shadow: inset 0 0 0 3px color-mod(var(--zd-color-white) alpha(35%));
}

.c-btn--primary.c-btn--focus-inset.focus-visible,.c-btn--primary.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#fff alpha(35%));
  box-shadow: inset 0 0 0 3px color-mod(var(--zd-color-white) alpha(35%));
}

.c-btn--primary.c-btn--focus-inset:focus-visible,.c-btn--primary.c-btn--focus-inset.is-focused {
  box-shadow: inset 0 0 0 3px color-mod(#fff alpha(35%));
  box-shadow: inset 0 0 0 3px color-mod(var(--zd-color-white) alpha(35%));
}

.c-btn--anchor.c-btn--anchor.focus-visible,.c-btn--anchor.c-btn--anchor.is-focused {
  box-shadow: none;
}

.c-btn--anchor.c-btn--anchor:focus-visible,.c-btn--anchor.c-btn--anchor.is-focused {
  box-shadow: none;
}

.c-btn:active,.c-btn.is-active {
  border-color: #0f3554;
  border-color: var(--zd-btn-color_active);
  background-color: color-mod(#1f73b7 alpha(20%));
  background-color: color-mod(var(--zd-btn-color) alpha(20%));
  color: #0f3554;
  color: var(--zd-btn-color_active);
}

.c-btn--danger:active,.c-btn--danger.is-active {
  border-color: #681219;
  border-color: var(--zd-btn--danger-color_active);
  background-color: color-mod(#cc3340 alpha(20%));
  background-color: color-mod(var(--zd-btn--danger-color) alpha(20%));
  color: #681219;
  color: var(--zd-btn--danger-color_active);
}

.c-btn--primary:active,.c-btn--primary.is-active {
  border-color: transparent;
  background-color: #0f3554;
  background-color: var(--zd-btn-color_active);
  color: #fff;
  color: var(--zd-color-white);
}

.c-btn--primary.c-btn--danger:active,.c-btn--primary.c-btn--danger.is-active {
  background-color: #681219;
  background-color: var(--zd-btn--danger-color_active);
}

.c-btn--basic:active,.c-btn--basic.is-active {
  border-color: transparent;
}

.c-btn--basic.c-btn--icon:active,.c-btn--basic.c-btn--icon.is-active {
  color: #2f3941;
  color: var(--zd-color-grey-800);
}

.c-btn--anchor:active,.c-btn--anchor.is-active {
  border-color: none;
  background-color: transparent;
}

.c-btn:disabled,.c-btn.is-disabled {
  border-color: transparent;
  background-color: #e9ebed;
  background-color: var(--zd-color-grey-200);
  color: #c2c8cc;
  color: var(--zd-color-grey-400);
}

.c-btn--anchor:disabled,.c-btn--anchor.is-disabled {
  background-color: transparent;
}

.c-btn__icon {
  /* prettier-ignore */
  transition: -webkit-transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.c-btn--lg .c-btn__icon {
  width: 24px;
  height: 24px;
}

.c-btn__icon.is-rotated {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.is-rtl .c-btn__icon.is-rotated {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
              -ms-user-select: none;
          user-select: none;  
}

.react-grid-item.cssTransforms {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

@media (min-width: 1740px) {
  .container {
    max-width: 1640px !important;
  }
}

a:visited {
  color: inherit;
}

a:link {
  color: inherit;
}

a {
  text-decoration: none;
}

.dividing {
  border-bottom: 1px solid rgba(34, 36, 38, 0.50);
}

.dividing-2px {
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
}

.pl-0 {
  padding-left: 0px !important;
}

body {
  height: 100vh;
}

#root, #root>div {
  -webkit-transform-origin: left top;
          transform-origin: left top;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  position: relative;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: auto;
}

nav {
  position: relative;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: auto;
}

main {
  position: relative;
  flex: 1 1 0;
  order: 2;
}

footer {
  position: relative;
  box-sizing: border-box;
  flex: none;
  order: 3;
  width: 100%;
}

/* Switch do zendesk */

.jlzbex:checked~.sc-kDThTU::before {
  border-color: #DE701D !important;
  background-color: rgba(222,112,29,0.92941) !important;
}

.u-link {
  text-decoration: underline;
  cursor: pointer;
}

.u-link:hover {
  color: lightgray !important;
}

.u-link.orange {
  color: #DE701D
}

.DraftEditor-editorContainer {
  z-index: 0;
}

svg {
  display: inline !important;
  display: initial !important;
}