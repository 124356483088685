.opp-card-body {
    background-color: #F8F9F9;
    min-height: 120px;
    width: 100%;
    margin-bottom: 11px;
    padding: 15px 0px 0px 15px;
    cursor: pointer;
}

.opp-card-body.grey-background {
    background-color: #c6cad0;
}

.img-fade-in-0 .img-fade-in-1, .img-fade-in-2, .img-fade-in-3{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.img-fade-in-0 {
    transform: translateX(-100%);
    animation: pulse-right-to-left 500ms cubic-bezier(.58, .57, .76, 1.18) forwards;
}
.img-fade-in-1 {
    transform: translateX(-100%);
    animation: pulse-right-to-left 500ms cubic-bezier(.58, .57, .76, 1.18) 200ms forwards;
}
.img-fade-in-2 {
    transform: translateX(-100%);
    animation: pulse-right-to-left 500ms cubic-bezier(.58, .57, .76, 1.18) 400ms forwards;
}
.img-fade-in-3 {
    transform: translateX(-100%);
    animation: pulse-right-to-left 500ms cubic-bezier(.58, .57, .76, 1.18) 600ms forwards;
}

@keyframes pulse-right-to-left {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.tag-container {
    display: flex;
    min-width: 45px;
    height: 100%;
    margin-top: -15px;
}

.tag-content-position {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-height: 120px;
}

.tag-content-position.orange {
    background-color: #ED961C;
}

.tag-content-position.gray {
    background-color: #757f8e;
}

.tag-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.truncateText {
    flex:1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
