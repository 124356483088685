.publication-header {
  background-color: #e74c3c;
}

.published-header {
  background-color: #f39c12;
}

.recruitment-header {
  background-color: #f1c40f;
}

.evaluation-header {
  background-color: #9b59b6;
}

.selection-header {
  background-color: #3498db;
}

.interview-header {
  background-color: #2ecc71;
}

.closed-header {
  background-color: #1abc9c;
}

.victories-header {
  background-color: #1abc9c;
}

.defeats-header {
  background-color: rgba(65, 65, 65, 0.897);
}
