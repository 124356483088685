/* Shark Attack */
.background-color-5e0655001c9d44000033088d {
  background-color: #3498db;
}

/* Caravela Portuguesa */
.background-color-5e0655091c9d44000033088e {
  background-color: #1abc9c;
}

/* The Black Dogs */
.background-color-5e0655e21c9d44000033088f {
  background-color: #e74c3c;
}

/* Golden Falcon */
.background-color-5e0655ea1c9d440000330890 {
  background-color: #f39c12;
}

.background-label-default {
  background-color: #4f6061;
}

.background-label-victories {
  margin-top: 6px;
  width: 36px;
  text-align: center;
  background: #4dd386 0% 0% no-repeat padding-box;
  border-radius: 4px;
}
.background-label-defeats {
  margin-top: 6px;
  width: 36px;
  text-align: center;
  background: #ea6658 0% 0% no-repeat padding-box;
  border-radius: 4px;
}
.background-label-victories > img,
.background-label-victories > span {
  margin-bottom: 4px;
}

.card {
  min-height: 130px;
}

.label-fs {
  margin-right: 8px;
  font-size: 8px !important;
  color: #fff !important;
  padding: 3px;
  border-radius: 2px;
}

.inactive {
  background-color: #dde0e2 !important;
}

.clock-card-kanbon {
  color: #897b7b;
  height: 14px;
}

.header-victory-defeat-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
}

.victory-defeat-container {
  display: flex;
  flex-direction: column;
}

.defeat-date {
  color: #ea6658;
  font-weight: 600;
  font-size: 0.6rem;
}

.victory-date {
  color: #4dd386;
  font-weight: 600;
  font-size: 0.6rem;
}
