.report-evaluation-header {
    border: solid 1px #ea951d;
    border-radius: 5px;
    padding-left: 40%;
    margin-bottom: 0.5rem;
}

.report-evaluation-header-label {
    color: #ea951d !important;
}

.button-evaluation-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: solid 1px #ea951d;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
}

.row-deleted-profile-modal {
    border: solid 1px grey;
    margin-bottom: 1%;
    border-radius: 5px;
    padding: 5px 2px 2px 2px;
    box-shadow: 2px 2px 5px grey;
}

.initial-state-deleted-profile {
    width: 100%;
    border: solid 1px grey;
    margin-bottom: 1%;
    border-radius: 5px;
    padding: 5px 2px 2px 2px;
    box-shadow: 2px 2px 5px grey;
    text-align: center;
}

.button-build-report {
    width: 100%;
    background-color: transparent;
    border: none;
}

.button-build-report-deleted-perfil {
    background-color: #ea951d;
    color: white;
    width: 100%;
    border: solid 1px #ea951d;
    border-radius: 8px;
    margin: 12px 0;
    padding: 8px 0;
    box-shadow: 1px 2px 3px 3px #b4b0b0fb;
}

.button-build-report-deleted-perfil-container {
    margin: 0 auto;
    width: 48.5%;
}

.container-date-range {
    width: 70%;
    background-color: white;
    border-radius: 5px;
    border: solid 1px white;
    box-shadow: 1px 2px 3px 3px #7f8081a2;
    margin: 0 auto;
    padding: 1rem;
}

.container-date-range-deleted-perfil {
    margin: 0 auto;
    width: 48%;
    background-color: white;
    border-radius: 5px;
    border: solid 1px white;
    box-shadow: 1px 2px 3px 3px #7f8081a2;
    margin-top: 1rem;
    padding: 1rem;
} 

.container-content-report {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.close-button-date-range {
    background-color: transparent;
    border: none;
    color: #7e7b7b;
    margin-left: 90%;
  }

.column-report-evaluation-body {
    border: solid 1px #7e7b7b;
    opacity: 0.5;
    margin: 0 1px 1px 0;
}

.column-report-evaluation-header {
    font-weight: 600;
    border: solid 1px #7e7b7b;
    margin: 0 1px 1px 0;
    opacity: 0.5;
}

.modal-header-container {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    margin: 0 auto;
    color: #7e7b7b;
    border: none;
    background-color: transparent,
}

.model-body-text {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    margin: 0 auto;
    color: #7e7b7b;
}

.report-list-style {
    background-color: transparent;
    color: #7e7b7b;
    font-style: italic;
    border: none;
}

.responsible-dropdown-button-report {
    width: 100% !important;
    border: solid 1px blue;
}

@media (max-width: 768px) {
    .container-content-report {
        flex-direction: column;
    }
    .button-date-range, .button-build-report{
        width: 100%;
    }
    .button-build-report {
        margin-top: 2px !important;
    }
    .container-date-range {
        width: 18rem;
    }
    .close-button-date-range {
        margin-left: 80%;
    }
    .report-evaluation-header {
        padding-left: 22%;
    }
}

@media (max-width: 992px) {
    .container-date-range-deleted-perfil {
        width: 100%;
    }
    .button-build-report-deleted-perfil-container {
        width: 100%;
    }
}
