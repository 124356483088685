.main-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.contactus-header p {
  text-align: left;
  font: normal normal 600 20px/27px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #2e3f51;
  opacity: 1;
}

.btn-enviar {
  outline: none;
  cursor: pointer;
  text-align: center;
  width: 150px;
  height: 41px;
  border-radius: 40px;
  background: #de701d 0% 0% no-repeat padding-box;
  border: 1px solid #c77332;
  color: green;
  letter-spacing: 1px;
  text-shadow: 0;
  text-align: center;
  font: normal normal 600 20px/27px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.btn-enviar:disabled,
.btn-enviar[disabled] {
  border: none;
  background-color: #cccccc;
  color: #666666;
}

.btn-enviar:hover:enabled {
  background-color: #cd6a20;
}
