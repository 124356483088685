$spinner-radius: 35px;
$spinner-height: $spinner-radius * 3;
$quiet: #fff;
$g09: #fff;

.circle-progress {
  display: inline-block;
  position: relative;
  width: $spinner-height;
  height: $spinner-height;
  &:after {
    content: attr(data-pct) "%";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $spinner-height;
    text-align: center;
    color: $quiet;
    font-size: 16px;
    font-weight: 500;
  }
  .circle-progress-content {
    transform: rotate(-90deg);
    .bar {
      stroke: #37b8af;
      transition: stroke-dashoffset 0.1s ease-out;
    }
    .bg {
      stroke: $g09;
    }
  }
}
