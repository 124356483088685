.body-style {
  border: solid 1px white;
  border-radius: 4px;
  box-shadow: 1px 0px 8px #00000029;
  height: 100%;
  width: 100%;
}

.title-name-flip-card {
  text-align: left;
  font: normal normal 600 16px/19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
  letter-spacing: 0px;
  color: #354A5F;
  opacity: 1;
}

.percentage-style {
  text-align: left;
  font: normal normal 600 16px/19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
  letter-spacing: 0px;
  color: #354A5F;
  opacity: 1;
}

.section-title-flip-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  font: normal normal 600 12px/14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
  letter-spacing: 0px;
  color: #354A5F;
  margin: 0 25px 0 25px;
}

.bottom-line-flip-card {
  margin: 4px 0 4px 0;
  border-bottom: 1px solid #707070;
  opacity: 0.4;
}

.content-field-flip-card {
  text-align: left;
  font: normal normal normal 12px/22px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
  letter-spacing: 0px;
  color: #354A5F;
  opacity: 1;
}

.main-content-flip-card {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-right: 5px;
  height: 80%;
}

.main-content-flip-card::-webkit-scrollbar {
  width: 6px;
}

.main-content-flip-card::-webkit-scrollbar-track {
  background: #D6D6D6;
  border-radius: 20px;
}

.main-content-flip-card::-webkit-scrollbar-thumb {
  background-color: #7B8896;
  border-radius: 20px;
  border: 1px solid #D6D6D6;
}