.hLgJNz {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.section-title {
    background-color: #354A5F;
    border: solid 1px #354A5F;
    border-radius: 4px;
    color: white;
    font-weight: 400;
    text-align: left;
    width: 100%;
}

.country-title {
    font-size: 18px;
    margin: 10px;
    color: white;
    padding: 5px 20px;
    border-radius: 4px;
}

.compatible-opportunities {
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
    padding: 5px 20px;
    border-radius: 4px;
    border: solid 1px #ED961C;
    background-color: #ED961C;
    width: 100%;
}

.country-title.brazil {
    background-color: #37B8AF;
}

.country-title.europe {
    background-color: #EB4962;
}

.country-title.usa {
    background-color: #ED961C;
}

.country-title-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 17px;

}

.body-opp-compatible {
    width: 50%;
}

.container-opp-compatible {
    display: flex;
}

@media (max-width: 991px) {
    .body-opp-compatible {
        width: 100%;
    }
    
    .container-opp-compatible {
        flex-direction: column;
    }
}
