.wrapper-editor-text {
  border: 1px solid #8e8e8e !important;
  border-radius: 5px;
  margin: 8px 0 15px;
  background-color: #fff;
}

.container-event-box {
  background-color: #354a5f;
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 2rem;
}

.container-buttons-editor {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  padding: 4px;
}

.buttons-editor {
  width: 35%;
  font-weight: 300;
  border-radius: 8px;
  padding: 0.5rem 0;
}

.cancel-color {
  background-color: transparent;
  border: solid 1px var(--zd-color-talk-yellow) !important;
  color: var(--zd-color-talk-yellow);
}

.save-color {
  background-color: var(--zd-color-talk-yellow);
  border: solid 1px var(--zd-color-talk-yellow);
  color: #fff;
}