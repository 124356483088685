.opp-title {
    font-size: 20px;
    margin-top: 0px;
    padding-top: 0px !important;
    font: normal normal 600 20px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    letter-spacing: 0px;
    color: #49545c;
    cursor: pointer;
    width: 100%;
    >a:hover{
        text-decoration: underline;
        color: #49545c;
    }
}

.opp-information {
    display: flex;
    width: 75%;
    justify-content: space-between;
    align-items: flex-end;
    color: #49545c;
    >p:last-child{
        font-size: 0.75rem;
        font-weight: 650;
    }
}

.button-container {
    text-align: left  !important;
    width: 100% !important;
    font-size: 14px  !important;
    padding: 18px 29px  !important;
    box-shadow: 2px 2px 4px #00000029;
    border: none  !important;
    border-radius: 4px;
    margin-bottom: 2px;
    &:hover{
        background-color: #ed961c !important;
    }
}

.petro {
    background-color: #687787 !important;
}

.petro.active {
    background-color: #354a5f !important;
}

.transp {
    background-color: transparent !important;
    color: #354a5f !important;
    border: none !important;
    font-weight: 500 !important;
    box-shadow: none !important;
    &:hover {
        transition: 0.3s ease-in-out;
        color:white !important;
    }
}

.input-search-compatible>div {
    height: 30px;
  }
  
.input-search-compatible>div> ::first-line {
    font-size: 13px;
    color: #DBA44D;
}

.button-find-compatible {
    background-color: #ed961c;
    border: none;
    border-radius: 15px;
    padding: 0 10px 0 10px;
    color: white;
}
.logo-img {
    background: transparent url('../../../images/enter-loading.png') 0% 0% no-repeat padding-box;
    width: 4rem;
    height: 4rem;
}

@media (max-width: 991px){
    .opp-information {
        width: 100%;
    }
    .button-cards-container {
        display: flex;
        flex-direction: column-reverse;
    }
}