.card-background {
  background-color: #6c6c6c;
  margin: 0px;
  padding: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.3;
  transition: opacity 0.3s linear;
}
