.avatar-card {
    margin: 0 auto;
}

.truncate {
    width: 20ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-container {
    display: flex;
    align-items: center;
    margin: 20px;
    // height: 100px;
    justify-content: right;
}

.recuiter-field {
    font-weight: 400;
}

.recuiter-field> img{
    width: 25px ;
    height: 25px;
    border-radius: 50%;
}
.file-field{
    display: flex;    
    font-weight: 400;
}

.automatic-event-style {
    display: inline-block !important;
    background-color: #354a5f !important;
    color: #fff;
    border-radius: 5px;
    padding: 4px;
    margin-bottom: 3px;
    text-align: right !important;
}

.automatic-event-style::after {
    content: " (ENTER)";
    color: #ea951d;
}


.img-field {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 20px;
    padding: 5px;
    box-sizing: inherit;
}

.no-feed {
    margin-top: calc(300px / 2);
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    p {
        font-weight: 500;
        color: #354a5f;
    }
    box-shadow: 1px 1px 8px #354a5f;
}

.feedField {
    position: absolute;
    height: 400px;
    width: 100%;
    margin-left: 0 !important;
    background-color: #fff;
    border-radius: 20px;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 1px 1px 6px #354a5f;
}

.feedField-scroll {
    //width: 100% !important;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
    
}

.img-fade-in {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.img-fade-in {
    transform: translateX(-100%);
    animation: pulse-right-to-left 500ms cubic-bezier(.58, .57, .76, 1.18) forwards;
}


.feed-content {
    transform: translateY(-100%);
    animation: pulse-to-bottom 1300ms cubic-bezier(.58, .57, .76, 1.18) forwards;
    direction: ltr;
}

.add-event-button {
    background-color: transparent;
    border: none;
    width: 100%;
    animation: wait-to-show 2300ms normal;
    color: #354a5f;
    font-weight: 500;
    padding-bottom: 5px;
}

.read-more-btn {
    cursor: pointer;
    background-color: transparent;
    color: #354a5f;
    border: none;
    font-size: smaller;
}

.read-more-btn:focus {
    outline: none;
}

@keyframes wait-to-show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulse-to-bottom {
    0% {
        transition: 0.8s;
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transition: 0.8s;
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes pulse-right-to-left {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@media (max-width:768px) {
    .text-container {
        flex-direction: column;
    }
}