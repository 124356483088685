/*--------------
    Comments
---------------*/

.ui.comments {
  margin: 1.5em 0em;
  max-width: 650px;
}

.ui.comments:first-child {
  margin-top: 0em;
}

.ui.comments:last-child {
  margin-bottom: 0em;
}

/*--------------
       Comment
  ---------------*/

.ui.comments .comment:first-child {
  margin-top: 0em;
  padding-top: 0em;
}

/*--------------
       Author
  ---------------*/

.ui.comments .comment .author {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}

.ui.comments .comment a.author {
  cursor: pointer;
}

.ui.comments .comment a.author:hover {
  color: #1e70bf;
}

/*--------------
       Metadata
  ---------------*/

.ui.comments .comment .metadata {
  display: inline-block;
  margin-left: 0.5em;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.875em;
}

.ui.comments .comment .metadata > * {
  display: inline-block;
  margin: 0em 0.5em 0em 0em;
}

.ui.comments .comment .metadata > :last-child {
  margin-right: 0em;
}

/*--------------
    Comments
---------------*/

.ui.comments {
  margin: 1.5em 0em;
  max-width: unset;
}

.ui.comments:first-child {
  margin-top: 0em;
}

.ui.comments:last-child {
  margin-bottom: 0em;
}

/*--------------
       Comment
  ---------------*/

.ui.comments .comment {
  position: relative;
  background: none;
  margin: 0.5em 0em 0em;
  padding: 0.5em 0em 0em;
  border: none;
  border-top: none;
}

/*--------------------
      Nested Comments
  ---------------------*/

.ui.comments .comment .comments {
  margin: 0em 0em 0.5em 0.5em;
  padding: 1em 0em 1em 1em;
}

.ui.comments .comment .comments:before {
  position: absolute;
  top: 0px;
  left: 0px;
}

.ui.comments .comment .comments .comment {
  border: none;
  border-top: none;
  background: none;
}

/*--------------
       Avatar
  ---------------*/

.ui.comments .comment .avatar {
  display: block;
  float: left;
  margin: 0.2em 0.5em 0em 0em;
}

.ui.comments .comment img.avatar,
.ui.comments .comment .avatar img {
  display: block;
  margin: 0em auto;
}

/*--------------
       Content
  ---------------*/

.ui.comments .comment > .content {
  display: block;
}

/* If there is an avatar move content over */

.ui.comments .comment > .avatar ~ .content {
  margin-left: 3.5em;
}

/*--------------
       Author
  ---------------*/

.ui.comments .comment .author {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}

.ui.comments .comment a.author {
  cursor: pointer;
}

.ui.comments .comment a.author:hover {
  color: #1e70bf;
}

/*--------------
       Metadata
  ---------------*/

.ui.comments .comment .metadata {
  display: inline-block;
  margin-left: 0.5em;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.875em;
  margin-bottom: -10px;
}

.ui.comments .comment .metadata > * {
  display: inline-block;
  margin: 0em 0.5em 0em 0em;
}

.ui.comments .comment .metadata > :last-child {
  margin-right: 0em;
}

/*--------------------
       Comment Text
  ---------------------*/

.ui.comments .comment .text {
  margin: 0em 0.2em 0.5em;
  font-size: 1em;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.3;
}

/*--------------------
       User Actions
  ---------------------*/

.ui.comments .comment .actions {
  font-size: 0.875em;
}

.ui.comments .comment .actions a {
  cursor: pointer;
  display: inline-block;
  margin: 0em 0.75em 0em 0em;
  color: rgba(0, 0, 0, 0.4);
}

.ui.comments .comment .actions a:last-child {
  margin-right: 0em;
}

.ui.comments .comment .actions a.active,
.ui.comments .comment .actions a:hover {
  color: rgba(0, 0, 0, 0.8);
}

/*--------------------
        Reply Form
  ---------------------*/

.ui.comments > .reply.form {
  margin-top: 1em;
}

.ui.comments .comment .reply.form {
  width: 100%;
  margin-top: 1em;
}

.ui.comments .textarea {
  height: 7em;
  font-size: 1em;
}