.welcome {
  border: 1px solid #ed961c;
  border-radius: 4px;
  font-size: 16px;
  color: #ed961c;
  text-align: center;
  padding: 20px;
  margin-bottom: 10px;
}

[role="tablist"] {
  margin-bottom: 0 !important;
  border-bottom: none !important;
}

.tab-profile[aria-selected="true"] {
  background-color: #354a5f !important;
  span {
    color: white !important;
  }
  color: #354a5f !important;
  border-radius: 8px 8px 0 0;
}

.tab-profile {
  color: #354a5f;
  padding: 35px 25px 25px !important;
}

.tab-body {
  background-color: #354a5f;
  color: white;
  padding: 30px;
  border-radius: 0 0 8px 8px !important;
}

.field-professional-profile {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  font-family: inherit;
  line-height: 1.2857142857142858;
  font-size: 14px;
  width: 100%;
  div,
  div:hover,
  div:focus {
    border: 0px !important;
    border-color: none;
    box-shadow: none;
  }
}

.field-professional-location {
  background-color: white;
  color: #354a5f;
  border-radius: 5px;
  padding: 10px;
  font-family: inherit;
  line-height: 1.2857142857142858;
  font-size: 14px;
  width: 100%;
  span {
    background-color: white;
  }
  div,
  div:hover,
  div:focus {
    border: 0px !important;
    border-color: none;
    box-shadow: none;
  }
}

.home-office {
  color: #707070 !important;
  font: normal normal 600 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !important;
}

.home-office.active {
  color: #ed961c !important;
}

.no-focus:focus {
  box-shadow: none !important;
}

.skill-input {
  font-family: inherit;
  line-height: 1.2857142857142858;
  font-size: 14px;
}

.next-step,
.cancel-delete-user {
  padding: 5px 50px !important;
  border-radius: 25px !important;
  background-color: #ea951d !important;
  border: unset !important;
}

.delete-user {
  padding: 5px 50px !important;
  border-radius: 25px !important;
  background-color: white !important;
  border: 2px solid #ea951d !important;
  color: #ea951d !important;
}

.title-mosaic-detail {
  color: #354a5f;
  padding: 5px 25px 0 10px;
}

.background-stripped {
  width: 500px;
  height: 500px;
  opacity: 0.1;
  background: repeating-linear-gradient(45deg, transparent, transparent 10px, #ed961c 10px, #ed961c 20px),
    linear-gradient(to bottom, #fff, #fff);
}

.filestack-upload-button {
  background-color: white;
  color: black;
  &:hover {
    background-color: #ea951d;
  }
}

.title-professional-profile {
  border-bottom: 1px solid #707070;
  padding-bottom: 5px;
  color: #49545c;
}
.info-professional-profile {
  color: #49545c;
}

.add-attachment {
  background-color: transparent;
  color: black;
  border: none !important;
}

.label-select-prof-profile {
  position: absolute;
  top: 4px;
  left: 11px;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !important;
  padding-top: 2px;
  color: #707070 !important;
}

.select-button-prof-profile,
.select-button-prof-profile > div {
  height: 3rem !important;
  border-radius: 5px;
  color: #707070 !important;
  width: 100%;
}

.select-button-prof-profile:first-child,
.select-button-prof-profile:nth-child(3) {
  margin-right: 4px;
}

.coin-container-pro-profile, .checkbox-button-style {
  display: flex;
}

.checkbox-button-style>div>label {
  padding-right: 8px;
}

.title-salary-cap {
  margin-bottom: 0.6rem;
  margin-left: 0.3rem;
  color: #707070 !important;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !important;
}

.text-info-tab2 {
  font-size: 0.8rem;
}

.text-info-tab3 {
  font-size: 0.9rem;
}

.cv-prof-profile-container {
  text-overflow: ellipsis;
  white-space: normal; 
  overflow: hidden ;
}

.location-label {
  width: 100%;
}

.price-field {
  width: 50%;
}

.coin-field {
  width: 50%;
  margin-right: 0.5rem;
}

.container-professional-info {
  display: flex;
  justify-content: space-between;
}

.salary-expectation-style {
  background-color: transparent;
  border: none;
  color: #707070;
  text-align: left;
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
}

.tab1-close-modal-pretensao {
  background-color: transparent;
  border: none;
  color: #8F969B;
  font-size: 1.5rem;
  padding: 0;
}

.update-role-container {
  border: solid 1px #354a5f;
  color: #354a5f;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  padding: 1%;
  margin-bottom: 2%;
}

.update-role-body {
  border-radius: 15px;
  background-color: #354a5f;
  color: white;
  margin: 0 1%;
  padding: 2px 1%;
}

.update-role-section {
  font-weight: 500 !important;
  display: flex;
  margin: 1%;
}

@media (max-width: 991px) {
  .checkbox-button-style {
    justify-content: space-around;
  }

  .container-professional-info {
    flex-direction: column;
  }
  
  .price-field {
    width: 100%;
  }

  .coin-field {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .select-button-prof-profile,
  .select-button-prof-profile > div {
    width: 100% !important;
  }

  .salary-expectation-style {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
}

.professional-profile-info-title {
  font: normal normal 600 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #8F969B;
}

.professional-profile-info-content {
  font: normal normal normal 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #8F969B;
  opacity: 1;
}

@media (max-width: 393px) {
  .coin-container-pro-profile {
    flex-direction: column;
  }
}

@media (max-width: 380px) {
  .checkbox-button-style {
    flex-direction: column;
  }
}
