/* Shark Attack */
.background-color-620e5be4241b6ee8e6382719 {
  background-color: #3498db;
}

/* Caravela Portuguesa */
.background-color-620e5be4241b6ee8e638271a {
  background-color: #1abc9c;
}

/* The Black Dogs */
.background-color-620e5be4241b6ee8e638271b {
  background-color: #e74c3c;
}

.background-label-default {
  background-color: #4f6061;
}

.background-label-victories {
  margin-top: 6px;
  width: 36px;
  text-align: center;
  background: #4dd386 0% 0% no-repeat padding-box;
  border-radius: 4px;
}
.background-label-defeats {
  margin-top: 6px;
  width: 36px;
  text-align: center;
  background: #ea6658 0% 0% no-repeat padding-box;
  border-radius: 4px;
}
.background-label-victories > img,
.background-label-victories > span {
  margin-bottom: 4px;
}

.card {
  min-height: 130px;
}

.label-fs {
  margin-right: 8px;
  font-size: 8px !important;
  color: #fff !important;
  padding: 3px;
  border-radius: 2px;
}

.hunted-days{
  background-color: #4f6061;
  font-size: 10px;
  font-weight: 400;
  color: white;
  padding: 4px 7px;
  border-radius: 15px;
  white-space: nowrap;
}

.chip-feature{
  background-color: #f39c12;
  font-size: 14px;
  font-weight: 400;
  color: white;
  padding: 7px 10px;
  border-radius: 15px;
  margin: 8px 8px 15px 8px;
  width: auto;
}