.user-name {
    color: #49545c;
    font: normal normal 600 19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    margin-top: 9px;
    margin-bottom: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 48px;
}

.user-info {
    color: #7b8896;
    font: normal normal normal 15px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    margin-top: 3px;
    margin-bottom: 3px;
}

pre.user-info {
    height: 70px;
}

.user-avatar {
    border-radius: 5px;
    width: 100%;
    position: absolute;
}

.user-props, .user-props-pre-button {
    color: #7b8896;
    font-weight: 700;
    font-size: 14px;
    width: 15%;
    padding-left: 10px;
    padding-top: 10px;
    text-align: center;
}

.user-props-pre-button {
    background-color: transparent;
    border: none;
}

.avatar-grid {
    position: relative;
    width: 85%;
    height: 0;
    padding-bottom: 85%;
}

.level {
    position: absolute;
    z-index: 10;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.226);
    width: 100%;
    bottom: 0;
    padding: 5px;
    font-weight: 500;
}

.button-flip-card {
    width: 100%;
    border: none;
    background-color: transparent;
}

.edit-pre-register-button {
    border: solid 1px #ED961C;
    border-radius: 5px;
    background-color: transparent;
    color: #ED961C;
    margin-right: 5%;
}

.eluminer-info {
    position: absolute;
    transform: translateY(-100%);
    color: #ed961c;
    font: 600 15px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}