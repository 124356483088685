/* No Squad */
.border-left- {
  border-left: 0.25rem solid #ababab !important;
}

.squad-color- {
  color: #ababab;
}

.squad-background- {
  background-color: #ababab;
}

/* Shark Attack */
.border-left-620e5be4241b6ee8e6382719 {
  border-left: 0.25rem solid #3498db !important;
}

.squad-color-620e5be4241b6ee8e6382719 {
  color: #3498db;
}

.squad-background-620e5be4241b6ee8e6382719 {
  background-color: #3498db;
}

/* Caravela Portuguesa */
.border-left-620e5be4241b6ee8e638271a {
  border-left: 0.25rem solid #1abc9c !important;
}

.squad-color-620e5be4241b6ee8e638271a {
  color: #1abc9c;
}

.squad-background-620e5be4241b6ee8e638271a {
  background-color: #1abc9c;
}

/* The Black Dogs */
.border-left-620e5be4241b6ee8e638271b {
  border-left: 0.25rem solid #e74c3c !important;
}

.squad-color-620e5be4241b6ee8e638271b {
  color: #e74c3c;
}

.squad-background-620e5be4241b6ee8e638271b {
  background-color: #e74c3c;
}


.candidates-counter {
  height: 120px;
  background: #f5f5f5;
  border-radius: 4px;
  color: #4f6061;
}

.hb-divider {
  height: 20px;
  border-bottom: 1px #b5b5b5 solid !important;
}

.candidates-link a {
  color: #4f6061;
}

.title-qty-opp {
  border-radius: 4px;
  opacity: 1;
  height: 51px;
  color: #fff;
  vertical-align: middle;
}

.input-select-qty {
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #fff;
  width: 40px;
  color: #000;
  padding-left: 5px;
}

/* Borda do dropdown */
.icyLxM {
  padding: 0.9em !important;
}

.chip-feature-major {
  background-color: #f39c12;
  font-size: 16px !important;
  font-weight: 400;
  color: white;
  padding: 7px 10px;
  border-radius: 18px;
  width: auto;
  white-space: nowrap;
}

.compatibles-labels {
  font-size: 14px;
  color: #49545C;
  height: auto;
}

.talent-name {
  font-size: 24px !important;
}

.more-opps {
  font-size: 16px;
  color: #707070;
  text-decoration: underline;
}

.current-talent-opps {
  color: #707070;
  font-size: 20px;
}

.talent-stepper {
  color: #CBCBCB;
  font-size: 24px;
  margin-top: 5px;
}
.talent-stepper.current {
  color: #856BF2;
}

.talent-stepper.defeat {
  color: rgb(211, 17, 17);
}

.talent-stepper.victory {
  color: rgb(46, 231, 46);
}

.hunted-days{
  background-color: #4f6061;
  font-size: 10px;
  font-weight: 400;
  color: white;
  padding: 4px 7px;
  border-radius: 15px;
  white-space: nowrap;
}

.parecer-comportamental-header {
  background-color: #EFF2F2;
  border-radius: 14px;
  color: #354A5F;
  font: normal normal 600 13px/17px !important;
  margin-bottom: 1%;
}

.talent-salary-expectation-style {
  background-color: transparent;
  border: none;
  color: #707070;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  padding: 0.3rem;
  text-align: left;
}

.talent-professional-profile-info-title {
  font: normal normal 600 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #8F969B;
}

.talent-professional-profile-info-content {
  font: normal normal normal 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #8F969B;
  opacity: 1;
}

.talent-coin-container-pro-profile {
  display: flex;
}

.talent-coin-field {
  width: 50%;
  margin-right: 0.5rem;
}

.talent-price-field {
  width: 50%;
}

.talent-select-button-prof-profile,
.talent-select-button-prof-profile > div {
  height: 3rem !important;
  border-radius: 5px;
  color: #707070 !important;
  width: 100%;
}

.talent-label-select-prof-profile {
  position: absolute;
  top: 4px;
  left: 11px;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !important;
  padding-top: 2px;
  color: #707070 !important;
}

.alert-message-group {
  position: absolute;
  width: 14rem;
}

@media (max-width: 1200px) {
  .alert-message-group {
    width: 10rem;
  }
}

@media (max-width: 768px) {
  .alert-message-group {
    width: 14rem;
  }
}
