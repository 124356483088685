.parameters {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 20px #00000029;
    border-radius: 4px;
    opacity: 1;
}

.necessary-parameters {
    margin-bottom: 3rem;
}

.candidate-info {
    background: #354A5F 0% 0% no-repeat padding-box;
}

.header-parameters {
    margin-bottom: 3rem;
}

.header-info {
    box-shadow: 0px 1px 20px #00000029;
    border-radius: 4px;
}   

.parameter {
    margin-bottom: 3rem;
}

.curriculum {
    color: white;
    border-color: white !important;
    border-style: dashed !important;
    padding: .5rem 1rem !important;
    border-width: 2px !important;
    border-spacing: 15cm !important;
}

.percentage {
    padding: 1rem 0 !important;
}

.icon-tooltip {
    width: 20px;
    height: 20px;
}

.button-orange {
    outline: none;
    border: none !important;
    padding: .5rem 3rem !important;
    border-radius: 24px !important;
    background-color: #ed961c !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
    display: none !important;
}

.bg-notification {
    background: rgba(170, 255, 215, 0.1)
}

.text-underline {
    cursor: pointer;
    text-decoration: underline;
    color: #696969;
}

.classeQlq {
    background-image: url(../../../images/icons/ic_file_upload_24px.svg);
}

.add-cv-candidatures-details {
    background-color: transparent;
    border: none;
    color: white;
    font-weight: 700;
  }