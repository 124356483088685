.save-button {
    background-color: #DE701D;
    color: white;
    border-radius: 20px;
    border: none;
    width: 100%;
    padding: 8% 0;
    font: normal normal 600 0.8rem/1rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
  
  .modal-save-button {
    background-color: #DE701D;
    color: white;
    border-radius: 25px;
    border: none;
    width: 83%;
    padding: 3% 0;
    font: normal normal 600 14px/21px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
  
  .modal-footer {
    color: #707070;
    font: normal normal normal 14px/19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
  
  .modal-title {
    font: normal normal 600 24px/32px Segoe UI;
    color: #616161;
  }
  
  .modal-footer a:link{
    font-weight: 600;
  }
  
  .pi-container {
    width: 82%;
    margin: 0 auto;
  }
  
  .text-label {
    text-align: left;
    font: normal normal 600 14px/19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    letter-spacing: 0px;
    color: #354A5F;
    opacity: 1;
  }
  
  .title-name {
    color: #354A5F;
    font: Semibold 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
  
  .main-title {
    border-bottom: solid 1px #8695a5;
    font: normal normal 600 18px/24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    color: #354A5F;
    padding-bottom: 1%;
  }
  
  .terms-of-use {
    margin: 1% 0 10% 0;
    text-align: right;
    font: normal normal normal 14px/19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    letter-spacing: 0.1px;
    color: #707070;
  }
  
  .terms-of-use p a {
    color: #ED961C;
  }

 .privacy-policy a:hover {
    color: #ED961C;
 }
  
  .redefine-button {
    background-color: transparent;
    border: none;
    color: #354A5F;
    font: normal normal 600 13px/15px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    text-decoration: underline;
    text-align: end;
    width: 100%;
  }
  
  .pcd-button {
    opacity: 1;
    color: #707070;
    font: normal normal normal 15px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
  
  .phone-css input {
    border-color: rgb(214, 221, 223);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 2.45rem;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    transition-property: all;
  }
  
  .phone-css input:focus { 
    outline: none !important;
    border-color: #DE701D;
    box-shadow: 0 0 1px 2.4px#f5c39d;
  }
  
  .phone-css input:hover {
    border-color: #DE701D;
  }
  
  @media (max-width: 800px) {
    .pi-container-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .pi-container {
      margin: 0 auto;
      width: 90%;
    }
  
    .redefine-button {
      border: solid 1px #707070;
      border-radius: 20px;
      font: normal normal 600 14px/21px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
      padding: 2% 0 ;
      text-decoration: none;
      text-align: center;
    }
  
    .terms-of-use {
      text-align: center;
    }
  
    .modal-save-button {
      width: 100%;
    }
  
    .modal-footer {
      text-align: center;
      color: #707070;
      font: normal normal normal 13px/19px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    }

    .save-button {
      padding: 3% 0;
    }
  }
  