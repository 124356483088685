.bg-enter {
  background: #ED961C 0% 0% no-repeat padding-box;
  height: 100vh;
}

.enter-logo {
  margin: 30px 0 30px 0;
  width: 161px;
  height: 49px;
  background: transparent url('../../images/enter-logo.png') 0% 0% no-repeat padding-box;
  opacity: 1;
}

.bg-container {
  border-radius: 15px !important;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.great-title {
  margin-top: 80px;
  text-align: left;
  font: normal normal 600 24px/29px -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif;
  letter-spacing: 0px;
  color: #ED961C;
}
.title-color-mobile {
  color: white !important;
}

@media (min-width: 500px) {
  .bg-container {
    max-width: 480px;
  }
}

@media (min-width: 992px) {
  .bg-container {
    max-width: 480px;
  }
}

@media (max-width: 680px) {
  .bg-enter {
    height: calc(100vh - 110px);
  }
  .pcd-toggle {
    color: white !important;
    font-weight: 400 !important;
  }
}

/* Altura do input de localidade */

.css-yk16xz-control {
  min-height: 40px;
}

button[name=filestack] {
  border: 1px dashed #DBDBDB;
}

.mt-30 {
  margin-top: 150px;
}

.mt-20 {
  margin-top: 100px;
}

.cursor-pointer{
  cursor: pointer !important;
}