.column-body.waiting {
  background-color: rgba(189, 195, 199, 0.1);
}

.waiting-header {
  background-color: #707070;
  opacity: 0.6;
}

.column-body.participating {
  background-color: rgba(189, 195, 199, 0.2);
}

.participating-header {
  background-color: #707070;
  opacity: 0.8;
}

.column-body.finished {
  background-color: rgba(189, 195, 199, 0.3);
}

.finished-header {
  background-color: #707070;
}

.evaluation-header {
  background-color: #9b59b6;
}

.column-body.evaluation {
  background-color: rgba(189, 195, 199, 0.4);
}

.selection-header {
  background-color: #3498db;
}

.column-body.selection {
  background-color: rgba(189, 195, 199, 0.5);
}

.interview-header {
  background-color: #2ecc71;
}

.column-body.interview {
  background-color: rgba(189, 195, 199, 0.6);
}

.closed-header {
  background-color: #1abc9c;
}

.column-body.closed {
  background-color: rgba(189, 195, 199, 0.7);
}

.opportunitiesLength-header {
  padding: 4px !important;
  background-color: rgba(65, 65, 65, 0.897);
}

.column-body.opportunitiesLength {
  background-color: rgba(189, 195, 199, 0.7);
}

.column-body {
  min-height: 500px;
  padding: 0 !important;
}

.no-cards-message {
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -170px;
  top: 50%;
}

.columnOptions p {
  margin: 0 !important;
  padding: 0 !important;
}

.columnOptions img {
  object-fit: contain;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeAnimation 1.8s forwards;
}