.user-card,
.user-card-eluminer-candidate {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 0px 8px #00000029;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
    z-index: 2;
    backface-visibility: hidden;
}

.user-card-eluminer-candidate {
    background-image: linear-gradient(rgb(202, 202, 127), rgba(0,0,0,0));
}

.button-gray,
.button-orange {
    width: 100%;
    color: white;
    border: none !important;
    font-size: 14px !important;
    margin-top: 5px;
    &:hover {
        backdrop-filter: contrast(95%);
        backdrop-filter: brightness(80%);
        transition: 0.3s ease-in-out;
    }
}

.button-gray {
    background-color: #7b8896 !important;
    border: none;
}

.button-orange {
    background-color: #ed961c !important;
}

.button-white {
    height: 100% !important;
    width: 100%;
    color: #7B8896 !important;
    border: 1px solid #7B8896 !important;
    font-size: 14px !important;
    margin-top: 5px;
    &:hover {
        transition: 0.3s ease-in-out;
        color:#49545c;
    }
    background-color: white !important;
}

.modal-button-next {
    width: 100%;
    background-color: transparent !important;
    border: 1px solid #ed961c !important;
    color: #ed961c !important;
    &:hover {
        color: white !important;
        background-color: #ed961c !important;
        transition: 0.2s;
    }
    &:focus:not(:focus-visible){
        box-shadow: none;
    }
}

.modal-button-submit {
    width: 100%;
    color: white !important;
    background-color: #ed961c !important;
    outline: none !important;
    border: none !important;
    &:hover {
        color: white;
    }
    &:focus:not(:focus-visible) {
        outline: none !important;
        box-shadow: none;
    }
}

.modal-button-opp {
    position: relative;
    width: 100%;
    background-color: #7b8896 !important;
    &:hover {
        background-color: #ed961c !important;
        transition: 0.2s;
    }
}

.modal-button-selected {
    position: relative;
    width: 100%;
    background-color: #ed961c !important;
    &:hover {
        background-color: #ffa526 !important;
        transition: 0.2s;
    }
}

.info {
    fill: white;
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 1000;
}

.button-flip {
    width: 100%;
    border: none;
    background-color: transparent;
}

.container-flip-card {
    height: 640px;
    perspective: 1000px;
    margin-bottom: 1rem;
}

.flip-card {
    width: 100%;
    height: 100%;
}

.is-flipped {
    width: 100%;
    height: 100%;
    position: relative;
}

.back-flip-card {
    height: 100%;
    width: 100%;
    position: absolute;
}

.style-scroll-bar-candidate::-webkit-scrollbar {
    width: 12px;
}

.style-scroll-bar-candidate::-webkit-scrollbar-track {
    background: white;
}

.style-scroll-bar-candidate::-webkit-scrollbar-thumb {
    background-color: #ed961c;
    border-radius: 20px;
    border: 3px solid white;
}

@media (max-width: 1150px) {
    .container-flip-card {
        height: 700px;
    }
}

@media (max-width: 768px) {
    .container-flip-card {
        height: 840px;
    }
}
