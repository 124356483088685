.loader {
  position: fixed;
  left: 50%;
  margin-left: -30px;
  margin-top: 20px;
  top: 50%;
  z-index: 9999;
  transition: opacity .3s ease-out;
}

#divLoading {
  margin: 0px;
  padding: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 30001;
  opacity: .95;
  transition: opacity .3s linear;
}


#divLoading.hide{
    opacity: 0;
    pointer-events: none;
}

.enter-logo-loading{
    width: 49px;
    height: 49px;
    background: transparent url('../../images/enter-loading.png') 0% 0% no-repeat padding-box;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    z-index: 9999;
    transition: opacity .3s ease-out;
}