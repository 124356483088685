.opp-title {
    font-size: 20px;
    margin-top: 17px;
    font: normal normal 600 20px/60px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    letter-spacing: 0px;
    color: #49545c;
    width: 100%;
}

.border-top-details {
    border-top: 2px solid lightgray;
}

.button-container {
    text-align: left !important;
    width: 100% !important;
    font-size: 14px !important;
    padding: 18px 29px !important;
    box-shadow: 2px 2px 4px #00000029;
    border: none !important;
    border-radius: 4px;
    margin-bottom: 2px;
    &:hover {
        background-color: #ed961c !important;
    }
}
.petro {
    background-color: #354a5f !important;
}
.gray {
    background-color: #71808f !important;
}
.green {
    background-color: #5cb85c !important;
    background: url("../../../../images/icons/whatsapp.svg") no-repeat;
    background-size: 22px;
    background-position: 28px 18px;
    padding-left: 60px !important;
}

.background-stripped {
    width: 500px;
    height: 500px;
    opacity: 0.1;
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #ed961c 10px, #ed961c 20px),
        linear-gradient(to bottom, #fff, #fff);
}
