.margintest {
  margin-top: 1rem;
  margin-right: 3.5rem;
}

.step-title {
  color: white;
}

.step-title::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 3.5px;
  height: 100%;
  background-color: var(--background-color);
  margin-right: 10px;
}

.sectionStep {
  padding: 1rem 2.5rem;
}

.wrapper {
  border: 1px solid #8e8e8e !important;
  border-radius: 5px;
  margin: 8px 0 15px;
}

.toggle-candidate .iWpaaV {
  padding-left: 24px !important;
}

.section-step {
  margin-right: 15px;
}

.user-icon {
  margin-left: 20px;
}

.save-button-opp-progress {
  color: #E6A243;
  font: normal normal normal 15px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  display: inline-block;
  padding: .15rem 1rem .15rem 1rem;
}

.clean-button-opp-progress {
  color: #34495D;
  font: normal normal normal 15px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.container-tootip-progress {
  display: flex;
}

.filho-1 {
  flex-grow: 2;
}

@media (max-width: 455px) {
  .step-title {
    color: white;
    font-size: 94%;
    display: flex;
    margin-right: 3%;
  }

  .step-title::before {
    content: '';
    width: 3.5px;
    height: 100%;
    background-color: var(--background-color);
    margin-right: 10px;
  }

  .section-step {
    font-size: 92%;
    margin-right: 15px;
  }

  .user-icon {
    margin-left: 0;
  }
  @media (max-width: 390px) {
    .step-title {
      color: white;
      font-size: 92%;
      display: flex;
      margin-right: 8%;
    }
    
    .section-step {
      font-size: 91%;
      margin-right: 0;
    }
  }
}
