.bg-enter {
  background: #ED961C 0% 0% no-repeat padding-box;
  height: 100vh;
}

.pcd-toggle {
  color: #6F7070 !important;
  font-weight: 400 !important;
}

.enter-logo {
  margin: 30px 0 30px 0;
  width: 161px;
  height: 49px;
  background: transparent url('../../images/enter-logo.png') 0% 0% no-repeat padding-box;
  opacity: 1;
}

.bg-container {
  border-radius: 15px !important;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.button-entrar {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.content-signup {
  padding: 1rem 3rem;
}

.signup-title {
  text-align: left;
  font: normal normal 600 24px/32px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #616161;
  opacity: 1;
}

@media (min-width: 500px) {
  .bg-container {
    max-width: 480px;
  }
}

@media (min-width: 992px) {
  .bg-container {
    max-width: 480px;
  }
}

@media (max-width: 680px) {
  .bg-enter {
    height: calc(100vh - 110px);
  }
  .pcd-toggle {
    color: white !important;
    font-weight: 400 !important;
  }
}

/* Altura do input de localidade */
.css-yk16xz-control {
  min-height: 40px;
}

button[name=filestack]{
  border: 1px dashed #DBDBDB;
}