.opp-props {
  margin: 46px 0 15px 0;
  background-color: #354a5f;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  top: 5px;
  position: sticky;
}

.float-div {
  height: 1460px;
  width: 100%;
}

.border-bottom-details {
  border-bottom: 2px solid rgba(112, 112, 112, 0.4);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

.opp-new {
  margin: 46px 0 0 0 !important;
  width: 100%;

  label {
    color: #707070 !important;
    font-size: 14px !important;
    margin-bottom: 0;
  }

  label.float {
    position: absolute;
    background-color: white;
    padding: 0 7px;
    left: 8px;
    top: -3px;
    z-index: 1;
  }

  .input-border {
    border: 1px solid #8e8e8e !important;
    padding: 13px 14px;
  }
}

.salaryValue {
  width: 100%;
  height: 3.25rem;
  margin-top: 0.75rem;
  border-radius: 4px;
  border: 1px solid #707070 !important;

}

.wrapper {
  border: 1px solid #8e8e8e !important;
  border-radius: 5px;
  margin: 8px 0 15px;
}

.match {
  margin: 0;
  color: #707070 !important;
  font-size: 14px !important;
  font-weight: 600;
}

.autocomplete {
  padding-top: 10px;
  > div:not(.location-container) {
    > div {
      border-left: 1px solid #707070;
      border-right: 1px solid #707070;
      border-top: 1px solid #707070;
      border-bottom: none;
      border-radius: 5px 5px 0 0;
      padding: 5px 0 3px;
      font-size: 14px;
    }
  }
}

.location-container {
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070;
  height: auto;
  border-radius: 0 0 5px 5px;
  padding-top: 3px;
}

.match-criteria {
  border-radius: 5px;
  border: 1px solid #707070;
  min-height: 150px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.interests-careers {
  border-radius: 5px;
  border: 1px solid #707070;
  min-height: 80px;
  margin-top: 5px;
  margin-bottom: 15px;
  background-color: white;
}

.pill {
  color: #49545c;
  padding: 3px 15px;
  margin: 4px 5px;
  background-color: #eff2f2;
  border: 1px solid #eff2f2;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
}

.pill-delete {
  color: #49545c;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

.pill-delete:hover:nth-child(2) {
  color: rgb(255, 52, 52);
}

.active {
  background-color: #ed961c;
  color: white;
}

.card-criteria {
  background-color: #e8e8e8;
  padding: 19px;
  margin: 10px 0 10px 10px;
  border-radius: 20px;
  display: inline-block;
  font-size: 14px;
  color: #707070;
}

.close {
  margin-bottom: 5px;
  margin-left: 5px;
  cursor: pointer;  
  display: initial;
}

.select-button-create-opp, .select-button-create-opp>div {
  height: 3rem !important;
  border-radius: 5px;
  color: #707070 !important;
}

.label-select-opp {
  position: absolute;
  top: 4px;
  left: 11px;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  padding-top: 2px;
  color: white !important;
}

.title-salary-cap {
  margin-bottom: 0.5rem;
  margin-left: 0.7rem;
  color: #707070 !important;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.title-opp-hire {
  color: #707070 !important;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.checkbox-button-opp {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.checkbox-button-opp>div {
  padding-right: 10px;
}

.title-opp-priority {
  color: #707070 !important;
  font: normal normal 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.select-priority-opp {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  display: grid;
  font-size: small;
}

.row-title-toggle {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
}

.row-title-toggle>div>div>label:nth-child(2) {
  font-size: 0.75rem !important;
}

.row-client-company {
  margin-top: 1%;
  margin-bottom: 1%;
  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr;
}

.row-client-company>div{
  padding-right: 8px;
}

.drop-container-salary-create {
  display: flex;
}

.row-opp-type-and-hiring {
  display: flex;
  justify-content: space-between;
}

.opp-button-contractType {
  display: flex;
  justify-content: space-around;
  background-color: transparent;
  border: solid 1px #707070;
  border-radius: 4px;
  color: #7e7b7b;
  padding: 0.5rem 0 0.5rem 0;
  width: 100%;
}

.create-info-title {
  font: normal normal 600 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #8F969B;
}

.create-info-content {
  font: normal normal normal 13px/17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  letter-spacing: 0px;
  color: #8F969B;
  opacity: 1;
}

.close-modal-button {
  background-color: transparent;
  border: none;
  color: #8F969B;
  font-size: 1.5rem;
  padding: 0;
}

.newCareer-button {
  width: 100%;
  border: solid 1px #ed961c;
  background-color: transparent;
  color: #ed961c;
  border-radius: 5px;
  padding: 5px 0;
  margin: 1% 0 2% 0;
}

.disabled-newCareer-button {
  width: 100%;
  border: solid 1px #ed961c;
  background-color: transparent;
  color: #ed961c;
  border-radius: 5px;
  padding: 5px 0;
  margin: 1% 0 2% 0;
  opacity: 0.3;
}

.close-newCareer-button {
  border: none;
  background-color: transparent;
  color: #ed961c;
  align-self: flex-end;
}

.newCareer-container {
  display: flex;
  flex-direction: column;
}

.input-search>div>input {
  height: 100% !important;
  font-size: 18px;
}

.create-global-input>div {
  height: 30px;
}

.create-global-input>div> ::first-line {
  font-size: 13px;
  color: #DBA44D;
}

.container-delete-button-carreira {
  display: inline-block;
}

.link-form-office {
  margin-left: 7vw;
}

@media (max-width: 1115px) {
  .row-title-toggle>div:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .row-title-toggle>div:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .float-div {
    height: 1535px;
  }

  .drop-container-salary-create {
    flex-direction: column;
  }

  .row-client-company {
    grid-template-columns: 1.2fr 1fr;
  }

  .row-client-company>div:nth-child(2){
    padding-right: 0;
  }

  .row-client-company>div:nth-child(3) {
    margin-top: 0.5rem;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 5;
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .float-div {
    height: 1747px;
  }

  .row-opp-type-and-hiring {
    flex-direction: column;
    margin-bottom: 1%;
  }

  .link-form-office {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .float-div {
    height: 100%;
  }

  .opp-props {
    position: relative;
  }

  .row-opp-type-and-hiring {
    flex-direction: column;
    margin-bottom: 1%;
  }
}

@media(max-width: 667px) {
  .opp-button-contractType {
    flex-direction: column;
    align-items: center;
  }
  .undeline {
    border-bottom: dotted 1px #7e7b7b;
  }
}

