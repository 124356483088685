.professional-search-box {
  margin: 66px 0 16px;
  width: 100%;
  min-height: 142px;
  background-color: #354A5F;
  border-radius: 4px;
  padding: 20px 30px 5px;
}

/* Input Oportunidades */

.input-search {
  background-color: white;
}

.input-search>div>input {
  height: 100% !important;
  font-size: 18px;
}

.find-button {
  background-color: #DE701D;
  height: 52px;
  border-radius: 35px;
  border: 1px solid #C77332;
  color: white;
  font-size: 20px;
  margin-bottom: 43px;
  width: 100%;
}

.register-button {
  background-color: transparent;
  border: solid 1px #4e6c8a;
  border-radius: 3px;
  color: white;
  padding: 0 20px;
  margin: 7px 10px 0 8px;
  font-size: 14px;
  position: absolute;
}

.register-button:hover {
  background-color: #68737D;
  transition: 200ms;
}

.accordion-container {
  display: flex;
  justify-content: space-between;
}

.accordion-panel-style {
  display: flex;
  flex-direction: column;
}

@media(max-width: 440px) {
  .register-button {
    position: relative;
    width: 100%;
    height: 52px;
    padding: 8px;
    font-size: 16px;
    margin-right: 10%;
  }
  .accordion-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .accordion-items {
    width: 95%;
    border: solid 1px #4e6c8a;
    margin: 0 auto;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

@media(max-width: 343px) {
  .register-button {
    font-size: 14px;
  }
}
