.content-value {
  color: #3e3e3e;
  text-align: left;
}

.responsable-header {
  background-color: #fefefe;
}

.even-cell {
  background-color: #fafafac2;
}

.odd-cell {
  background-color: #fff;
}

.tooltip-inner {
  background-color: rgba(65, 65, 65, 1) !important;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
}
