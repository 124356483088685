.column-body.revision {
  background-color: rgba(189, 195, 199, 0.1);
}
.revision-header {
  background-color: #e74c3cee;
}

.column-body.published {
  background-color: rgba(189, 195, 199, 0.2);
}

.published-header {
  background-color: #f39c12;
}

.recruitment-header {
  background-color: #f39c12;
}

.column-body.recruitment {
  background-color: rgba(189, 195, 199, 0.3);
}

.evaluation-header {
  background-color: #9b59b6ee;
}

.column-body.evaluation {
  background-color: rgba(189, 195, 199, 0.4);
}

.selection-header {
  background-color: #3498dbee;
}

.column-body.selection {
  background-color: rgba(189, 195, 199, 0.5);
}

.interview-header {
  background-color: #2ecc71ee;
}

.column-body.interview {
  background-color: rgba(189, 195, 199, 0.6);
}

.closed-header {
  background-color: #1abc9cee;
}

.column-body.closed {
  background-color: rgba(189, 195, 199, 0.7);
}

.opportunitiesLength-header {
  padding: 4px !important;
  background-color: rgba(65, 65, 65, 0.6);
}

.column-body {
  min-height: 500px;
  padding: 0 !important;
}

.no-cards-message {
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -170px;
  top: 50%;
}

.columnOptions p {
  margin: 0 !important;
  padding: 0 !important;
}

.columnOptions img {
  object-fit: contain;
}

.button-victory-style {
  background-color: transparent;
  border: none;
  color: #68737d;
  font-size: 12px;
  font-weight: 600;
  padding: 0 5px;
}

.button-victory-style:hover {
  background-color: #68737d;
  color: #c2c8cc;
  border-radius: 15px;
  opacity: 0.5;
}

.body-victory-defeat {
  background-color: #ebeef0;
  border-radius: 10px;
  border: solid 1px #ebeef0;
  margin: 3px;
  padding: 5px;
}
