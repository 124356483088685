.container-invalid-token {
  margin: 0 auto;
  width: 60%;
}

.container-field-reset-password {
  width: 50%;
  margin: 0 auto;
}

.button-reset-password {
  background-color: #DE701D;
  border: transparent;
  border-radius: 20px;
  color: white;
  margin-left: 80%;
  margin-top: 3%;
  margin-bottom: 3%;
  width: 8rem;
}

.label-field-reset-password {
  width: 100%;
}

.invalid-token {
  font-size: 1.3rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.invalid-token:last-child a {
  color: #DE701D;
}

.reset-password-title {
  color: #707070;
  font-size: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  margin-bottom: 3%;
  margin-top: 2%;
}

@media (max-width: 800px) {
  .container-field-reset-password {
    width: 95%;
  }

  .button-reset-password {
    background-color: #DE701D;
    border: transparent;
    border-radius: 20px;
    color: white;
    margin-left: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 90%;
    padding: 2%;
  }

  .reset-password-title {
    color: #707070;
    font-size: 1.7rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    margin-bottom: 10%;
    margin-top: 5%;
  }
}
